import BASE_EVENT from "./event";
import { getConfig } from "../config/index";
export default class Session {
  constructor({ event } = { event: {} }) {
    this.event = event ? eventToArray(event) : {};
    this.config = getConfig();
  }

  on(event, callback) {
    console.log(this, event);
    if (
      this.event[event] &&
      Object.prototype.toString.call(this.event[event]) === "[object Array]"
    ) {
      this.event[event].push(callback);
    } else {
      this.event[event] = [callback];
    }
  }

  fire(event = {}, ...callSession) {
    // 先update
    if (
      Object.prototype.toString.call(this.event.update) === "[object Array]"
    ) {
      this.event.update.forEach((fn) => {
        if (typeof fn === "function") {
          fn(...callSession);
        }
      });
    } else if (
      Object.prototype.toString.call(this.event.update) === "[object Function]"
    ) {
      this.event.update(...callSession);
    }

    // 在其他
    if (!this.event[event] || event === BASE_EVENT.UPDATE) return; // 防止fire update事件 触发两次
    if (!this.event[event]) {
      console.warn(`callEvent ${event} process is empty`);
      return;
    }
    // 因为事件注册入口不一定从on里面走 而是直接赋值
    if (
      Object.prototype.toString.call(this.event[event]) === "[object Function]"
    ) {
      this.event[event](...callSession);
    } else if (
      Object.prototype.toString.call(this.event[event]) === "[object Array]"
    ) {
      this.event[event].forEach((fn) => {
        if (typeof fn === "function") {
          fn(...callSession);
        }
      });
    }
  }

  // 关闭事件
  off(type, callback) {
    const callbacks = this.event[type] || [];
    const newCallbacks = callbacks.filter((fn) => fn != callback);
    this.event[type] = newCallbacks;
  }
}

function eventToArray(event) {
  const result = {};
  Object.keys(event).forEach((key) => {
    if (result[key]) {
      result[key].push(event[key]);
    } else {
      result[key] = [event[key]];
    }
  });
  return result;
}
