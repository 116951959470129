//用户范围
const USER_SCOPE = {
  WEB: 1, //调度台
  APP: 2, //终端
  MONITOR: 3, //监控
  TELEPHONE: 4, //话机
  GATEWAY: 5, //集群网关
  OUTNUMBER: 6, //外部号码
  ONLINEAPP: 7, //在线终端
  OTHER: 0 //其它
};

const USER_SCOPE_TEXT = {
  1: '调度台',
  2: '终端',
  3: '监控',
  4: '话机',
  5: '集群网关',
  6: '外部号码',
  0: '其它'
};

//联动话机状态
const USER_GROUP_STATE = {
  OPEN: '2',
  CLOSE: '1'
};

export { USER_SCOPE, USER_SCOPE_TEXT, USER_GROUP_STATE };
