import request from './request';
export function postAction(url, parameter, options) {
  return request(
    {
      url,
      method: 'post',
      data: parameter
    },
    options
  );
}

//get
export function getAction(url, parameter) {
  return request({
    url: url,
    method: 'get',
    params: parameter
  });
}
