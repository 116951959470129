import { RTCPublisher, RTCPlayer } from "./rtc.base";
import { getCallDeviceType } from "../util/index.js";
import { DEFALT_BIT_RANGE } from "../dict/index";
import { useStreamAction } from "./stream";

//推流
function startPublish({ url, type, mediaConstraints, waterMarker }) {
  return new Promise(async (resolve, reject) => {
    try {
      const pcInfo = new RTCPublisher();
      pcInfo.pc.onicegatheringstatechange = (event) => {
        if (pcInfo.pc.iceGatheringState === "complete") {
          console.log("ok");
        }
      };
      // http协商的promise
      /* 为什么这么写呢
        因为pcInfo要先给外部用 这样外部可以无论什么情况都可以停掉rtc 这个promise过程中报错也要给外面 所以这里没法同步
        为什么不在上册直接调用publish呢  因为这里的url在中间层被处理了，要改目录结构太麻烦   所以这里请求协商后 返回这个promise给外部
      */
      const negotiatePromise = pcInfo.publish({
        url,
        type,
        mediaConstraints,
        waterMarker,
      });
      resolve({ pcInfo, negotiatePromise });
    } catch (error) {
      reject(error);
    }
  });
}

//播流
function startPlay({ url, call_type }) {
  return new Promise(async (resolve, reject) => {
    try {
      const callOption = getCallDeviceType(call_type);
      const pcInfo = new RTCPlayer();
      const negotiatePromise = pcInfo.play({ url, ...callOption });
      resolve({ pcInfo, negotiatePromise });
    } catch (error) {
      reject(error);
    }
  });
}

export { startPublish, startPlay };
