import {
  CHANNEL_IN,
  CHANNEL_ADD,
  CHANNEL_OUT,
  CHANNEL_DEL_USER,
  CHANNEL_GET,
  CHANNEL_RELEASE,
  CHANNEL_HANGUP
} from '../../websocket/message/index';
import { CHANNEL_STATE } from '../../dict';
import CHANNEL_EVENT from './event';
import { setStorage, getStorage, removeStorage } from '../../util';
import { CHANNEL_NUMBER_KEY } from '../../dict/index';
import api from '../../api';

export function useChannelNotice() {
  const _this = this;

  function isMine(user_id) {
    return _this.userInfo.user.id === user_id;
  }

  return {
    //收到进入对讲组的通知时,此时自己又不在对讲组内
    [CHANNEL_IN]: async (channel) => {
      const { channel_id, user_id } = channel;
      if (isMine(user_id)) {
        //  (!getStorage(CHANNEL_NUMBER_KEY) || _this.isMineChannel(channel_id))
        //首次进入
        if (!getStorage(CHANNEL_NUMBER_KEY)) {
          setStorage(CHANNEL_NUMBER_KEY, channel_id);
          api.channelIn({ channel_id: channel_id, id: _this.userInfo.user.id });
        }
        const channelAllUsers = await _this.channelList.getChannelUsers(
          channel_id
        );
        const info = {
          channel,
          state: CHANNEL_STATE.CAHNELIN,
          channelAllUsers
        };
        _this.channelSession = Object.assign(_this.channelSession, info);
      } else {
        _this.channelList.channelUpdateUser(channel_id, user_id, {
          channel_in: true
        });
      }
      _this.channelSession.fire(
        CHANNEL_EVENT.CHANNEL_IN,
        _this.channelSession,
        { channel }
      );
    },
    [CHANNEL_ADD]: ({ channel, user }) => {
      const { channel_id } = channel;
      _this.channelList.channelAddUser(channel_id, user);
      _this.channelSession.fire(CHANNEL_EVENT.CHANNEL_ADD, _this.channelSession, {
        channel,
        user
      });
    },
    [CHANNEL_OUT]: (channel) => {
      const { channel_id, user_id } = channel;
      _this.channelList.channelUpdateUser(channel_id, user_id, {
        channel_in: false
      });
      _this.channelSession.fire(
        CHANNEL_EVENT.CHANNEL_OUT,
        _this.channelSession,
        { channel }
      );
    },
    [CHANNEL_DEL_USER]: (channel) => {
      const { channel_id, user_id } = channel;
      _this.channelList.channelRemoveUser(channel_id, user_id);
      _this.channelSession.fire(
        CHANNEL_EVENT.CHANNEL_DEL_USER,
        _this.channelSession,
        {
          channel
        }
      );
    },
    //对讲组成员获取抢话 不是我说话 停止推流 -> 播放别人流 同时麦克风状态为 OTHER_SPEAKING
    [CHANNEL_GET]: async (channel) => {
      const { user_id } = channel;
      let playerPcInfo;
      if (!isMine(user_id)) {
        _this.channelSession.stopPublish();
        playerPcInfo = await _this.play(channel);
        console.log(playerPcInfo);
      }
      _this.channelSession.setSpeaker(channel, playerPcInfo);
      _this.channelSession.fire(
        CHANNEL_EVENT.CHANNEL_GET,
        _this.channelSession,
        { channel }
      );
    },
    //对讲组成员释放抢话
    [CHANNEL_RELEASE]: (channel) => {
      const { user_id } = channel;
      if (isMine(user_id)) {
        _this.release();
      }
      _this.channelSession.cleanSpeaker();
      _this.channelSession.fire(
        CHANNEL_EVENT.CHANNEL_RELEASE,
        _this.channelSession,
        { channel }
      );
    },
    //对讲组超时 组内在线人员强制退出
    [CHANNEL_HANGUP]: (channel) => {
      const { channel_id } = channel;
      _this.channelSession && _this.channelSession.cleanChannel();
      _this.channelList &&
        _this.channelList.channelUpdateUserBatch(channel_id, {
          channel_in: false
        });
    }
  };
}
