import RTCBase from '../base';
import api from '../../api/index';
import { useChannelNotice } from './notice';
import ChannelSession from './ChannelSession';
import { setStorage, getStorage, removeStorage } from '../../util';
import { CHANNEL_STATE, MICROPHONE_STATE } from '../../dict';
import { CHANNEL_NUMBER_KEY } from '../../dict/index';
import ChannelList from './ChannelList';
import { useAddStream } from "../../webrtc/stream";

export default class Channel extends RTCBase {
  constructor({ userInfo, ws }) {
    super({ userInfo, ws });
    this.channelSession = new ChannelSession({}, {}, userInfo);
    this.channelList = new ChannelList({ userInfo, ws });
    this.spekBtn = null;
    this.eid = "";
    this.init();
  }

  init() {
    this.initChannelNotice();
    this.initTempChannelInfo();
  }

  initChannelNotice() {
    const channelNotice = useChannelNotice.call(this);
    this.ws.on('*', channelNotice);
  }

  // 初始化临时对讲组信息
  async initTempChannelInfo() {
    const tempChannel = await api.getTempChannel({
      user_id: this.userInfo.user.id
    });
    if (Object.keys(tempChannel).length) {
      this.channelSession.tempChannel = {
        exist: true,
        info: tempChannel
      };
    }
  }

  // 是否是我的对讲组
  isMineChannel(channel_id) {
    return [
      this.channelSession.channel && this.channelSession.channel.channel_id,
      getStorage(CHANNEL_NUMBER_KEY)
    ].includes(channel_id);
  }

  // 进入对讲组
  async join({ channel }, event = {}) {
    console.log(event,'===event')
    try {
      const { channel_id } = channel;
      if (this.isMineChannel(channel_id)) return;
      setStorage(CHANNEL_NUMBER_KEY, channel_id);
      await api.channelIn({
        channel_id: channel_id,
        id: this.userInfo.user.id
      });
      if (Object.keys(event).length > 0) {
        Object.assign(this.channelSession, { event });
      }
      return this.channelSession;
    } catch (error) {
      removeStorage(CHANNEL_NUMBER_KEY);
      console.error(error);
      throw error;
    }
  }

  //临时对讲组
  async callTemp(phones) {
    const { tempChannel } = this.channelSession;
    if (!tempChannel.exist) {
      throw `There is no temporary intercom group`;
    }
    await join({ tempChannel });
    if (phones.length) {
      api.channelAdd({
        phone: phones.toString(),
        channel_id: tempChannel.channel_id
      });
    }
  }

  //获取话权
  getTalk(el, eid) {
    this.spekBtn = el;
    this.eid = eid;
    if (this.channelSession.state !== CHANNEL_STATE.CAHNELIN) {
      throw 'Did not enter the intercom group';
    }
    this.channelSession.microphoneState = MICROPHONE_STATE.GET_READY;
    // 防抖 内部调用接口过多   任何一步停了都不走后续
    setTimeout(async () => {
      try {
        getMicrophone.call(this);
      } catch (error) {
        this.release();
      }
    }, 100);

    async function getMicrophone() {
      console.log(this.eid, "===this.eid")
      let el = document.getElementsByClassName(".microphone-item");
      if (this.channelSession.microphoneState !== MICROPHONE_STATE.GET_READY)
        return;
      const { channel_id } = this.channelSession.channel;
      this.channelSession.microphoneState = MICROPHONE_STATE.GET_TALKING;
      await api.channelGet({ channel_id, id: this.userInfo.user.id });
      this.spekBtn.setAttribute("state", "get");
      this.channelSession.microphoneState = MICROPHONE_STATE.GET_TALK_SUCCESS;
      if (this.userInfo.config.media_sources) {
        this.channelSession.microphoneState = MICROPHONE_STATE.MINE_SPEAKING;
      } else {
        /* this.channelSession.publishPcInfo = await  */
        this.publish({
          target: channel_id,
          mediaConstraints: { audio: true, video: false },
          stream: `${this.userInfo.user.phone}${channel_id}_${this.userInfo.user.phone}`,
          eid: this.eid
        }).then(({ pcInfo })=>{
          if (pcInfo) {
            pcInfo.onaddstream = ({ stream }) => {
              useAddStream({ pcInfo, stream });
            };
            this.channelSession.publishPcInfo = pcInfo;
          }
        })
        console.log(this.channelSession.publishPcInfo,'===this.channelSession.publishPcInfo')
        if (
          this.channelSession.microphoneState ===
          MICROPHONE_STATE.GET_TALK_SUCCESS
        ) {
          this.channelSession.microphoneState = MICROPHONE_STATE.MINE_SPEAKING;
        } else {
          this.release();
        }
      }
    }
    return this.channelSession;
  }

  release() {
    //释放分为视频源模式和非视频源模式
    if (this.channelSession.microphoneState === MICROPHONE_STATE.RELEASE)
      return;
    this.channelSession.microphoneState = MICROPHONE_STATE.RELEASE;
    if (this.userInfo.config.media_sources) {
      const param = {
        channel_id: this.channelSession.channel.channel_id,
        user_id: this.userInfo.user.id
      };
      api.channelRelease(param);
    } else {
      console.log(this.channelSession,'===this.channelSession')
      this.channelSession.stopPublish();
    }
  }

  // 播放
  play(channel) {
    const { phone, channel_id, stream } = channel;
    /* const stream = `${phone}${channel_id}_${phone}`; */
    return this.player({
      stream,
      target: channel_id,
      call_type: 'channel_voice'
    });
  }
}
