import CallSession from "./CallSession";
import { useP2PNotice } from "./notice";
import RTCBase from "../base/index";
import { USER_SCOPE } from "../../dict/index";
import { getCallDeviceType } from "../../util/index";
import { useAddStream } from "../../webrtc/stream";
import P2P_EVENT from "./event";
import BASE_EVENT from "../base/event";

export default class P2P extends RTCBase {
  constructor({ userInfo, ws }) {
    super({ userInfo, ws });
    this.callList = {};
    this.init();
  }

  init() {
    this.initP2PNotice();
  }

  initP2PNotice() {
    const p2pNotice = useP2PNotice.call(this);
    this.ws.on("*", p2pNotice);
  }

  getTarget(call) {
    return call.caller === this.userInfo.user.phone ? call.callee : call.caller;
  }

  getTargetCallItem(call) {
    const target = this.getTarget(call, this.userInfo);
    const callItem = this.callList[target];
    return callItem;
  }

  // 呼叫
  async call(
    {
      target,
      mediaConstraints = {
        audio: false,
        video: false,
      },
      eid=""
    },
    event = {}
  ) {
    if (target === this.userInfo.user.phone) {
      throw "prohibit calling yourself";
    }
    let user = await this.api.usersearch({ phone: target });
    let scope = user.length ? user[0].scope : USER_SCOPE.OUTNUMBER;
    mediaConstraints.type =
      this.userInfo.config.sys_sip_use_state &&
      [
        USER_SCOPE.TELEPHONE,
        USER_SCOPE.OUTNUMBER,
        USER_SCOPE.ONLINEAPP,
      ].includes(Number(scope))
        ? "sip"
        : "p2p";

    let callItem = new CallSession(
      {
        userInfo: this.userInfo,
        isMineCaller: true,
        target,
      },
      event
    );
    console.log(eid,'==eid111')
    this.publishProcess({ target, mediaConstraints, eid }, callItem);
    this.callList[target] = callItem;
    return callItem;
  }

  callWX(mobile) {
    return this.api.callSipPlayMusicSendSms({
      user_id: this.userInfo.user.id,
      mobile,
    });
  }

  //挂断
  hangup(call, target) {
    if (
      this.callList[target] &&
      this.callList[target].publishPcInfo &&
      !this.config.isMediaSource
    ) {
      this.callList[target] && this.callList[target].terminate();
    } else {
      this.api.sysCallHangup({
        user_id: this.userInfo.user.id,
        uuid: call.uuid,
      });
    }
  }

  // 接听
  answer(call, event) {
    // 先推流 在拉流
    return new Promise(async (resolve, reject) => {
      try {
        if (!call) {
          console.error("必填参数call不能为空");
          return;
        }
        const callItem = this.getTargetCallItem(
          call,
          this.userInfo,
          this.callList
        );
        //其他设备源头
        if (this.userInfo.config.media_sources) {
          this.api
            .sysCallAnswer({
              caller: call.caller,
              callee: this.userInfo.user.phone,
            })
            .then(() => this.playerProcess({ call }, callItem));
        } else {
          //自身设备推流
          // await this.publishProcess(
          //   {
          //     target: call.caller,
          //     mediaConstraints: getCallDeviceType(call.call_type)
          //   },
          //   callItem
          // );
          //自身设备推流
          this.publishProcess(
            {
              target: call.caller,
              mediaConstraints: getCallDeviceType(call.call_type),
            },
            callItem
          ).then(() => this.playerProcess({ call }, callItem));
        }
        // await this.playerProcess({ call }, callItem);
        Object.assign(callItem, {
          event,
        });
        resolve(callItem);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  //播放
  playerProcess({ call }, callItem) {
    return this.player({
      url:
        this.userInfo.user.phone === call.caller
          ? call.callee_stream
          : call.caller_stream,
      target:
        this.userInfo.user.phone === call.caller ? call.callee : call.caller,
      call_type: call.call_type,
      stream:
        this.userInfo.user.phone === call.caller
          ? call.callee_stream
          : call.caller_stream,
    })
      .then(({ pcInfo, negotiatePromise }) => {
        pcInfo.onaddstream = ({ stream }) => {
          useAddStream({ pcInfo, stream });
          callItem.fire(BASE_EVENT.ADD_PLAYER_STREAM, stream);
        };
        callItem.playerPcInfo = pcInfo;
        return negotiatePromise;
      })
      .catch((e) => {
        callItem.fire(BASE_EVENT.PLAYER_FIRE, e);
        throw e;
      });
  }

  // 处理推流
  publishProcess({ target, mediaConstraints, eid }, callItem) {
    console.log(eid,'==eid111')
    return this.publish({ target, mediaConstraints, eid })
      .then(({ pcInfo, negotiatePromise }) => {
        if (pcInfo) {
          pcInfo.onaddstream = ({ stream }) => {
            useAddStream({ pcInfo, stream });
            callItem.fire(BASE_EVENT.ADD_PUBLISH_STREAM, stream);
          };
          callItem.publishPcInfo = pcInfo;
        }
        return negotiatePromise;
      })
      .catch((e) => {
        callItem.fire(BASE_EVENT.PUBLISH_FIRE, e);
        throw e;
      });
  }
}
