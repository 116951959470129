import { BTN_CMD, BTN_TYPE, CALL_TYPE, ICON_TYPE } from "../../dict/index";
import { audioTpl, videoTpl } from "./tpl";

export const ACTIONSBTNS = {
  [CALL_TYPE.VOICE_VIDEO]: [
    {
      text: "静音",
      btnType: BTN_TYPE.ABOUT_MUTE,
      btnCmd: BTN_CMD.MUTED,
      icon: ICON_TYPE.MUTED
    },
    {
      text: "置聋",
      btnType: BTN_TYPE.ABOUT_DEAF,
      btnCmd: BTN_CMD.DEAF,
      icon: ICON_TYPE.DEAF,
    },
    {
      text: "共享屏幕",
      btnType: BTN_TYPE.ABOUNT_SHARE,
      btnCmd: BTN_CMD.SHARE_SCREEN,
      icon: ICON_TYPE.SHARE_SCREEN,
    },
    {
      text: '远程协助',
      btnType: BTN_TYPE.ABOUT_HELP,
      btnCmd: BTN_CMD.HELP,
      icon: ICON_TYPE.HELP,
    },
    {
      text: "挂断",
      btnType: BTN_TYPE.HANGUP,
      btnCmd: BTN_CMD.HANGUP,
      icon: ICON_TYPE.HNAGUP,
    },
    // {
    //   text: '信息',
    //   btnType: BTN_TYPE.ABOUT_INFO,
    //   btnCmd: BTN_CMD.INFO_SHOW
    // }
  ],
  [CALL_TYPE.VOICE]: [
    {
      text: "静音",
      btnType: BTN_TYPE.ABOUT_MUTE,
      btnCmd: BTN_CMD.MUTED,
      icon: ICON_TYPE.MUTED
    },
    {
      text: "置聋",
      btnType: BTN_TYPE.ABOUT_DEAF,
      btnCmd: BTN_CMD.DEAF,
      icon: ICON_TYPE.DEAF,
    },
    {
      text: "挂断",
      btnType: BTN_TYPE.HANGUP,
      btnCmd: BTN_CMD.HANGUP,
      icon: ICON_TYPE.HNAGUP,
    },
  ],
};

export const btnNextInfo = {
  [BTN_CMD.MUTED]: {
    btnCmd: BTN_CMD.UNMUTED,
    icon: ICON_TYPE.CANCELMUTED,
    text: "取消静音",
  },
  [BTN_CMD.UNMUTED]: {
    btnCmd: BTN_CMD.MUTED,
    text: "静音",
    icon: ICON_TYPE.MUTED,
  },
  [BTN_CMD.DEAF]: {
    btnCmd: BTN_CMD.UNDEAF,
    icon: ICON_TYPE.CANCELDEAF,
    text: "取消置聋",
  },
  [BTN_CMD.UNDEAF]: {
    btnCmd: BTN_CMD.DEAF,
    icon: ICON_TYPE.DEAF,
    text: "置聋",
  },
  [BTN_CMD.HELP]: {
    btnCmd: BTN_CMD.CANCLE_HELP,
    text: "取消远程协助",
    icon: ICON_TYPE.HELP,
  },
  [BTN_CMD.CANCLE_HELP]: {
    btnCmd: BTN_CMD.HELP,
    text: "远程协助",
    icon: ICON_TYPE.HELP,
  },
  [BTN_CMD.ON_WALL]: {
    btnCmd: BTN_CMD.CANCEL_ON_WALL,
    text: "取消上墙",
  },
  [BTN_CMD.CANCEL_ON_WALL]: {
    btnCmd: BTN_CMD.ON_WALL,
    text: "上墙",
  },
  [BTN_CMD.INFO_SHOW]: {
    btnCmd: BTN_CMD.INFO_HIDE,
    text: "隐藏信息",
  },
  [BTN_CMD.INFO_HIDE]: {
    btnCmd: BTN_CMD.INFO_SHOW,
    text: "信息",
  },
  [BTN_CMD.SHARE_SCREEN]: {
    btnCmd: BTN_CMD.CANCEL_SHARE_SCREEN,
    icon: ICON_TYPE.CANCEL_SHARE,
    text: "取消共享",
  },
  [BTN_CMD.CANCEL_SHARE_SCREEN]: {
    btnCmd: BTN_CMD.SHARE_SCREEN,
    icon: ICON_TYPE.SHARE_SCREEN,
    text: "共享屏幕",
  },
};

// 呼叫类型不同的mediatpl
export const callTypeTpl = {
  [CALL_TYPE.VIDEO]: videoTpl,
  [CALL_TYPE.VOICE_VIDEO]: videoTpl,
  [CALL_TYPE.VOICE]: audioTpl,
};

export const CALL_TYPE_TEXT_MAP = {
  [CALL_TYPE.VOICE_VIDEO]: "音视频呼叫",
  [CALL_TYPE.VIDEO]: "视频呼叫",
  [CALL_TYPE.VOICE]: "语音呼叫",
};
