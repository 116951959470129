/**
 * @method
 * @description 创建模板并返回
 * @param {String} tpl
 */
export function createTpl(tpl) {
  if (!tpl) return [];
  var oDiv = document.createElement('div');
  oDiv.innerHTML = tpl;
  return oDiv.childNodes || [];
}

export function tplReplace(template, replaceObject) {
  const tpl = template.replace(/{{(.*?)}}/g, (node, key) => {
    return replaceObject[key.trim()];
  });
  return tpl;
}

export function isNodeEl(el) {
  return el.nodeType === 1;
}

export function getBtn(e) {
  let target = e.target;
  if (target.nodeName !== 'BUTTON') {
    target = target.parentElement;
  }
  return target;
}

export function getBtnType(e) {
  let target = e.target;
  if (target.nodeName !== 'BUTTON') {
    target = target.parentElement;
  }
  return target.getAttribute('btn-type');
}

export function getBtnCmd(e) {
  let target = e.target;
  if (target.nodeName !== 'BUTTON') {
    target = target.parentElement;
  }
  return target.getAttribute('btn-cmd');
}

export async function getReportInfo(pc) {
  if (!pc || !pc.getReceivers) {
    return;
  }
  const receiverReport = {
    info: {},
    lastResult: new Map()
  };
  let rtcReceivers = pc.getReceivers();
  for (let rtcReceiver of rtcReceivers) {
    if (rtcReceiver.track.kind === 'video') {
      const reports = await rtcReceiver.getStats();
      reports.forEach((report) => {
        if (report.type === 'inbound-rtp') {
          var curTs = report.timestamp;
          var bytes = report.bytesReceived;
          //上面的bytes和packets是累计值。我们只需要差值
          if (
            receiverReport.lastResult &&
            receiverReport.lastResult.has &&
            receiverReport.lastResult.has(report.id)
          ) {
            var biterate =
              (8 *
                (bytes -
                  receiverReport.lastResult.get(report.id).bytesReceived)) /
              (curTs - receiverReport.lastResult.get(report.id).timestamp);
            receiverReport.info = Object.assign(report, {
              biterate
            });
          }
        }
      });
      receiverReport.lastResult = reports;
    }
  }
  return receiverReport;
}
