import { DEFALT_BIT_RANGE } from "../dict/index";
import  { RemoteCanvas, REMOTE_HAND } from './remote_help'

export function useAddStream(
  { stream, pcInfo },
  { bw } = {
    bw: DEFALT_BIT_RANGE,
  }
) {
  const streamAction = useStreamAction(stream, pcInfo);
  streamAction.changeBW(bw); //改变视频码率
  Object.assign(pcInfo, { stream }, streamAction);
}

//流添加动作
export function useStreamAction(stream, pcInfo) {
  return {
    replaceCamera(deviceId) {
      //切换摄像头
      if (
        stream.getVideoTracks()[0] &&
        stream.getVideoTracks()[0].id === deviceId
      ) {
        return;
      }
      navigator.mediaDevices
        .getUserMedia({ video: { deviceId }, audio: false })
        .then((shareStream) => {
          let vsender = null;
          let senders = pcInfo.pc.getSenders();
          senders.forEach((sender) => {
            if (sender && sender.track && sender.track.kind === "video") {
              vsender = sender;
              // stream.cloneVideoTrack = sender.track.clone(); //将当前视频流克隆
              stream.getVideoTracks()[0].stop();
              sender.track.stop(); //pc关闭当前视频流
              stream.removeTrack(sender.track); //流里面清除视频流
            }
          });
          if (vsender) {
            stream.addTrack(shareStream.getVideoTracks()[0]);
            vsender.replaceTrack(shareStream.getVideoTracks()[0]);
          }
        });
    },
    //禁言
    closeVoice: () => {
      stream.getAudioTracks()[0] &&
        (stream.getAudioTracks()[0].enabled = false);
    },
    //取消禁言
    openVoice: () => {
      stream.getAudioTracks()[0] && (stream.getAudioTracks()[0].enabled = true);
    },
    //关闭视频传输
    closeVideo: () => {
      stream.getVideoTracks()[0] &&
        (stream.getVideoTracks()[0].enabled = false);
    },
    //打开视频传输
    openVideo: () => {
      stream.getVideoTracks()[0] && (stream.getVideoTracks()[0].enabled = true);
    },
    //改变码率
    changeBW: (bit = DEFALT_BIT_RANGE) => {
      let vsender = null;
      let senders = pcInfo.pc.getSenders();
      senders.forEach((sender) => {
        if (sender && sender.track && sender.track.kind === "video") {
          vsender = sender;
        }
      });
      if (!vsender) return;
      var parameters = vsender.getParameters();
      if (parameters.encodings) {
        parameters.encodings[0].maxBitrate = bit * 1000;
      }
      vsender
        .setParameters(parameters)
        .then(() => {
          console.log("Successed to set parameters!");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    shareScreen: (cancalCb) => {
      //共享屏幕
      return new Promise((resolve, reject) => {
        //共享屏幕
        navigator.mediaDevices
          .getDisplayMedia({ audio: false, video: true })
          .then((shareStream) => {
            let vsender = null;
            let senders = pcInfo.pc.getSenders();
            senders.forEach((sender) => {
              if (sender && sender.track && sender.track.kind === "video") {
                vsender = sender;
                stream.cloneVideoTrack = sender.track.clone(); //将当前视频流克隆
                sender.track.stop(); //pc关闭当前视频流
                stream.removeTrack(sender.track); //流里面清除视频流
              }
            });
            if (vsender) {
              stream.addTrack(shareStream.getVideoTracks()[0]);
              vsender.replaceTrack(shareStream.getVideoTracks()[0]);
              shareStream.getVideoTracks()[0].onended = () => {
                pcInfo.cancelShareScreen();
                cancalCb && cancalCb();
              };
            }
            resolve();
          })
          .catch(() => {
            reject("取消共享");
          });
      });
    },
    cancelShareScreen() {
      //取消共享
      let vsender = null;
      let senders = pcInfo.pc.getSenders();
      senders.forEach((sender) => {
        if (sender && sender.track.kind === "video") {
          vsender = sender;
          sender.track.stop(); //pc清除共享屏幕流
          stream.removeTrack(sender.track); //流里面清除共享屏幕流
        }
      });
      if (vsender) {
        stream.addTrack(stream.cloneVideoTrack); //流里面添加克隆的视频流
        vsender.replaceTrack(stream.cloneVideoTrack); //切换成之前的克隆流
      }
      // });
    },
    async help(targetStream, videoEl) {
      //远程协助
      let vsender = null;
      let senders = pcInfo.pc.getSenders();
      senders.forEach((sender) => {
        if (sender && sender.track && sender.track.kind === "video") {
          vsender = sender;
          stream.cloneVideoTrack = sender.track.clone();
          sender.track.stop();
          stream.removeTrack(sender.track);
        }
      });
      pcInfo.helpObj = new RemoteCanvas({ videoEl });
      const canvasStream = await pcInfo.helpObj.init({
        stream: targetStream,
      });
      pcInfo.helpObj.addAction(REMOTE_HAND);
      if (vsender) {
        stream.addTrack(canvasStream.getVideoTracks()[0]);
        vsender
          .replaceTrack(canvasStream.getVideoTracks()[0])
          .catch((e) => console.error(e));
      }
    },
    cancelHelp() {
      let vsender = null;
      let senders = pcInfo.pc.getSenders();
      senders.forEach((sender) => {
        if (sender && sender.track?.kind === "video") {
          vsender = sender;
          sender.track.stop();
          stream.removeTrack(sender.track);
        }
      });
      if (vsender) {
        stream.addTrack(stream.cloneVideoTrack);
        vsender.replaceTrack(stream.cloneVideoTrack);
        stream.cloneVideoTrack = null;
      }
      pcInfo.helpObj?.close();
    },

    terminate: () => {
      if (pcInfo) {
        pcInfo.close();
        // pcInfo.stream.getTracks().forEach((item) => {
        //   item.stop();
        // });
      }
    },
  };
}
