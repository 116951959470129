import { STORAGE_TYPE } from '../dict/index';

function getStorage(key) {
  return STORAGE_TYPE === 'session'
    ? window.sessionStorage.getItem(key)
    : window.localStorage.getItem(key);
}

function setStorage(key, value) {
  return STORAGE_TYPE === 'session'
    ? window.sessionStorage.setItem(key, value)
    : window.localStorage.setItem(key, value);
}

function removeStorage(key) {
  return STORAGE_TYPE === 'session'
    ? window.sessionStorage.removeItem(key)
    : window.localStorage.removeItem(key);
}

export { getStorage, setStorage, removeStorage };
