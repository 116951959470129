import { isSupportMediaDevices } from '../../util/index';

/**
 * @class
 * @description 媒体配置信息
 * @constructor
 */
class MediaSource {
  constructor() {
    this.videoConstraintMap = {};
    this.audioConstraintMap = {};
    this.videoInputDevices = [];
    this.audioInputDevices = [];
    this.selectVideoConstraint = null;
    this.selectAudioConstraint = null;
    this.init();
  }

  /**
   * @method
   * @private
   * @description 初始化
   */
  init() {
    this.initDevices();
  }

  /**
   * @method
   * @private
   * @description 初始化输入设备信息
   */
  async initDevices() {
    if (!isSupportMediaDevices()) return;
    let devices = await navigator.mediaDevices.enumerateDevices();
    //音频检测
    let { audioDevices, videoDevices } = devices.reduce(
      (total, item) => {
        if (item.kind === 'audioinput') {
          total.audioDevices.push(item);
        }
        if (item.kind === 'videoinput') {
          total.videoDevices.push(item);
        }
        return total;
      },
      { audioDevices: [], videoDevices: [] }
    );
    this.audioInputDevices = audioDevices;
    this.videoInputDevices = videoDevices;
  }
}

let mediaSource;

/**
 * @method
 * @description 获取mediaSouce对象 (单例)
 * @returns {MediaSource} mediaSource
 */
function getMediaSource() {
  mediaSource = mediaSource || new MediaSource();
  return mediaSource;
}

export { getMediaSource };
