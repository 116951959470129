import { CLIENT_ID } from "../dict/enum/ws";
import { getStorage, setStorage } from "../util";

export function heartTpl() {
  const data = {
    cmd: "heart",
    data: {
      lat: "0",
      lng: "0",
      direction: "0",
      battery: "0",
      speed: "0",
      map_type: 1,
    },
    msgid: new Date().getTime(),
  };
  return data;
}

export function loginTpl({ account, password, token, last_token, module }) {
  if (!window.localStorage.getItem(CLIENT_ID)) {
    window.localStorage.setItem(
      CLIENT_ID,
      new Date().getTime() + navigator.userAgent
    );
  }
  const client_id = window.localStorage.getItem(CLIENT_ID);
  const data = {
    cmd: "login",
    data: {
      account,
      password,
      token,
      last_token: last_token || "",
      type: "web",
      module,
      client_id,
    },
    msgid: new Date().getTime() + account,
  };
  return data;
}

export function logoutTpl() {
  const data = { cmd: "logout", msgid: new Date().getTime() };
  return data;
}
