import P2P from "../../operate/p2p/index";
import P2PPop from "./popSession";
import { getCallDeviceType, tplReplace } from "../../util";
import { CALL_TYPE_TEXT_MAP } from "./data";
import { p2pInviteTipTpl } from "./tpl";
import P2P_EVENT from "../../operate/p2p/event";
import Render from "./render";
import P2PPopSession from "./popSession";
import { useP2PProcess } from "./process";
import {
  BEFORE_MINE_ANSWER,
  MINE_ANSWER,
  MINE_P2P_NOTICE_MAP,
  TARGET_P2P_NOTICE_MAP,
} from "../../websocket/message";
import BASE_EVENT from "../../operate/base/event";

export default class P2PService extends P2P {
  constructor({ userInfo, ws }) {
    super({ userInfo, ws });
    this.initWsNotice();
    this.layerMap = {};
  }

  initWsNotice() {
    let mouted = null;
    function mountedEl(el) {
      mouted = el;
    }
    layui.use(() => {
      const { layer } = window.layui;
      window._rtc.ws.on(MINE_P2P_NOTICE_MAP.ringing, ({ user, call }) => {
        mouted = null;
        const target = this.getTarget(call);
        layer.open({
          type: 1,
          title: "来电提示",
          shade: 0,
          maxmin: true,
          content: tplReplace(p2pInviteTipTpl, {
            target: target,
            type: CALL_TYPE_TEXT_MAP[call.call_type],
          }),
          btn: ["接受", "拒绝"],
          success: (layero, index) => {
            this.layerMap[target] = index;
          },
          yes: async (index, layero) => {
            // 判断是否还存在这通通话
            await _rtc.ws.fire(BEFORE_MINE_ANSWER, {
              target,
              mountedEl,
              wsInfo: { user, call },
            });
            this.answerPop({ el: mouted, call }).then((popSession) => {
              _rtc.ws.fire(MINE_ANSWER, {
                target,
                popSession,
                wsInfo: { user, call },
              });
            });
            // layer.close(index);
            this.removeLayer(layer, target);
          },
          btn2: (index, layero) => {
            // 判断是否还存在这通通话
            this.hangup(call);
            this.removeLayer(layer, target);
          },
        });
      });

      window._rtc.ws.on(TARGET_P2P_NOTICE_MAP.available, ({ user, call }) => {
        const target = this.getTarget(call);
        this.removeLayer(layer, target);
      });
    });
  }

  async answerPop({ el, call }) {
    const popSession = new P2PPopSession();
    const render = new Render(el);
    render.openLoading(this);
    const session = await this.answer(call, useP2PProcess.call(popSession));
    session.on(BASE_EVENT.ADD_PLAYER_STREAM, (stream) => {
      render.addStream(session.playerPcInfo.stream);
    });
    render.setSession(session);
    popSession.init({ session, render });
    return popSession;
  }

  /**
   *@param {Object} options 配置对象
   * @param {Element} options.el 挂载元素
   * @param  {String} options.target 被叫号码
   * @param {'voice' | 'voice_video' | 'video'} options.callType 呼叫类型
   * @returns
   */
  async callPop({ el, target, callType = "voice_video", eid }) {
    const popSession = new P2PPopSession();
    const render = new Render(el);
    render.openLoading(this);
    const session = await this.call(
      {
        target,
        mediaConstraints: getCallDeviceType(callType),
        eid
      },
      useP2PProcess.call(popSession)
    );
    render.setSession(session); //呼叫后 ---  对方接听前  将session传给render
    popSession.init({ session, render });
    return popSession;
  }

  removeLayer(layer, target) {
    const index = this.layerMap[target];
    layer.close(index);
    delete this.layerMap[target];
  }
}
