const CHANNEL_EVENT = {
  CHANNEL_IN: 'channel_in',
  CHANNEL_ADD: 'channel_add',
  CHANNEL_OUT: 'channel_out',
  CHANNEL_DEL_USER: 'channel_del_user',
  CHANNEL_GET: "channel_get",
  CHANNEL_RELEASE: "channel_release",
};

export default CHANNEL_EVENT;
