import UserGroup from './UserGroup';
import { getMediaSource } from './MediaSource';

/**
 * @classdesc 全局配置
 * @member {UserInfo} userInfo 用户信息
 * @member {UserGroup} userGroup 用户组信息
 * @member {MediaSource} mediaSource 媒体信息
 * @member {boolean} isMediaSource 是否采用媒体源配置
 * @member {number} _t 对象创建时间戳
 */
class Config {
  constructor({ userInfo }) {
    this.userInfo = userInfo;
    this.mediaSource = getMediaSource();
    this.isMediaSource = userInfo.config.media_sources;
    this.userGroup = new UserGroup({ userInfo });
    this._t = new Date().getTime();
    this.init();
  }

  init() {}
}

//单例
function regiestConfig({ userInfo }) {
  window._rtcConfig = window._rtcConfig || new Config({ userInfo });
  return window._rtcConfig;
}

/**
 * @method
 * @description 获取全局配置对象
 * @param {Object} opt 配置参数
 * @param {UserInfo} opt.userInfo 用户信息
 * @returns {Config} 全局对象配置
 */
function getConfig() {
  return window._rtcConfig;
}
export { getConfig, regiestConfig };
