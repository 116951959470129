import Render from "./render";
import { useImNotice } from "./notice";

export default class ImService {
    constructor({ userInfo, ws, imApi }) {
        this.userInfo = userInfo;
        this.ws = ws;
        this.imApi = imApi;
        this.render = null;
        this.mountedId = null;
        this.inviteesList = [];
        this.chatMsgList = [];
        this.initImNotice();
        this.initInviteesList();
        this.ChatMsgList();
    }

    initImNotice() {
        const imNotice = useImNotice.call(this);
        this.ws.on("*", imNotice);
    }

    mounted(id) {
        this.mountedId = id;
    }

    /* 获取成员列表 */
    async initInviteesList() {
        console.log(this.userInfo.user.id,'===this.userInfo.user.id');
        this.inviteesList = await this.imApi.getInviteesListIm({
            user_id: this.userInfo.user.id,
            scopes: "1,2"
        });
    }

    /* 获取历史消息记录 */
    async ChatMsgList() {
        this.chatMsgList = await this.imApi.getChatMsgIm({
            user_id: this.userInfo.user.id
        });
    }

    callPopFormal() {
        this.render = new Render({
            mountedId: this.mountedId,
            userInfo: this.userInfo,
            inviteesList: this.inviteesList,
            chatMsgList: this.chatMsgList,
            imApi: this.imApi
        });
        this.render.openIM();
    }
}