import { heartTpl, loginTpl, logoutTpl } from "./tpl";
import { RECONNECT_MAX_COUNT, RECONNECT_TIME } from "../dict/index";
import { messageProcess } from "./message/index";
import {
  SOCKET_RECONNECT_FAIL,
  SOCKET_RECONNECT_SUCCESS,
  SOCKET_RECONNECTING,
  SOCKET_CLOSE,
} from "./message/index";
import { encryption } from "../util";

export default class Core_ws {
  //url:地址 account:账号 password:密码 token:唯一参数
  constructor({ url, account, password, token, last_token }) {
    if (!(url && account && password)) {
      console.error("websocket创建失败,信息缺失");
      return null;
    }
    this.url = url;
    this.account = account;
    this.password = password;
    this.token = token;
    this.last_token = last_token;
    this.websock = null;
    this.userInfo = {}; //用户信息
    this._observer = {}; //观察
    this._promisePool = {}; //promise池
    this.reconnectCount = 0; //重连次数
    this.lockReconnect = false; //是否正在重连
  }
  // 创建ws
  regiest({ account, password, token } = {}) {
    account && (this.account = account);
    password && (this.password = password);
    token && (this.token = token);
    return new Promise((resolve, reject) => {
      const _self = this;
      this.websock = new WebSocket(this.url);
      this.websock.onopen = (e) => {
        wsOpen.call(_self, e, resolve, reject);
      };
      this.websock.onerror = (e) => {
        wsError.call(_self, e, resolve, reject);
      };
      this.websock.onmessage = (e) => {
        wsMessage.call(_self, e, resolve, reject);
      };
      this.websock.onclose = (e) => {
        wsClose.call(_self, e, resolve, reject);
      };
    });

    function wsOpen(e, resolve, reject) {
      console.log("WebSocket连接成功");
      this.login()
        .then((data) => {
          this.userInfo = data;
          this.heart(data.config.sys_user_heart_time); //发送心跳
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    }
    function wsError(e, resolve, reject) {
      console.log("WebSocket连接发生错误", e);
      this.stopHeart();
      reject("WebSocket连接发生错误");
    }
    function wsMessage(e, resolve, reject) {
      let data = JSON.parse(e.data);
      let isNotice = data.cmd !== "rsp_msg"; //cmd 是通知   req_cmd是请求返回 判断req===rsp_msg
      let cmdData = data.data;
      let cmd = isNotice ? data.cmd : data.req_cmd;
      if (cmd === "heart") return;
      if (isNotice) {
        this.fire(cmd, cmdData); //触发监听
      } else {
        const { msgid, res_code } = data;
        const req = this._promisePool[msgid];
        if (!req) return;
        res_code === "0" ? req.resolve(cmdData) : req.reject(data);
        delete this._promisePool[msgid];
      }
    }
    function wsClose(e, resolve, reject) {
      console.log("websocketOnclose ===>", e, "时间 ===>", new Date());
      this.fire(SOCKET_CLOSE, {});
      console.log(e.code);
      this.websock = null;
      this.stopHeart();
      if (![1000].includes(e.code)) {
        this.reconnect();
      }
      // this.websock = null;
      // this.stopHeart();
      // if (e.code === 1000) {
      //   //|| e.code === 1006
      //   // this.stopHeart();
      // } else {
      //   this.reconnect();
      // }
    }
  }

  //事件注册
  on(type, fn) {
    // * 根据fn传入的对象进行注册
    if (
      type === "*" &&
      Object.prototype.toString.call(fn) === "[object Object]"
    ) {
      Object.keys(fn).forEach((key) => {
        if (this._observer[key]) {
          this._observer[key].push(fn[key]);
        } else {
          this._observer[key] = [fn[key]];
        }
      });
    } else {
      if (!this._observer[type]) {
        this._observer[type] = [fn];
      } else {
        this._observer[type].push(fn);
      }
    }
    return this;
  }

  //事件触发
  fire(type, data) {
    const oType = messageProcess(type, data, this.userInfo);
    // 先触发自定义的  后触发系统的
    if (oType !== type) {
      this._observer[oType] &&
        this._observer[oType].forEach((fn) => {
          fn && fn(data);
        });
    }
    this._observer[type] &&
      this._observer[type].forEach((fn) => {
        fn && fn(data);
      });
  }

  // 关闭事件
  off(type, callback) {
    const callbacks = this._observer[type] || [];
    const newCallbacks = callbacks.filter((fn) => fn != callback);
    this._observer[type] = newCallbacks;
  }

  // 数据发送
  wsSend(data) {
    this.websock.send(JSON.stringify(data));
  }

  heart(beattim = 10) {
    //心跳间隔
    const data = heartTpl();
    this.wsSend(data);
    this.heartWorker = setInterval(() => {
      this.wsSend(data);
    }, beattim * 1000);
  }

  stopHeart() {
    console.log("心跳停止", this.heartWorker);
    // this.heartWorker && this.heartWorker.terminate();
    window.clearInterval(this.heartWorker);
    this.heartWorker = null;
  }

  // 内部操作
  login() {
    return new Promise((resolve, reject) => {
      const password = encryption(this.password); //
      const data = loginTpl({
        account: this.account,
        password,
        token: this.token,
        last_token: this.last_token,
      });
      this._promisePool[data.msgid] = {
        resolve,
        reject,
      };
      this.wsSend(data);
    });
  }

  logout() {
    let data = logoutTpl();
    this.wsSend(data);
    this.websock = null;
  }

  reconnect() {
    this.fire(SOCKET_RECONNECTING, {});
    if (this.lockReconnect) return;

    // if (this.reconnectCount > RECONNECT_MAX_COUNT) {
    //   this.reconnectCount = 0;
    //   this.fire(SOCKET_RECONNECT_FAIL, {});
    //   this.stopHeart();
    //   this.websock = null;
    //   return;
    // }

    //没连接上会一直重连，设置延迟避免请求过多
    this.lockReconnect = true;
    setTimeout(async () => {
      try {
        this.reconnectCount++;
        await this.regiest();
        this.reconnectCount = 0;
        this.fire(SOCKET_RECONNECT_SUCCESS, {});
      } catch (error) {
        console.error(error);
      }
      this.lockReconnect = false; //本次重连结束
    }, RECONNECT_TIME);
  }
}
