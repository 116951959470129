
export const SENDMSG_IM = 'sendMsg_im';
export const MEMBERADD_IM = 'memberAdd_im';
export const DISMISSGROUP_IM = 'dismissGroup_im';
export const MEMBEROUT_im = 'memberOut_im';

export function useImNotice() {
    return {
        sendMsg_im(data) {
            return data
        },

        memberAdd_im(data) {
            return data
        },

        dismissGroup_im(data) {
            return data
        },

        memberOut_im(data) {
            return data
        }
    }
}