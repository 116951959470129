import api from '../../api/index';
import Session from '../base/Session';
import BASE_EVENT from '../base/event';

export default class ChannelList extends Session {
  constructor({ userInfo, ws }) {
    super();
    this.userInfo = userInfo;
    this.ws = ws;
    this.list = []; //children:人员
    this.init();
  }

  //初始化对讲组列表
  init() {
    this.initList();
  }

  async initList() {
    try {
      const param = { user_id: this.userInfo.user.id };
      const { channelList } = await api.getChannelList(param);
      this.list = channelList;
      this.fire(BASE_EVENT.UPDATE, this.list);
    } catch (error) {
      console.error(error);
    }
  }

  // 获取对讲组下人员
  async getChannelUsers(channel_id) {
    let channel = this.list.find((item) => item.channel_id === channel_id);
    if (!channel.children) {
      const children = await api.getChannelUser({ channel_id });
      channel.children = children;
      this.fire(BASE_EVENT.UPDATE, this.list);
    }
    return channel.children;
  }

  // 对讲组添加人员
  channelAddUser(channel_id, user) {
    const channel = this.list.find((item) => item.channel_id === channel_id);
    channel.children && channel.children.push(user);
    this.fire(BASE_EVENT.UPDATE, this.list);
  }

  // 对讲组下人员信息更新
  channelUpdateUser(channel_id, user_id, attr) {
    const channel = this.list.find((item) => item.channel_id === channel_id);
    const user =
      channel.children &&
      channel.children.find((item) => item.user_id === user_id);
    user && Object.assign(user, attr);
    this.fire(BASE_EVENT.UPDATE, this.list);
  }

  // 对讲组下删除人员
  channelRemoveUser(channel_id, user_id) {
    const channel = this.list.find((item) => item.channel_id === channel_id);
    const userIdx = channel.children.indexOf(
      (item) => item.user_id === user_id
    );
    channel.children.splice(userIdx, 0);
    this.fire(BASE_EVENT.UPDATE, this.list);
  }

  channelUpdateUserBatch(channel_id, attr) {
    const channel = this.list.find((item) => item.channel_id === channel_id);
    channel &&
      channel.children &&
      channel.children.forEach((item) => {
        Object.assign(item, attr);
      });
    this.fire(BASE_EVENT.UPDATE, this.list);
  }
}
