import axios from 'axios';
import { SUCCESS_CODE } from './code';
import { HTTP_URL } from '../dict/config';

// 创建axios实例
const service = axios.create({
  timeout: 300000, // 请求超时时间
  baseURL: HTTP_URL
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.res_code || SUCCESS_CODE;
    // 获取错误信息
    const message = res.data.res_msg;
    if (parseInt(code) !== SUCCESS_CODE) {
      console.error(message);
      alert(message);
      return Promise.reject(message);
    } else {
      return res.data.data;
    }
  },
  (error) => {
    console.log('err' + error);
    let { message } = error;
    if (message == 'Network Error') {
      message = '后端接口连接异常';
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时';
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常';
    }
    return Promise.reject(error);
  }
);

export default service;
