import { getAction, postAction } from './manage';

//推流
const sendPublishSDP = (url, param) => {
  return postAction(url, param);
};

//拉流
const sendPlayerSDP = (url, param) => {
  return postAction(url, param);
};

export default {
  sendPublishSDP,
  sendPlayerSDP
};
