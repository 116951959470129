import { USER_MEDIA, DISPLAY_MEDIA } from "../dict/index";
import {
  DEFAULT_VIDEO_CONSTRAINT,
  DEFAULT_AUDIO_CONSTRAINT,
  DEFAULT_DISPLAY_CONSTRAINT,
  HTTP_URL,
} from "../dict/index";

("use strict");

class RTCPublisher {
  constructor() {
    this.warterMarkerObj = null;
    this.pc = new RTCPeerConnection(null);
    this.onaddstream = null;
    this.originStream = null;
  }

  async publish({ url, type, mediaConstraints, waterMarker }) {
    try {
      mediaConstraints.audio &&
        this.pc.addTransceiver("audio", { direction: "sendonly" });
      mediaConstraints.video &&
        this.pc.addTransceiver("video", { direction: "sendonly" });
      this.originStream = await createStream(type, mediaConstraints);
      if (!this.pc) {
        console.warn("该rtc已断开");
        this.close();
        return;
      }
      this.originStream.getTracks().forEach((track) => {
        this.pc.addTrack(track);
      });
      // sdp协商
      const offer = await this.pc.createOffer();
      await this.pc.setLocalDescription(offer);
      return sendXHR(url, offer.sdp)
        .then(async (session) => {
          await this.pc.setRemoteDescription(
            new RTCSessionDescription({ type: "answer", sdp: session.sdp })
          );
          console.log("协商", this.pc);
          if (!this.pc) {
            this.close();
            return;
          }
          this.onaddstream && this.onaddstream({ stream: this.originStream });
        })
        .catch((e) => {
          this.close();
          throw e;
        });
    } catch (error) {
      this.close();
      throw error;
    }
  }

  close() {
    console.log("rtc关闭", this.originStream);
    this.onaddstream = null;
    this.pc && this.pc.close();
    this.warterMarkerObj && this.warterMarkerObj.remove();
    this.helpObj && this.helpObj.close();
    this.originStream &&
      this.originStream.getTracks().forEach((item) => {
        item.stop();
      });
    this.pc = null;
  }
}

class RTCPlayer {
  constructor() {
    this.pc = new RTCPeerConnection(null);
    this.onaddstream = null;
    this.init();
  }

  init() {
    this.pc.onaddstream = (event) => {
      this.onaddstream && this.onaddstream(event);
    };
  }

  async play({ url, audio, video }) {
    try {
      audio && this.pc.addTransceiver("audio", { direction: "recvonly" });
      video && this.pc.addTransceiver("video", { direction: "recvonly" });
      const offer = await this.pc.createOffer();
      await this.pc.setLocalDescription(offer);
      return sendXHR(url, offer.sdp).then(async (session) => {
        if (this.pc) {
          await this.pc.setRemoteDescription(
            new RTCSessionDescription({ type: "answer", sdp: session.sdp })
          );
        }
      });
    } catch (error) {
      this.close();
      throw error;
    }
  }

  close() {
    this.pc && this.pc.close();
    this.pc = null;
  }
}

function createStream(type = USER_MEDIA, mediaConstraints) {
  return new Promise(async (resolve, reject) => {
    try {
      let stream;
      if (type === USER_MEDIA) {
        stream = await navigator.mediaDevices.getUserMedia({
          audio: mediaConstraints.audio && DEFAULT_AUDIO_CONSTRAINT,
          video: mediaConstraints.video && DEFAULT_VIDEO_CONSTRAINT,
        });
      } else if (type === DISPLAY_MEDIA) {
        stream = await navigator.mediaDevices.getDisplayMedia(
          DEFAULT_DISPLAY_CONSTRAINT
        );
      }
      resolve(stream);
    } catch (error) {
      reject(error);
    }
  });
}

function sendXHR(url, data) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "json";
    xhr.open("post", `${HTTP_URL}${url}`, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onload = function () {
      if (xhr.status === 200) {
        resolve(xhr.response);
      }
    };
    xhr.onerror = function () {
      reject(xhr.response);
    };
    xhr.send(data);
  });
}

export { RTCPublisher, RTCPlayer };
