export const MINE_P2P_RING = 'mine_p2p_ring'; //我的振铃
export const MINE_P2P_ONCALL = 'mine_p2p_oncall'; //我的通话状态
export const MINE_P2P_AVAILABLE = 'mine_p2p_available'; //我的挂断

export const TARGET_P2P_RING = 'target_p2p_ring'; //对方的振铃
export const TARGET_P2P_ONCALL = 'target_p2p_oncall'; //对方的通话状态
export const TARGET_P2P_AVAILABLE = 'target_p2p_available'; //对方的挂断
export const TARGET_P2P_ANSWER = 'target_p2p_answer'; //对方的接听

export const OTHER_P2P_RING = 'other_p2p_ring'; //与我无关的通话的振铃
export const OTHER_P2P_ONCALL = 'other_p2p_oncall'; //与我无关的通话的通话状态
export const OTHER_P2P_AVAILABLE = 'other_p2p_available'; //与我无关的通话的挂断

export const BEFORE_MINE_ANSWER = 'before_mine_answer'; //在我应答前的事件
export const MINE_ANSWER = 'mine_answer'; //我应答的事件

export const CALLEE_OFFLINE = 'callee_offline'; //被叫不在线
export const CALLEE_BUSY = 'callee_busy'; //被叫正忙
export const CALLEE_NOT_SYS_USER = 'callee_not_sys_user'; //被叫非系统用户
export const TARGER_NOT_CALL = 'targer_not_call'; //被叫非系统用户
export const SYS_NOW_CALL = 'sys_now_call';

// 我的状态map
export const MINE_P2P_NOTICE_MAP = {
  ringing: MINE_P2P_RING,
  oncall: MINE_P2P_ONCALL,
  available: MINE_P2P_AVAILABLE
};

// 对方的状态map
export const TARGET_P2P_NOTICE_MAP = {
  ringing: TARGET_P2P_RING,
  oncall: TARGET_P2P_ONCALL,
  available: TARGET_P2P_AVAILABLE,
  answer: TARGET_P2P_ANSWER
};

// 其他人的状态map
export const OTHER_P2P_NOTICE_MAP = {
  ringing: OTHER_P2P_RING,
  oncall: OTHER_P2P_ONCALL,
  available: OTHER_P2P_AVAILABLE
};

export function useP2PNotice() {
  return {
    extension_state_notice(data, userInfo) {
      let oType = '';
      const { user, call } = data;
      if (user.id === userInfo.user.id) {
        oType = MINE_P2P_NOTICE_MAP[user.now_extension_state];
      } else if (
        call.caller === userInfo.user.phone ||
        call.callee === userInfo.user.phone
      ) {
        oType =
          call.caller === userInfo.user.phone &&
          user.now_extension_state === 'oncall'
            ? TARGET_P2P_NOTICE_MAP.answer
            : TARGET_P2P_NOTICE_MAP[user.now_extension_state];
      } else {
        oType = OTHER_P2P_NOTICE_MAP[user.now_extension_state];
      }
      return oType;
    }
  };
}
