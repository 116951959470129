import { ENCRYPTION_KEY } from "../dict";
import CryptoJS from "crypto-js";
import moment from "moment";

async function supportDetection() {
  const support = {
    audio: false,
    video: false,
  };
  if (!isSupportMediaDevices()) {
    alert("无法获取设备  请检查是否是可信赖网址");
    return support;
  }
  if (navigator.mediaDevices.enumerateDevices) {
    let devices = await navigator.mediaDevices.enumerateDevices();
    //音频检测
    let { audio, video } = devices.reduce((total, item) => {
      if (item.kind === "audioinput") {
        total.audio = true;
        support.audio = true;
      }
      if (item.kind === "videoinput") {
        total.video = true;
        support.video = true;
      }
      return total;
    }, {});
    !audio && alert("没有检测到音频设备");
    !video && alert("没有检测到视频设备");
  }
  return support;
}

function isSupportMediaDevices() {
  return navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
}

//获取uuid
const uuid = () => {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((Number(s[19]) & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
};

function addCssByLink(url) {
  var doc = document;
  var link = doc.createElement("link");
  link.setAttribute("rel", "stylesheet");
  link.setAttribute("type", "text/css");
  link.setAttribute("href", url);

  var heads = doc.getElementsByTagName("head");
  if (heads.length) heads[0].appendChild(link);
  else doc.documentElement.appendChild(link);
}

function addScript(url) {
  var doc = document;
  var script = document.createElement("script");
  script.setAttribute("type", "text/javascript");
  script.setAttribute("src", url);

  var heads = doc.getElementsByTagName("head");
  if (heads.length) heads[0].appendChild(script);
  else doc.documentElement.appendChild(script);
}

function triggerScale(width, height, el) {
  var targetX = 1228;
  var targetY = 577;
  var targetRatio = 1228 / 577;
  var currentX = width;
  var currentY = height;

  // 1.缩放比例  3840 / 2160 => 2
  var ratio = currentX / targetX;
  var currentRatio = currentX / currentY;
  var transformStr = "";
  if (currentRatio > targetRatio) {
    ratio = currentY / targetY;
    transformStr = `transform:scale(${ratio}) translateX(-${
      targetX / 2
    }px); left:50%;`;
  } else {
    transformStr = `transform:scale(${ratio}) translateY(-50%);
    transform-origin: top left;
    height:${targetY}px;
    width:${targetX}px;
    top:50%;`;
  }
  /* 
   translateY(-${
      targetY / 2 - height / 2
    }px);
    transform-origin: center left;
     */
  el.setAttribute("style", transformStr);
}

// 加密
const encryption = (content) => {
  let key = "xzk0kKx1ehUYGPxp";
  // key格式化处理
  key = CryptoJS.enc.Utf8.parse(key);
  // 偏移量长度为16位, 注：偏移量需要与后端定义好，保证一致
  let iv = "";
  const encryptedContent = CryptoJS.AES.encrypt(content, key, {
    iv: "",
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Base64.stringify(encryptedContent.ciphertext);
};

const formatTime = (second, mat) => {
  second = Number(second);
  if (isNaN(second)) return "";
  second = second && second.toString().length === 13 ? second : second * 1000;
  return second ? moment(second).format(mat) : "";
};

export {
  supportDetection,
  isSupportMediaDevices,
  uuid,
  addCssByLink,
  addScript,
  triggerScale,
  encryption,
  formatTime
};
