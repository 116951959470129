
export const IS_GROUP = {
    YES: '1',
    NO: '0'
};

//内容类型枚举
export const CONTENT_TYPE = {
    TEXT: "1",
    IMG: "2",
    VIDEO: "3",
    AUDIO: "4",
    FILE: "5",
    POSITION: "8",
    OHTER: "9",
    SYSTEM: "10"
}