import { MICROPHONE_STATE, CHANNEL_STATE } from '../../dict/index';
import Session from '../base/Session';

export default class ChannelSession extends Session {
  constructor(
    { publishPcInfo, playerPcInfo, state, channel, channelAllUsers },
    event,
    userInfo
  ) {
    super({ event });
    this.publishPcInfo = publishPcInfo;
    this.playerPcInfo = playerPcInfo;
    this.channel = channel;
    this.channelAllUsers = channelAllUsers || [];
    this.userInfo = userInfo;
    this.speaker = {};
    this.microphoneState = MICROPHONE_STATE.RELEASE;
    this.state = state || CHANNEL_STATE.AVAILABLE;
    this.tempChannel = {
      exist: false,
      info: null
    };
    this.init();
  }

  init() {}

  stopPublish() {
    this.publishPcInfo &&
      this.publishPcInfo.terminate &&
      this.publishPcInfo.terminate();
    this.publishPcInfo = null;
  }

  setSpeaker(speaker, playerPcInfo) {
    const { user_id } = speaker;
    this.speaker = speaker;
    this.playerPcInfo = playerPcInfo;
    this.microphoneState =
      user_id === this.userInfo.user.id
        ? MICROPHONE_STATE.MINE_SPEAKING
        : MICROPHONE_STATE.OTHER_SPEAKING;
  }

  cleanSpeaker() {
    this.playerPcInfo &&
      this.playerPcInfo.terminate &&
      this.playerPcInfo.terminate();
    this.playerPcInfo = null;
    this.speaker = {};
    this.microphoneState = MICROPHONE_STATE.RELEASE;
  }

  cleanChannel() {
    this.channel = {};
    this.currentChannel = {};
    this.state = CHANNEL_STATE.AVAILABLE;
  }
}
