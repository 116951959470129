import {
  BTN_TYPE,
  MEET_USER_STATE,
  IS_MEET_COMPERE,
  ICON_TYPE,
  MEET_LAYOUUT
} from '../../dict/index';

export const meetContentTpl = `
  <div class="meet-container">
        <div class="meet-top-tools common-top-tools">
          <div class="left-info">
          </div>
          <div class="right-info" >
            <span class="time">21:54</span>
            <button btn-type=${BTN_TYPE.FULLSCREEN}>
              <i class="iconfont iconquanping2" ></i>
            </button>
            <button btn-type=${BTN_TYPE.SHOWUSERLIST}>
              <i class="iconfont iconzhanshilie"></i>
            </button>
          </div>
        </div>

        <div class="video-container common-container">
            <video></video>
        </div>

        <div class="meet-bottom-tools common-bottom-tools">

        </div>
  </div>
`;

export const meetBottomBtnTpl = `
  <i class="iconfont iconyuyin"></i>
  <span class="text"> 静音 </span>
  </button>
`;

export const meetTabTpl = `<div class="meet-tab">
<span class="title">{{meetname}}</span>
<div class="actions">
  <button btn-type=${BTN_TYPE.CLOSEPOP}>
    <i class="iconfont iconshanchu1"></i>
  </button>
</div>
</div>`;

export const meetTpl = `
<div class="meet-main">
  <div class="meet-container">
    <div class="meet-top-tools common-top-tools">
      <div class="left-info">
        {{meetname}}
      </div>
      <div class="right-info">
        <div class="talking">
          正在发言: <span class="name">暂无</span>
        </div>
        <div class="actions">
          <button btn-type=${BTN_TYPE.SHOWUSERLIST}>
            <i class="iconfont iconzhanshilie" ></i>
          </button>
        </div>
      </div>
    </div>

    <div class="video-container common-container">

    </div>

    <div class="meet-bottom-tools common-bottom-tools">

    </div>
  </div>

  <div class="meet-side">

    <div class="common-container">
      <div class="search-wrap">
        <i class="iconfont iconsousuo"></i>
        <input
          type="text"
          class="search-input"
          placeholder="搜索成员"
        />
      </div>
      <ul class="user-list">

      </ul>
    </div>
  </div>
</div>
`;

export const timeTpl = `<span class="time">21:54</span>`;

export const userItemTpl = `
<li class="user-item">
  <div class="left-info">
    <!-- <img src="{{headimg}}" class="avatar" /> -->
    <div class="info-desc">
      <div class="name">{{name}}</div>
      <div class="desc">{{desc}}</div>
    </div>
  </div>
  <div class="actions"  data-phone={{phone}}>

  </div>
</li>`;

/* export const meetControlsTpl = `
<button btn-type=${BTN_TYPE.MUTEALL}>全体静音</button>
<button btn-type=${BTN_TYPE.UNMUTEALL}>全体解除静音</button>`;
 */
export const containerToolBtnTpl = `
  <button class="tool-btn {{btnType}}" btn-type={{btnType}}>
    <i class="iconfont {{icon}}"></i>
    <span class="text"> {{text}} </span>
  </button>`;

export const userBtnTpl = `
  <button class="user-btn {{btnType}}" btn-type={{btnType}} title={{text}}>
      <i class="iconfont {{icon}}"></i>
    </button>
  `;

export const videoTpl = `<video autoplay></video>`;

export const audioTpl = `
  <audio autoplay></audio>
  <i class="iconfont iconyuyin1 center yuyin"></i>
`;

export const layoutTpl = `
  <div class="layout">
    <button btn-type=${MEET_LAYOUUT.ONE_AND_ONE}>
      <i class="iconfont iconicon_yifenping"></i>
    </button>
    <button btn-type=${MEET_LAYOUUT.TWO_AND_TWO}>
      <i class="iconfont iconicon_sifenping"></i>
    </button>
    <button  btn-type=${MEET_LAYOUUT.ONE_AND_FIVE}>
      <i class="iconfont iconicon_wufenping"></i>
    </button>
    <button  btn-type=${MEET_LAYOUUT.THREE_AND_THREE}>
      <i class="iconfont iconicon_jiufenping"></i>
    </button>
    <button btn-type=${MEET_LAYOUUT.FOUR_AND_FOUR}>
      <i class="iconfont iconicon_shiliufenping"></i>
    </button>
  <div>
`;

export const meetInviteTip = `<div class="invite">会议 <span class="info">{{meetname}}</span> 邀请加入 <span class="info">{{type}}</span></div>`;
