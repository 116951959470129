import Channel from "../../operate/channel/index";
import Render from "./render";

export default class ChannelService extends Channel {
    constructor({ userInfo, ws, channelList, channelSession }) {
        super({ userInfo, ws, channelList, channelSession });
        this.render = null;
        this.mountedId = null;
        this.channelObj = {};
    }

    /**
    *@description 挂载元素 不传默认弹窗形式展现
    * @param {String} id 挂载id
    */
    mounted(id) {
        this.mountedId = id;
    }

    callPopFormal({ eid } = { eid: "" }) {
        console.log(eid,'==eid')
        this.render = new Render({
            mountedId: this.mountedId,
            userInfo: this.userInfo,
            _channel: this,
            eid: eid
        });
        this.render.openChannel(this.channelList.list);
        return this.channelSession;
    }
}