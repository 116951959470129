import { tplReplace } from "../../util";
import Meet from "../../operate/meet/index";
import { useMeetProcess } from "./process";
import Render from "./render";
import { meetInviteTip } from "./tpl";
import { MEET_TYPE, MEET_TYPE_TEXT_MAP } from "../../dict/index";
import BASE_EVENT from "../../operate/base/event";

export default class MeetService extends Meet {
  constructor({ userInfo, ws }) {
    super({ userInfo, ws });
    this.render = null;
    this.mountedId = null;
    this.process = useMeetProcess.call(this);
    this.isOpen = false;
    this.meetlistObj = {};
    this.initWsNotice();
    this.initMeetList();
  }

  isMine(phone) {
    return this.userInfo.user.phone === phone;
  }

  async initMeetList() {
    const videoList = await this.api.meetList({
      user_id: this.userInfo.user.id,
      type: MEET_TYPE.MEET_VIDEO,
    });
    const audioList = await this.api.meetList({
      user_id: this.userInfo.user.id,
      type: MEET_TYPE.MEET_VOICE,
    });
    [...videoList, ...audioList].forEach((item) => {
      this.meetlistObj[item.meetnum] = item;
    });
  }

  initWsNotice() {
    layui.use(() => {
      const { layer } = window.layui;
      window._rtc.ws.on("meet_invite", ({ meet, meetAddUser }) => {
        layer.open({
          type: 1,
          title: "会议邀请",
          shade: 0,
          maxmin: true,
          content: tplReplace(meetInviteTip, {
            meetname: meet.meetname,
            type: MEET_TYPE_TEXT_MAP[meet.type],
          }),
          btn: ["接受", "拒绝"],
          yes: (index, layero) => {
            this.render = new Render({ mountedId: this.mountedId });
            this.meetSession = this.answer(meet, this.process);
            this.meetSession.on(BASE_EVENT.ADD_PLAYER_STREAM, (stream) => {
              this.render.playMedia(stream); //播放会议
            });
            this.addProcessFail();
            this.render.setSession(this.meetSession);
            layer.close(index);
          },
          btn2: (index, layero) => {
            layer.close(index);
          },
        });
      });
    });
  }

  /**
   *@description 挂载元素 不传默认弹窗形式展现
   * @param {String} id 挂载id
   */
  mounted(id) {
    this.mountedId = id;
  }

  callPopFormal({ meetnum, users, eid }) {
    // 打开弹窗
    const meet = this.meetlistObj[meetnum];
    this.render = new Render({
      mountedId: this.mountedId,
      userInfo: this.userInfo
    });
    this.meetSession = this.call({ meet, users, eid }, this.process);
    this.meetSession.on(BASE_EVENT.ADD_PLAYER_STREAM, (stream) => {
      this.render.playMedia(stream);
    });
    this.addProcessFail();
    this.render.setSession(this.meetSession);
    this.render.openMeet(meet);
    return this.meetSession;
  }

  async callPopTempVoice({ users, eid } = { users: [], eid: "" }) {
    // 打开弹窗
    const meet = await this.api.tempMeet({
      type: MEET_TYPE.MEET_VOICE,
      user_id: this.userInfo.user.id,
    });
    this.render = new Render({
      mountedId: this.mountedId,
      userInfo: this.userInfo,
    });
    this.meetSession = await this.callTempVoice({ users, meet, eid }, this.process);
    this.meetSession.on(BASE_EVENT.ADD_PLAYER_STREAM, (stream) => {
      this.render.playMedia(stream);
    });
    this.addProcessFail();
    this.render.setSession(this.meetSession);
    this.render.openMeet(meet);
    return this.meetSession;
  }

  async callPopTempVideo({ users, eid } = { users: [], eid: "" }) {
    // 打开弹窗
    const meet = await this.api.tempMeet({
      type: MEET_TYPE.MEET_VIDEO,
      user_id: this.userInfo.user.id,
    });
    this.render = new Render({
      mountedId: this.mountedId,
      userInfo: this.userInfo,
    });
    this.meetSession = await this.callTempVideo({ users, meet, eid }, this.process);
    this.meetSession.on(BASE_EVENT.ADD_PLAYER_STREAM, (stream) => {
      this.render.playMedia(stream);
    });
    this.addProcessFail();
    this.render.setSession(this.meetSession);
    this.render.openMeet(meet);
    return this.meetSession;
  }

  // 推拉流失败的处理
  addProcessFail() {
    this.meetSession.on(BASE_EVENT.PUBLISH_FIRE, () => {
      console.error("会议推流失败");
      this.render.closePop();
    });
    this.meetSession.on(BASE_EVENT.PLAYER_FIRE, () => {
      console.error("会议拉流失败");
      this.render.closePop();
    });
  }
}
