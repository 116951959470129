import { getAction, postAction } from './manage';

//消息列表
const getChatMsgIm = (param) => {
  param.cmd = 'getChatMsg_im';
  return getAction('/dispatch/api', param);
};

//删除消息
const deleteChatMsgIm = (param) => {
  param.cmd = 'deleteChatMsg_im';
  return getAction('/dispatch/api', param);
};

//点对点消息列表详情
const getPointChatMsgIm = (param) => {
  param.cmd = 'getPointChatMsg_im';
  return getAction('/dispatch/api', param);
};

//群聊消息列表详情
const getGroupChatMsgIm = (param) => {
  param.cmd = 'getGroupChatMsg_im';
  return getAction('/dispatch/api', param);
};

//发送消息
const sendMsgIm = (param) => {
  param.cmd = 'sendMsg_im';
  return getAction('/dispatch/api', param);
};

//获取chatUser
const getChatUserIm = (param) => {
  param.cmd = 'getChatUser_im';
  return getAction('/dispatch/api', param);
};

//获取群信息详情
const getGroupInfoIm = (param) => {
  param.cmd = 'getGroupInfo_im';
  return getAction('/dispatch/api', param);
};

//更新群名称、公告
const updateIm = (param) => {
  param.cmd = 'update_im';
  return getAction('/dispatch/api', param);
};

//解散群聊
const dismissGroupIm = (param) => {
  param.cmd = 'dismissGroup_im';
  return getAction('/dispatch/api', param);
};

//删除群成员
const deleteMemberIm = (param) => {
  param.cmd = 'deleteMember_im';
  return getAction('/dispatch/api', param);
};

//添加群成员
const addMemberIm = (param = {}) => {
  param.cmd = 'addMember_im';
  return getAction('/dispatch/api', param);
};

//获取当前用户下所有人员信息
const getInviteesListIm = (param = {}) => {
  param.cmd = 'getInviteesList_im';
  return getAction('/dispatch/api', param);
};

//创建群（同时可加人）
const createIm = (param = {}) => {
  param.cmd = 'create_im';
  return getAction('/dispatch/api', param);
};

//消息设为已读
const updateCheckFlagIm = (param = {}) => {
  param.cmd = 'updateCheckFlag_im';
  return getAction('/dispatch/api', param);
};

//点对点用户详情
const getPointUserInfo = (param = {}) => {
  param.cmd = 'getPointUserInfo';
  return getAction('/dispatch/api', param);
};

//上传文件
const uploadByFileType = (param = {}) => {
  param.append("cmd", "uploadByFileType");
  return postAction("/dispatch/api", param);
};

export default {
  getChatMsgIm,
  deleteChatMsgIm,
  getPointChatMsgIm,
  getGroupChatMsgIm,
  sendMsgIm,
  getChatUserIm,
  getGroupInfoIm,
  updateIm,
  dismissGroupIm,
  deleteMemberIm,
  getInviteesListIm,
  addMemberIm,
  createIm,
  updateCheckFlagIm,
  getPointUserInfo,
  uploadByFileType
};
