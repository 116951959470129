const BTN_TYPE = {
  CLOSEPOP: "closePop",
  FULLSCREEN: "fullScreen",
  SHOWUSERLIST: "showuserlist",
  MUTED: "muted",
  UNMUTED: "unmuted",
  DEAF: "deaf",
  UNDEAF: "undeaf",
  MEETFLOOR: "meetfloor",
  MEETHANGUP: "meethangup",
  MEETKICK: "MEETKICK",
  CALLAGAIN: "CALLAGAIN",
  MUTEALL: "MUTEALL",
  UNMUTEALL: "UNMUTEALL",
  SHARE_SCREEN: "SHARE_SCREEN",
  CANCEL_SHARE_SCREEN: "CANCEL_SHARE_SCREEN",
  PLAY_MUSIC: "PLAY_MUSIC",
  BREAK_MUSIC: "BREAK_MUSIC",
  ADD_OUTLINE: "ADD_OUTLINE",
  OPEN_DECODER: "OPEN_DECODER",
  CLOSE_MEET: "CLOSE_MEET",
  FLOOR: "FLOOR",
  MEETOFF: "meetOff",
  ABOUT_MUTE: "about_mute",
  ABOUT_DEAF: "about_deaf",
  ABOUT_ONWALL: "about_onwall",
  ABOUT_INFO: "about_info",
  ABOUNT_SHARE: "about_share",
  ON_WALL: "onwall",
  CANCEL_ON_WALL: "CANCEL_ON_WALL",
  HANGUP: "hangup",
  INFO: "info",
  ADD_WX_MEET: "add_wx_meet",
  ABOUT_HELP: "about_help"
};

const ICON_TYPE = {
  MUTED: "iconyuyin",
  CANCELMUTED: "iconjinyan",
  DEAF: "iconshengyin",
  CANCELDEAF: "iconguanbishengyin",
  LAYOUT: "iconsheweizhupingbeifen",
  SHARE_SCREEN: "icongongxiangzhuomian",
  CANCEL_SHARE: "icon-s-quxiaogongxiang1",
  HNAGUP: "iconguaduan1",
  MEETKICK: "icontichujiaoshi",
  CALLAGAIN: "iconicon-test",
  MEETOFF: "iconguanbizhongqi",
  ADD_WX_MEET: "iconweixin",
  HELP: "iconlianjie",
  CANCLE_HELP: "iconlink-off"
};

const BTN_CMD = {
  CLOSEPOP: "closePop",
  FULLSCREEN: "fullScreen",
  SHOWUSERLIST: "showuserlist",
  MUTED: "muted",
  UNMUTED: "unmuted",
  DEAF: "deaf",
  UNDEAF: "undeaf",
  MEETFLOOR: "meetfloor",
  MEETHANGUP: "meethangup",
  MEETKICK: "MEETKICK",
  CALLAGAIN: "CALLAGAIN",
  MUTEALL: "MUTEALL",
  UNMUTEALL: "UNMUTEALL",
  SHARE_SCREEN: "SHARE_SCREEN",
  CANCEL_SHARE_SCREEN: "CANCEL_SHARE_SCREEN",
  PLAY_MUSIC: "PLAY_MUSIC",
  BREAK_MUSIC: "BREAK_MUSIC",
  ADD_OUTLINE: "ADD_OUTLINE",
  OPEN_DECODER: "OPEN_DECODER",
  CLOSE_MEET: "CLOSE_MEET",
  FLOOR: "FLOOR",
  MEETOFF: "meetOff",
  ON_WALL: "onwall",
  CANCEL_ON_WALL: "CANCEL_ON_WALL",
  HANGUP: "hangup",
  INFO_SHOW: "info_show",
  INFO_HIDE: "info_hide",
  HELP: "help",
  CANCLE_HELP: "cancle_help",
};

export { BTN_TYPE, ICON_TYPE, BTN_CMD };
