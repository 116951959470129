
export const imTpl =`
    <div class="im-container">
        <div class="bar">
            <div class="userImg"></div>
        </div>

        <div class="main">
            <div class="im-content">
                <div class="im-record child-margin-right">
                    <div class="list-search">
                        <div class="im-search-btn"></div>
                        <input type="text" class="search-input" placeholder="搜索..."/>
                        <div class="addIm-btn">
                            <button class="addIM-icon"></button>
                        </div>
                    </div>
            
                    <div class="record-list">
                        
                    </div>
                </div>

                <div class="chatBox">
                    <div class="im-header">
                        <div class="headerName">{{ headerName }}</div>
                        <div class="btns">
                            <button class="header-more"></button>
                            <button class="close"></button>
                        </div>
                    </div>

                    <div class="im-main-content">

                    </div>

                    <div class="box-im-main-content">
                        <div class="content">
                            <div class="empty"></div>
                        </div>
                    </div>
                </div>

                <div class="moreInfo hide" id="im-group-detial">
                    <div class="moreHheader">
                        <div class="right_search">
                            <button class="right_searchBtn"></button>
                            <input type="text" class="right-search-input" placeholder="搜索..."/>
                        </div>
                    </div>

                    <div class="right-body">
                        <div class="right-list" id="groupDetialContainer">

                        </div>

                        <div class="info-form" id="groupDetialForm">
                            
                        </div>
                        <button class="disSolve" id="disSolve">{{btnText}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
`

export const rightUserItemTpl = `
    <div class="item show">
        <img src={{icon}} />
        <p data={{user}} class="text">{{text}}</p>
        <button class="remove hide" id="{{userId}}"></button>
    </div>
`

export const groupNameAndGongGaoTpl = `
    <div class="info-item">
        <div class="label">
            <img src={{icon}} />
            {{name}}
        </div>
        <input class="info-input" value={{value}}></input>
    </div>
`

export const imRecordListItemTpl = `
    <div class="chat-item show noactive">

        <div class="avatar-wrappeer">
            <div class="badge">
                <img src="{{avatar}}" />
                <sup class="badge-content hide">{{newMsgCount}}</sup>
            </div>
        </div>

        <div class="info-wrappeer">
            <div class="name">{{name}}</div>
            <div class="bottom">
                <span class="message">{{content}}</span>
                <p class="time">{{time}}</p>
            </div>
        </div>

    </div>
`

export const chatContentTpl = `
    <div class="message" id="msgContainer">
        <div class="message-more">
            <span href="" class="moreBtn">加载更多</span>
        </div>

    </div>
`

export const msgSysItemTpl =  `
    <div class="msg-item sys">
        <div class="text-content">
            <p>{{sysInfo}}</p>
        </div>
    </div>
`

export const msgItemTpl = `
    <div class="msg-item" issender={{issender}}>
        <div class="msg-item sys">
            <div class="text-content">
                <p>{{time}}</p>
            </div>
        </div>

        <div class="info-wrapper">
            <div class="avatar-wrapper">
                <img class="head-icon" src={{headimg}} />
            </div>
            <div class="info-content">
                <p class="user-name">{{userName}}</p>
                <div class="info-msg-content">
                    <div class="item-content">

                    </div>
                </div>
            </div>
        </div>
    </div>
`

export const textMsgTpl = `
    <p class="text">{{content}}</p>
`
export const picMsgTpl = `
    <div class="image-content">
        <img src={{url}} class="msgPic" />
    </div>
`

export const audioMsgTpl = `
    <div class="iAudio" direction={{direction}}>
        <p class="time"></p>
        <audio src={{url}} class="audioItem"></audio>
        <div class="play" state="pause"></div>
    </div>
`

export const videoMsgTpl = `
    <div class="iVideo">
        <video src={{url}} preload="auto" muted></video>
        <i class="iconfont iconshipinchakan play"></i>
    </div>
`

export const fileMsgTpl = `
    <a href={{href}} download="file" class="file">
     <img src={{url}} class="pre-img" />
     <p class="text">{{name}}</p>
    </a>
`

export const chatBoxFooterTpl = `
    <div class="input-content">
        <div class="tool-wrapper">
            <div class="left">
                <div class="btn emjoy">
                    <img src={{emjoy}} />
                </div>
                <div class="btn wenjian">
                    <img src={{wenjian}} />
                    <input type="file" id="upload-file" style="display: none" />
                </div>
                <div class="btn mic">
                    <img src={{mic}} />
                </div>
                <div class="btn back">
                    <i class="iconfont iconshangyibu back1"></i>
                </div>

                <div class="emjoy-box hide">
                    <div class="list-inner">
                    
                    </div>
                </div>
            </div>

            <div class="right"></div>
        </div>

        <div class="content-wrapper">
            <div class="content-input">
                <div class="im-input" id="inputContent" contenteditable="true"></div>
            </div>

            <div class="footer">
                <button class="send-btn">发送</button>
            </div>
        </div>
    </div>
`

export const micOpeartTpl = `
    <div class="input-container">
        <button title="麦克风" class="btns-item" state="release"></button>
        <div class="other-action">
            <button class="item grey play">播放</button>
            <button class="item hide pause">暂停</button>
            <button class="item grey clear">清除</button>
        </div>
        <audio class="audioBot" src={{url}}></aduio>
    </div>
`

export const emjoyTpl = `
    <img src={{emjoy}} title={{face}} class="qqemoji" />
`

export const maskTpl = `
    <div class="im-mask">
        <div class="modal model-left">
            <div class="modal-left-header">
                <div class="modal-search">
                    <button class="user-search-btn"></button>
                    <input type="text" class="search-input" placeholder="搜索..."/>
                </div>
            </div>

            <div class="modal-left-content">
            
            </div>
        </div>

        <div class="modal model-right">
            <div class="modal-right-header">
            已选择
                <span class="number">0</span>
            个联系人
            </div>

            <div class="modal-right-content">
            
            </div>

            <div class="modal-btns">
                <button class="submit-btn">确认</button>
                <button class="cancle-btn">取消</button>
            </div>
        </div>
    </div>
`

export const userListItemTpl = `
    <div class="user-check-item show" data-type={{id}}>
        <div class="left">
            <div class="avatar"></div>
            <p class="userName">{{userName}}<p>
        </div>

        <input type="checkbox" class="checkBtn" data={{user}} id={{id}} />
    </div>
`

export const checkedListTpl = `
    <div class="user-check-item show">
        <div class="left">
            <div class="avatar"></div>
            <p class="userName">{{userName}}<p>
        </div>

        <input type="checkbox" class="checkBtn" data="{{user}}" checked/>
    </div>
`

export const videoTpl = `
    <div class="view-dialog">
        <i class="iconfont iconshanchu1 close"></i>
        <div class="main">
            
        </div>
        <div class="action">
            <a href="" download class="downIco">
                <i class="iconfont iconxiazai down"></i>
            </a>
        </div>
    </div>
`

export const videoPreviewTpl = `
    <video class="img" autoplay controls src={{url}} ></video>
`

export const picPreviewTpl = `
    <img class="img" src={{url}}></img>
`
