import { BTN_CMD, CALL_TYPE } from "../../dict/index";
import {
  createTpl,
  tplReplace,
  isNodeEl,
  getBtnCmd,
  getBtnType,
  getBtn,
} from "../../util";
import { ACTIONSBTNS, btnNextInfo } from "./data";

export async function handleActionsEvent(e, mediaEl) {
  let flag = true;
  const btnType = getBtnType(e);
  const cmd = getBtnCmd(e);
  switch (cmd) {
    case BTN_CMD.MUTED:
      this.session.muted();
      mediaEl.muted = true;
      break;
    case BTN_CMD.UNMUTED:
      this.session.cancelMuted();
      mediaEl.muted = false;
      break;
    case BTN_CMD.DEAF:
      this.session.deaf();
      break;
    case BTN_CMD.UNDEAF:
      this.session.cancelDeaf();
      break;
    case BTN_CMD.SHARE_SCREEN:
      await this.session.shareScreen(() => {
        changeBtnInfo.call(this, btnType, BTN_CMD.CANCEL_SHARE_SCREEN);
      });
      break;
    case BTN_CMD.CANCEL_SHARE_SCREEN:
      this.session.cancelShare();
      break;
    case BTN_CMD.HANGUP:
      if (this.session && (this.session.call || this.session.target)) {
        this.p2pInstance.hangup(this.session.call, this.session.target);
      }
      this.closePop();
      flag = false;
      break;
    case BTN_CMD.INFO_SHOW:
      this.loadReportInfo();
      this.reportInfoEl.setAttribute("data-show", cmd);
      break;
    case BTN_CMD.INFO_HIDE:
      this.stopReportInfo();
      this.reportInfoEl.setAttribute("data-show", cmd);
      break;
    case BTN_CMD.HELP:
      this.session.help(this.session, mediaEl);
      break;
    case BTN_CMD.CANCLE_HELP:
        this.session.cancelHelp(this.session);
        break;
    default:
      flag = false;
      break;
  }
  if (flag) {
    changeBtnInfo.call(this, btnType, cmd);
  }
}

// 更改按钮信息
function changeBtnInfo(btnType, btnCmd) {
  const findBtn = this.actionsEl.querySelector(`.${btnType}`);
  const nextBtn = btnNextInfo[btnCmd];
  findBtn.setAttribute("btn-cmd", nextBtn.btnCmd);
  findBtn.setAttribute("title", nextBtn.text);
  findBtn.querySelector(".iconfont").className = `iconfont ${nextBtn.icon}`;
  /* findBtn.innerText = nextBtn.text; */
}

export const P2PPOP_EVENT = {
  CLOSE_POP: "close_pop",
};
