
export default class imEvent {
    constructor(imApi) {
        this.imApi = imApi;
    }

    async createGroupIM(param) {
        const groupInfo =  await this.imApi.createIm(param);
        return groupInfo;
    }

    async addGroupIM(param) {
        const groupInfo =  await this.imApi.addMemberIm(param);
        return groupInfo;
    }

    async delMemberIm(param) {
        const result =  await this.imApi.deleteMemberIm(param);
        return result;
    }

    async getGroupChatMsgList(param) {
        const groupList =  await this.imApi.getGroupChatMsgIm(param);
        return groupList;
    }

    async getPointChatMsgList(param) {
        const pointList =  await this.imApi.getPointChatMsgIm(param);
        return pointList;
    }

    async sendIM(param) {
        const sendInfo =  await this.imApi.sendMsgIm(param);
        return sendInfo;
    }

    // 设为消息已读
    async updateCheckFlagIm(param) {
        const result =  await this.imApi.updateCheckFlagIm(param);
    }

    async getGroupDetialInfo(param) {
        const detial = await this.imApi.getGroupInfoIm(param);
        return detial;
    }

    async disMissGroup(param) {
        const result = await this.imApi.dismissGroupIm(param);
        return result;
    }

    async updateGroupName(param) {
        const result = await this.imApi.updateIm(param);
        return result;
    }
}