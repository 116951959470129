//视频约束
const DEFAULT_VIDEO_CONSTRAINT = {
  width: 640,
  height: 360,
  frameRate: { ideal: 30, max: 30, min: 30 },
};

//语音约束
const DEFAULT_AUDIO_CONSTRAINT = {
  autoGainControl: true,
};

//共享屏幕约束
const DEFAULT_DISPLAY_CONSTRAINT = {
  audio: true,
  video: { width: 640, height: 360 },
};

// 视频码率
const DEFALT_BIT_RANGE = 2048;

export {
  DEFAULT_VIDEO_CONSTRAINT,
  DEFAULT_AUDIO_CONSTRAINT,
  DEFAULT_DISPLAY_CONSTRAINT,
  DEFALT_BIT_RANGE,
};
