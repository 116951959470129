import RTCBase from "../base/index";
import MeetSession from "./MeetSession";
import {
  getStorage,
  setStorage,
  getCallDeviceType,
  removeStorage,
} from "../../util/index";
import { MEET_NUMBER_KEY, MEET_STATE } from "../../dict/index";
import { useMeetNotice } from "./notice";
import { MEET_TYPE } from "../../dict";
import BASE_EVENT from "../base/event";
import { useAddStream } from "../../webrtc/stream";

export default class Meet extends RTCBase {
  constructor({ userInfo, ws }) {
    super({ userInfo, ws });
    this.init();
    this.meetSession = null;
  }

  init() {
    this.initMeetNotice();
  }

  initMeetNotice() {
    const meetNotice = useMeetNotice.call(this);
    this.ws.on("*", meetNotice);
  }

  // 呼叫正式会议
  call(
    { meet, waterMarker = { show: false, text: null }, users = [], eid="" },
    event = {}
  ) {
    try {
      if (!meet) {
        console.error("请传入会议");
        return;
      }
      const target = meet.meetnum;
      /* const eidStr = eid; */
      const mediaConstraints = getCallDeviceType(meet.type);
      this.meetSession = new MeetSession(
        {
          meet,
          isCompere: true,
          state: MEET_STATE.AWAIT_MEETIN,
        },
        event,
        this.userInfo
      );
      setStorage(MEET_NUMBER_KEY, target);
      this.publish({ target, mediaConstraints, waterMarker, eid })
        .then(({ pcInfo, negotiatePromise }) => {
          if (pcInfo) {
            pcInfo.onaddstream = ({ stream }) => {
              useAddStream({ pcInfo, stream });
              this.meetSession.fire(BASE_EVENT.ADD_PUBLISH_STREAM, stream);
            };
            this.meetSession.publishPcInfo = pcInfo;
          }
          this.meetSession.addUser(users);
          return negotiatePromise;
        })
        .catch((e) => {
          this.meetSession.fire(BASE_EVENT.PUBLISH_FIRE, e);
          throw e;
        });
      return this.meetSession;
    } catch (error) {
      removeStorage(MEET_NUMBER_KEY);
      console.error(error);
      throw error;
    }
  }

  callTempVideo({ users = [], meet, eid="" }, event = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        meet =
          meet ||
          (await this.api.tempMeet({
            type: MEET_TYPE.MEET_VIDEO,
            user_id: this.userInfo.user.id,
          }));
        const callSession = this.call(
          {
            meet,
            users,
            eid
          },
          event
        );
        resolve(callSession);
      } catch (error) {
        reject(error);
      }
    });
  }

  callTempVoice({ users = [], meet, eid="" }, event = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        meet =
          meet ||
          (await this.api.tempMeet({
            type: MEET_TYPE.MEET_VOICE,
            user_id: this.userInfo.user.id,
          }));
        const callSession = this.call(
          {
            meet,
            users,
            eid
          },
          event
        );
        resolve(callSession);
      } catch (error) {
        reject(error);
      }
    });
  }

  // callTempVideo({ users = [], meet }, event = {}) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       meet =
  //         meet ||
  //         (await this.api.tempMeet({
  //           type: MEET_TYPE.MEET_VIDEO,
  //           user_id: this.userInfo.user.id
  //         }));
  //       const callSession = await this.call(
  //         {
  //           meet,
  //           users
  //         },
  //         event
  //       );
  //       resolve(callSession);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // }

  // callTempVoice({ users = [], meet }, event = {}) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       meet =
  //         meet ||
  //         (await this.api.tempMeet({
  //           type: MEET_TYPE.MEET_VOICE,
  //           user_id: this.userInfo.user.id
  //         }));
  //       const callSession = await this.call(
  //         {
  //           meet,
  //           users
  //         },
  //         event
  //       );
  //       resolve(callSession);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // }

  answer(meet, event) {
    try {
      const target = meet.meetnum;
      const mediaConstraints = getCallDeviceType(meet.type);
      // 在邀请的时候就有了新的session了
      this.meetSession = new MeetSession(
        {
          isCompere: false,
          meet,
        },
        event,
        this.userInfo
      );

      this.publish({
        target,
        mediaConstraints,
      }).then(({ pcInfo, negotiatePromise }) => {
        pcInfo.onaddstream = ({ stream }) => {
          useAddStream({ pcInfo, stream });
          this.meetSession.fire(BASE_EVENT.ADD_PUBLISH_STREAM, stream);
        };
        this.meetSession.publishPcInfo = pcInfo;
        return negotiatePromise;
      });
      setStorage(MEET_NUMBER_KEY, target);
      // Object.assign(this.meetSession, {
      //   event
      // });
      return this.meetSession;
    } catch (error) {
      removeStorage(MEET_NUMBER_KEY);
      console.error(error);
    }
  }

  /*  async answer(meet, event) {
    try {
      const target = meet.meetnum;
      const mediaConstraints = getCallDeviceType(meet.type);
      const publishPcInfo = await this.publish({
        target,
        mediaConstraints
      });
      setStorage(MEET_NUMBER_KEY, target);
      Object.assign(this.meetSession, {
        publishPcInfo,
        event
      });
      return this.meetSession;
    } catch (error) {
      removeStorage(MEET_NUMBER_KEY);
      console.error(error);
    }
  } */

  // 播放
  play({ meet, meetInUser }) {
    return new Promise(async (resolve, reject) => {
      if (meet.type === "meet_video") {
        const { pcInfo: playVideoPcInfo, negotiatePromise: playVideoPromise } =
          await this.player({
            target: meet.meetnum,
            call_type: "meet_video_only",
            stream: meet.stream_url,
          });
        const { pcInfo: playAudioPcInfo, negotiatePromise: playAudioPromise } =
          await this.player({
            target: meet.meetnum,
            call_type: "meet_voice",
            stream: meetInUser.stream_url,
          });

        function getVideoStream() {
          return new Promise((resolve, reject) => {
            playVideoPcInfo.onaddstream = ({ stream }) => {
              useAddStream({ pcInfo: playVideoPcInfo, stream });
              resolve(playVideoPcInfo);
            };
          });
        }

        function getAudioStream() {
          return new Promise((resolve, reject) => {
            playAudioPcInfo.onaddstream = ({ stream }) => {
              useAddStream({ pcInfo: playAudioPcInfo, stream });
              resolve(playAudioPcInfo);
            };
          });
        }

        const playVideo = getVideoStream();
        const playAudio = getAudioStream();

        Promise.all([playVideoPromise, playAudioPromise]).catch(() => {
          // 推流失败
          this.meetSession.fire(BASE_EVENT.PLAYER_FIRE);
        });

        Promise.all([playVideo, playAudio]).then(
          ([videoPcInfo, audioPcInfo]) => {
            videoPcInfo.stream.addTrack(audioPcInfo.stream.getAudioTracks()[0]);
            resolve(videoPcInfo);
          }
        );
      } else {
        const stream = await this.player({
          target: meet.meetnum,
          call_type: meet.type,
          stream: meetInUser.stream_url,
        });
        resolve(stream);
      }
    });
  }
}
