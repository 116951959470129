import { getAction } from './manage';

//发送指令列表
const getSendOrderListOd = (param = {}) => {
  param.cmd = 'getSendOrderList_od';
  return getAction('/dispatch/api', param);
};

//发送指令
const sendOrderOd = (param = {}) => {
  param.cmd = 'sendOrder_od';
  return getAction('/dispatch/api', param);
};

//获取指令调度树结构获取指令调度树结构
const getMoudleTreeOd = (param = {}) => {
  param.cmd = 'getMoudleTree_od';
  return getAction('/dispatch/api', param);
};

//指令内容启用
const moudleContentUsableOd = (param = {}) => {
  param.cmd = 'moudleContentUsable_od';
  return getAction('/dispatch/api', param);
};

//模板内容新增
const insertMoudleContentOd = (param = {}) => {
  param.cmd = 'insertMoudleContent_od';
  return getAction('/dispatch/api', param);
};

//模板内容修改
const updateMoudleContentOd = (param = {}) => {
  param.cmd = 'updateMoudleContent_od';
  return getAction('/dispatch/api', param);
};

//发送指令详情
const getSendOrderDetailOd = (param = {}) => {
  param.cmd = 'getSendOrderDetail_od';
  return getAction('/dispatch/api', param);
};

//接受指令列表
const getReceivedOrderListOd = (param = {}) => {
  param.cmd = 'getReceivedOrderList_od';
  return getAction('/dispatch/api', param);
};

//回复指令
const replyOrderOd = (param = {}) => {
  param.cmd = 'replyOrder_od';
  return getAction('/dispatch/api', param);
};

export default {
  getSendOrderListOd,
  sendOrderOd,
  getMoudleTreeOd,
  moudleContentUsableOd,
  insertMoudleContentOd,
  updateMoudleContentOd,
  getSendOrderDetailOd,
  getReceivedOrderListOd,
  replyOrderOd
};
