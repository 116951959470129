function getCallMediaConstraints({ video, audio }) {
  let type;
  if (video === true && audio === true) {
    type = 'voice_video';
  } else if (video === true && audio === false) {
    type = 'video';
  } else if (video === false && audio === true) {
    type = 'voice';
  }
  return type;
}

function getCallDeviceType(callType) {
  let c;
  switch (callType) {
    case 'voice':
      c = { audio: true, video: false, type: 'p2p' };
      break;
    case 'voice_video':
    case 'sys_video':
      c = { audio: true, video: true, type: 'p2p' };
      break;
    case 'video':
      c = { audio: false, video: true, type: 'p2p' };
      break;
    case 'channel_voice':
      c = { audio: true, video: false, type: 'channel' };
      break;
    case 'channel_video':
      c = { audio: true, video: true, type: 'channel' };
      break;
    case 'meet_voice':
      c = { audio: true, video: false, type: 'meet' };
      break;
    case 'meet_video':
      c = { audio: true, video: true, type: 'meet' };
      break;
    case 'meet_video_only':
      c = { audio: false, video: true, type: 'meet' };
      break;
    case 'radio':
      c = { audio: true, video: false, type: 'radio' };
      break;
    case 'monitor':
      c = { audio: true, video: true, type: 'monitor' };
      break;
  }
  return c;
}

function getTarget(call, userInfo) {
  return call.caller === userInfo.user.phone ? call.callee : call.caller;
}

function getTargetCallItem(call, userInfo, list) {
  const target = getTarget(call, userInfo);
  const callItem = list[target];
  return callItem;
}

export {
  getCallMediaConstraints,
  getCallDeviceType,
  getTarget,
  getTargetCallItem
};
