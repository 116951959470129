/* import handIcon from "../images/hand.png"; */
/* import { createRequire } from 'module';
const require = createRequire(import.meta.url); */

const REMOTE_STOP = "remote_stop";
const REMOTE_HAND = "remote_hand";

class RemoteCanvas {
  constructor({ videoEl }) {
    this.videoEl = videoEl;
    this.canvas = null;
    this.context = null;
    this.timer = 0;
    this.oldStream = {};
    this.handler = { icon: null, position: null };
    this.curAction = REMOTE_STOP;
  }
  init({ stream }) {
    return new Promise((resolve, reject) => {
      this.oldStream = stream;
      let width = getEvenNumber(this.videoEl.clientWidth);
      let height = getEvenNumber(this.videoEl.clientHeight);
      this.canvas = createCanvasEl({ el: this.videoEl, width, height });
      this.context = this.canvas.getContext("2d");
      const draw = () => {
        if (
          getEvenNumber(this.videoEl.clientHeight) !== height &&
          height > 0 &&
          getEvenNumber(this.videoEl.clientWidth) !== width &&
          width > 0
        ) {
          this.canvas.width = width = getEvenNumber(this.videoEl.clientWidth);
          this.canvas.height = height = getEvenNumber(
            this.videoEl.clientHeight
          );
        }
        if (!this.videoEl.paused && !this.videoEl.ended) {
          this.context.drawImage(this.videoEl, 0, 0, width, height);
          this._drawAction();
        }
        this.timer = window.requestAnimationFrame(draw);
      };
      draw();
      let canvasStream = this.canvas.captureStream();
      resolve(canvasStream);
    });
  }
  addAction(action) {
    switch (action) {
      case REMOTE_HAND:
        this._addHand();
        break;
    }
    this.curAction = action;
  }

  //添加手势
  _addHand() {
    const position = {
      x: 0,
      y: 0,
    };
    const hand = getHandIcon();
    this.canvas.onmousemove = function (e) {
      position.x = e.offsetX;
      position.y = e.offsetY;
    };
    this.handler = {
      position,
      icon: hand,
    };
  }

  //当前动作的绘画
  _drawAction() {
    if (this.curAction === REMOTE_STOP) return;
    switch (this.curAction) {
      case REMOTE_HAND:
        const { icon, position } = this.handler;
        this.context.drawImage(icon, position.x, position.y, 16, 16);
        break;
    }
  }

  //停止当前绘画
  stopDrawAction() {
    this.handler = null;
    this.curAction = REMOTE_STOP;
  }

  close() {
    this.stopDrawAction();
    this.canvas.remove();
    cancelAnimationFrame(this.timer);
    this.timer = null;
    this.videoEl.style.position = "relative";
    this.videoEl.style.zIndex = "0";
  }
}

//获取手势图标
function getHandIcon() {
  const hand = new Image();
  const handIcon = new URL("../images/hand.png", import.meta.url).href;
  hand.src = handIcon;
  return hand;
}

function createCanvasEl({ el, width, height }) {
  let canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  el.parentElement.append(canvas);
  el.style.position = "absolute";
  el.style.zIndex = "-1";
  return canvas;
}

function getEvenNumber(number) {
  number = parseInt(number);
  if (number % 2 !== 0) {
    number = number - 1;
  }
  console.log(number);
  return number;
}
export { RemoteCanvas, REMOTE_HAND };
