Element.prototype.dragClick = function (handle, cancle) {
  var bTime = 0,
    endTime = 0,
    offsetX = 0,
    offsetY = 0,
    oPos = [],
    wWidth = window.innerWidth,
    wHeight = window.innerHeight,
    eleWidth = parseInt(window.getComputedStyle(this).width),
    eleHeight = parseInt(window.getComputedStyle(this).height),
    _this = this;

  drag.call(this);
  function drag() {
    const dragEl = this.querySelector(handle) || this;
    dragEl.onmousedown = function (e) {
      if (e.target !== dragEl && !dragEl.contains(e.target)) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      bTime = new Date().getTime();
      offsetX = e.offsetX;
      offsetY = e.offsetY;
      oPos = [e.pageX, e.pageY];
      document.onmousemove = mouseMove.bind(_this);
      document.onmouseup = mouseUp.bind(_this);
      console.log(e);
    };
    function mouseMove(e) {
      var eleLeft = e.pageX - offsetX,
        eleTop = e.pageY - offsetY;
      // if (eleLeft <= 0) {
      //   eleLeft = 0;
      // } else if (eleLeft >= wWidth - eleWidth) {
      //   eleLeft = wWidth - eleWidth - 1;
      // }

      // if (eleTop <= 0) {
      //   eleTop = 0;
      // } else if (eleTop >= wHeight - eleHeight) {
      //   eleTop = wHeight - eleHeight - 1;
      // }
      this.style.left = eleLeft + 'px';
      this.style.top = eleTop + 'px';
    }
    function mouseUp(e) {
      endTime = new Date().getTime();
      if (
        !(e.pageX === oPos[0] && e.pageY === oPos[1]) &&
        endTime - bTime < 100
      ) {
        this.style.left = oPos[0] + 'px';
        this.style.top = oPos[1] + 'px';
      }

      document.onmousemove = null;
      document.onmouseup = null;
    }
  }
};
