import {
  LOGIN_ACCOUNT,
  LOGIN_PASSWORD,
  LOGIN_TOKEN_KEY,
  WS_URL,
} from "../dict";
import { getStorage, setStorage } from "./storage";
import api from "../api";

// 登录
async function interceptCall() {
  async function regestWS({ account, password }) {
    let token =
      getStorage(LOGIN_TOKEN_KEY) || `${new Date().getTime()}_${account}`;
    await window._rtc.ws.regiest({ account, password, token }); //ws登录
    // window._rtc.p2p.initInfo({ userInfo, ws });
    // window._rtc.meet.initInfo({ userInfo, ws });
    // window._rtc.p2p.initP2PNotice({ userInfo, ws });
    // window._rtc.meet.initMeetNotice({ userInfo, ws });
  }

  // 没有登录ws
  if (window._rtc.ws && window._rtc.ws.websock) {
    return;
  }

  let account = getStorage(LOGIN_ACCOUNT);
  let password = getStorage(LOGIN_PASSWORD);
  if (account && password) {
    await regestWS({ account, password });
  } else {
    throw "请先注册RTC";
  }
  /*  else {
    account = window.prompt("请输入通讯登陆账号", "");
    if (account) {
      password = window.prompt("请输入通讯登录密码", "");
    }
    if (account && password) {
      await regestWS({ account, password });
    }
  } */
}

/* proxy拦截 */
/* const get = createGetter();
function createGetter() {
  return function get(target, key, receiver) {
    const value = Reflect.get(target, key, receiver);
    if (INTERCEPT_KEY.includes(key)) {
      interceptCall();
    } else {
      return value;
    }
  };
}

const mutableHandler = {
  get,
};

function createRTCIntercept(target) {
  const observer = new Proxy(target, mutableHandler);
  return observer;
}
 */
export { interceptCall };
