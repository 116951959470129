import { getAction, postAction } from "./manage";
import IMAPI from "./im";
import ORDERAPI from "./order";
import RTCAPI from "./rtc";

//获取当前免登录人的帐号和密码
const getUser = (param) => {
  param.cmd = "getUser";
  return getAction("/dispatch/v", param);
};

// 联动话机呼叫
const sysCall = (param) => {
  param.cmd = "sysCall";
  return getAction("/dispatch/api", param);
};

// 未接来电明细
const missedCalls = (param) => {
  param.cmd = "missedCalls";
  return getAction("/dispatch/api", param);
};

//强插
const sysCallQC = (param) => {
  param.cmd = "sysCallQC";
  return getAction("/dispatch/api", param);
};

const sysNowCall = (param = {}) => {
  param.cmd = "sys_now_call";
  return getAction("/dispatch/api", param);
};

//热线
const hotlinePhone = (param = {}) => {
  param.cmd = "hotlinePhone";
  return getAction("/dispatch/api", param);
};

//添加外线
const meetAddGateway = (param = {}) => {
  param.cmd = "meet_add_gateway";
  return getAction("/dispatch/api", param);
};

//自身未接听时挂断
const sysCallHangup = (param) => {
  param.cmd = "sysCallHangup";
  return getAction("/dispatch/api", param);
};

//查看他人视频
const sysCallVideo = (param) => {
  param.cmd = "sysCallVideo";
  return getAction("/dispatch/api", param);
};

//监听接口
const sysCallMonitor = (param) => {
  param.cmd = "sysCallMonitor";
  return getAction("/dispatch/api", param);
};

//待接接口
const sysCallReplaceAnswer = (param) => {
  param.cmd = "sysCallReplaceAnswer";
  return getAction("/dispatch/api", param);
};

//组织架构
const getOrgStructure = (param) => {
  param.cmd = "getOrgStructure";
  return getAction("/dispatch/api", param);
};

//常用联系人
const getContacts = (param) => {
  param.cmd = "getContacts";
  return getAction("/dispatch/api", param);
};

//会议列表
const meetList = (param) => {
  param.cmd = "meetList";
  return getAction("/dispatch/api", param);
};

//临时语音会议、临时视频会议
const tempMeet = (param) => {
  param.cmd = "tempMeet";
  return getAction("/dispatch/api", param);
};

//语音会议成员、视频会议成员、广播成员
const getMeetMembers = (param) => {
  param.cmd = "getMeetMembers";
  return getAction("/dispatch/api", param);
};

//会议详情
const meetDetail = (param) => {
  param.cmd = "meetDetail";
  return getAction("/dispatch/api", param);
};

//会议、广播明细导出
const exportMeetDetail = (param) => {
  param.cmd = "exportMeetDetail";
  return getAction("/dispatch/api", param);
};
//会议添加人员
const meetAdd = (param) => {
  param.cmd = "meet_add";
  return getAction("/dispatch/api", param);
};
//会议剔除成员
const meetKick = (param) => {
  param.cmd = "meet_kick";
  return getAction("/dispatch/api", param);
};

//会议关闭
const meetOff = (param) => {
  param.cmd = "meet_off";
  return getAction("/dispatch/api", param);
};

//会议成员退出
const meetOut = (param) => {
  param.cmd = "meet_out";
  return getAction("/dispatch/api", param);
};

//视频会议画布布局
const meetLayout = (param) => {
  param.cmd = "meet_layout";
  return getAction("/dispatch/api", param);
};

//视频会议设置主屏
const meetFloor = (param) => {
  param.cmd = "meet_floor";
  return getAction("/dispatch/api", param);
};

//会议成员静音
const meetMute = (param) => {
  param.cmd = "meet_mute";
  return getAction("/dispatch/api", param);
};

//会议成员解除静音
const meetUnmute = (param) => {
  param.cmd = "meet_unmute";
  return getAction("/dispatch/api", param);
};

//会议成员置聋
const meetDeaf = (param) => {
  param.cmd = "meet_deaf";
  return getAction("/dispatch/api", param);
};

//会议成员解除置聋
const meetUndeaf = (param) => {
  param.cmd = "meet_undeaf";
  return getAction("/dispatch/api", param);
};

//获取对讲组列表
const getChannelList = (param) => {
  param.cmd = "getChannelList";
  return getAction("/dispatch/api", param);
};

//获取对讲组成员列表
const getChannelUser = (param) => {
  param.cmd = "getChannelUser";
  return getAction("/dispatch/api", param);
};

const channelIn = (param) => {
  param.cmd = "channel_in";
  return getAction("/dispatch/api", param);
};

const channelGet = (param) => {
  param.cmd = "channel_get";
  return getAction("/dispatch/api", param);
};

const channelRelease = (param) => {
  param.cmd = "channel_release";
  return getAction("/dispatch/api", param);
};

const channelOut = (param) => {
  param.cmd = "channel_out";
  return getAction("/dispatch/api", param);
};

const getTempChannel = (param) => {
  param.cmd = "getTempChannel";
  return getAction("/dispatch/api", param);
};

const channelDetail = (param) => {
  param.cmd = "channelDetail";
  return getAction("/dispatch/api", param);
};

const channelAdd = (param) => {
  param.cmd = "channel_add";
  return getAction("/dispatch/api", param);
};

const exportChannelDetail = (param) => {
  param.cmd = "exportChannelDetail";
  return getAction("/dispatch/api", param);
};

//坐席视频
const todayCall = (param) => {
  param.cmd = "todayCall";
  return getAction("/dispatch/api", param);
};

const monitorLiveAddress = (param = {}) => {
  param.cmd = "monitorLiveAddress";
  return getAction("/dispatch/api", param);
};

//获取GB28181监控的组织架构及其用户
const orgMonitor = (param = {}) => {
  param.cmd = "orgMonitor";
  return getAction("/dispatch/api", param);
};

//资源调度
//根据用户的信息查询（不包含调度台账号的专有信息）
const usersearch = (param = {}) => {
  param.cmd = "usersearch";
  return getAction("/dispatch/api", param);
};

//用户轨迹查询
const guiji = (param = {}) => {
  param.cmd = "guiji";
  return getAction("/dispatch/api", param);
};

//通话记录明细
const callDetail = (param = {}) => {
  param.cmd = "callDetail";
  return getAction("/dispatch/api", param);
};

//获取电子围栏列表
const getFenceList = (param = {}) => {
  param.cmd = "getFenceList";
  return getAction("/dispatch/api", param);
};

//通话记录明细
const exportCallDetail = (param = {}) => {
  param.cmd = "exportCallDetail";
  return getAction("/dispatch/api", param);
};

//获取物资管理系统物资类型信息
const getVimTypes = (param = {}) => {
  param.cmd = "getVimTypes";
  return getAction("/dispatch/api", param);
};

//根据物资类型id获取物资信息
const getVimPreviewInfo = (param = {}) => {
  param.cmd = "getVimPreviewInfo";
  return getAction("/dispatch/api", param);
};

//获取无人值守信息
const getUnattendedInfo = (param = {}) => {
  param.cmd = "getUnattendedInfo";
  return getAction("/dispatch/api", param);
};

//设置无人值守状态
const updateUnattended = (param = {}) => {
  param.cmd = "updateUnattended";
  return getAction("/dispatch/api", param);
};

//获取联动话机组信息
const getUserGroupInfo = (param = {}) => {
  param.cmd = "getUserGroupInfo";
  return getAction("/dispatch/api", param);
};

//设置联动话机组状态
const updateUserGroup = (param = {}) => {
  param.cmd = "updateUserGroup";
  return getAction("/dispatch/api", param);
};

//上传文件
const uploadByFileType = (param = {}) => {
  param.append("cmd", "uploadByFileType");
  return postAction("/dispatch/api", param);
};

//短信发送
const sendSms = (param = {}) => {
  param.cmd = "sendSms";
  return getAction("/dispatch/api", param);
};

//sms短信发送列表
const smsList = (param = {}) => {
  param.cmd = "sms_list";
  return getAction("/dispatch/api", param);
};

//sms用户-手机号-新增
const usmInsert = (param = {}) => {
  param.cmd = "usm_insert";
  return getAction("/dispatch/api", param);
};

//sms用户-手机号-删除
const usmDelete = (param = {}) => {
  param.cmd = "usm_delete";
  return getAction("/dispatch/api", param);
};

//sms用户-手机号-列表
const usmList = (param = {}) => {
  param.cmd = "usm_list";
  return getAction("/dispatch/api", param);
};

//获取系统版权信息
const copyright = (param = {}) => {
  param.cmd = "copyright";
  return getAction("/dispatch/api", param);
};

//播放音乐
const playmusic = (param = {}) => {
  param.cmd = "playmusic";
  return getAction("/dispatch/api", param);
};

//停止音乐
const breakmusic = (param = {}) => {
  param.cmd = "breakmusic";
  return getAction("/dispatch/api", param);
};

//获取第三方设备
const sysCacheThirdParty = (param = {}) => {
  param.cmd = "sysCacheThirdParty";
  return getAction("/dispatch/api", param);
};

//对监控监控预置位查询
const presetQuery = (param = {}) => {
  param.cmd = "presetQuery";
  return getAction("/dispatch/api", param);
};

//监控云台控制
const deviceControl = (param = {}) => {
  param.cmd = "deviceControl";
  return getAction("/dispatch/api", param);
};

//获取第三方组织架构
const thirdPartyTree = (param = {}) => {
  param.cmd = "thirdPartyTree";
  return getAction("/dispatch/api", param);
};

//根据条件获取人员
const getLimitUsers = (param = {}) => {
  param.cmd = "getLimitUsers";
  return getAction("/dispatch/api", param);
};

//用户轨迹分段查询
const guijiSubsection = (param = {}) => {
  param.cmd = "guiji_subsection";
  return getAction("/dispatch/api", param);
};

//监控历史回放信息
const playback = (param = {}) => {
  param.cmd = "playback";
  return getAction("/dispatch/api", param);
};

//视频源呼叫
const mediaSourcesCall = (param = {}) => {
  param.cmd = "mediaSourcesCall";
  return getAction("/dispatch/api", param);
};

//视频源应答
const sysCallAnswer = (param = {}) => {
  param.cmd = "sysCallAnswer";
  return getAction("/dispatch/api", param);
};

const getXwInfoList = (param = {}) => {
  param.cmd = "getXwInfoList";
  return getAction("/dispatch/api", param);
};

const getUserOnlineList = (param = {}) => {
  param.cmd = "getUserOnlineList";
  return getAction("/dispatch/api", param);
};

const userlasso = (param = {}) => {
  param.cmd = "userlasso";
  return getAction("/dispatch/api", param);
};

const videoListRecord = (param = {}) => {
  param.cmd = "videoList_record";
  return getAction("/dispatch/api", param);
};

const filmListRecord = (param = {}) => {
  param.cmd = "filmList_record";
  return getAction("/dispatch/api", param);
};

//会议分享
const meetShare = (param) => {
  param.cmd = "meet_share";
  return getAction("/dispatch/api", param);
};

//会议轮询
const meetPolling = (param) => {
  param.cmd = "meet_polling";
  return getAction("/dispatch/api", param);
};

// 上墙
const decoderPlay = (param) => {
  param.cmd = "decoder_play";
  return getAction("/dispatch/api", param);
};

// 停止上墙
const decoderStopWeb = (param) => {
  param.cmd = "decoder_stop_web";
  return getAction("/dispatch/api", param);
};

// 会议自定义布局
const meetLayoutCustom = (param = {}) => {
  return postAction("/dispatch/api?cmd=meet_layout_custom", param);
};

// 根据用户id获取可用的解码器
const selectAllDecoderByUserId = (param = {}) => {
  param.cmd = "selectAllDecoderByUserId";
  return getAction("/dispatch/api?cmd=selectAllDecoderByUserId", param);
};

// 呼叫手机播放音乐
const callSipPlayMusicSendSms = (param = {}) => {
  param.cmd = "callSipPlayMusicSendSms";
  return getAction("/dispatch/api", param);
};

const appletMeet = (param = {}) => {
  param.cmd = "applet_meet";
  return getAction("/dispatch/api", param);
};

export default {
  callSipPlayMusicSendSms,
  appletMeet,
  getUser,
  sysCall,
  missedCalls,
  sysCallQC,
  sysNowCall,
  monitorLiveAddress,
  meetAdd,
  sysCallHangup,
  sysCallVideo,
  sysCallMonitor,
  sysCallReplaceAnswer,
  getOrgStructure,
  getContacts,
  meetList,
  tempMeet,
  getMeetMembers,
  exportMeetDetail,
  meetKick,
  meetOff,
  meetOut,
  meetDetail,
  meetLayout,
  meetFloor,
  meetMute,
  meetUnmute,
  meetDeaf,
  meetUndeaf,
  getChannelList,
  getChannelUser,
  channelIn,
  channelGet,
  channelRelease,
  channelOut,
  getTempChannel,
  channelDetail,
  channelAdd,
  exportChannelDetail,
  orgMonitor,
  usersearch,
  guiji,
  todayCall,
  callDetail,
  getFenceList,
  exportCallDetail,
  uploadByFileType,
  getVimTypes,
  getVimPreviewInfo,
  hotlinePhone,
  meetAddGateway,
  getUnattendedInfo,
  updateUnattended,
  getUserGroupInfo,
  updateUserGroup,
  sendSms,
  smsList,
  usmInsert,
  usmDelete,
  usmList,
  copyright,
  playmusic,
  breakmusic,
  sysCacheThirdParty,
  presetQuery,
  deviceControl,
  thirdPartyTree,
  getLimitUsers,
  guijiSubsection,
  playback,
  mediaSourcesCall,
  sysCallAnswer,
  getXwInfoList,
  getUserOnlineList,
  userlasso,
  videoListRecord,
  filmListRecord,
  meetShare,
  meetPolling,
  decoderPlay,
  decoderStopWeb,
  meetLayoutCustom,
  selectAllDecoderByUserId,
  ...IMAPI,
  ...ORDERAPI,
  ...RTCAPI,
};
