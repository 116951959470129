import api from "../../api/index";
import { startPublish, startPlay } from "../../webrtc/index";
import { uuid, getCallMediaConstraints } from "../../util/index";
import { getConfig } from "../config/index";
import { interceptCall } from "../../util/intercept";

class RTCBase {
  constructor({ userInfo, ws }) {
    this.userInfo = userInfo;
    this.groupInfo = {};
    this.ws = ws;
    this.api = api;
    this.config = getConfig();
  }

  isMine(phone) {
    this.userInfo.user.phone === phone;
  }

  //推流
  publish({
    target,
    mediaConstraints = { audio: true, video: true, type: "p2p" },
    waterMarker = { show: false, text: null }, //水印
    stream,
    eid
  }) {
    /* u:uuid
        f:自己
        t:目标
        s:签名
        */
    if (!target) {
      throw "必填参数target不能为空";
    }
    return new Promise(async (resolve, reject) => {
      console.log(eid,'===eid')
      await interceptCall();
      const newUrl = generatePublishUrl({
        userInfo: this.userInfo,
        target,
        stream,
        eid
      });
      let obj = {
        url: newUrl,
        mediaConstraints: {
          audio: mediaConstraints.audio,
          video: mediaConstraints.video,
        },
        waterMarker,
      };
      if (this.userInfo.config.media_sources) {
        const call_type = getCallMediaConstraints(mediaConstraints);
        await this.api.mediaSourcesCall({
          caller: this.userInfo.user.phone,
          callee: target,
          call_type,
        });
        resolve({});
      } else {
        resolve(startPublish(obj));
      }
    });
  }

  //播流
  player({ target, call_type, stream }) {
    if (!target) {
      console.error("必填参数target不能为空");
      return Promise.reject();
    }
    const newUrl = generatePlayerUrl({
      userInfo: this.userInfo,
      target,
      stream,
    });
    let obj = {
      url: newUrl,
      call_type,
    };
    return startPlay(obj);
  }
}

function generatePublishUrl({ userInfo, target, stream, eid="" }) {
  let u = new Date().getTime() + "_" + userInfo.user.phone;
  let f = userInfo.user.phone;
  let s = userInfo.user.id;
  let t = target;
  let eidstr = eid;
  stream = stream || uuid();
  //真正的推流地址
  const newUrl = `/rtc/webrtc?stream=${stream}&u=${u}&f=${f}&t=${t}&s=${s}&eid=${eidstr}&type=push&app=live`;
  return newUrl;
}

function generatePlayerUrl({ userInfo, target, stream }) {
  let u = new Date().getTime() + "_" + userInfo.user.phone;
  let f = userInfo.user.phone;
  let s = userInfo.user.id;
  let t = target;
  stream = stream || uuid();
  //真正的推流地址
  const newUrl = `/rtc/webrtc?u=${u}&f=${f}&t=${t}&s=${s}&app=live&type=play&stream=${stream}`;
  return newUrl;
}
export default RTCBase;
