import { CONNECT_STATE } from "../../dict";
import {
  MINE_P2P_RING,
  MINE_P2P_ONCALL,
  MINE_P2P_AVAILABLE,
  TARGET_P2P_RING,
  TARGET_P2P_ONCALL,
  TARGET_P2P_AVAILABLE,
  TARGET_P2P_ANSWER,
  CALLEE_OFFLINE,
  CALLEE_BUSY,
  CALLEE_NOT_SYS_USER,
  TARGER_NOT_CALL,
} from "../../websocket/message/p2p";
import CallSession from "./CallSession";
import P2P_EVENT from "./event";
import { useAddStream } from "../../webrtc/stream";
import BASE_EVENT from "../base/event";

export function useP2PNotice() {
  const _this = this;

  function deleteCallItem(call) {
    const target = _this.getTarget(call, _this.userInfo);
    const callItem = _this.getTargetCallItem(
      call,
      _this.userInfo,
      _this.callList
    );
    callItem && callItem.stopPublish && callItem.stopPublish();
    delete _this.callList[target];
  }

  return {
    [MINE_P2P_RING]: ({ call }) => {
      const target = _this.getTarget(call, _this.userInfo);
      const callItem = new CallSession({ call, userInfo: _this.userInfo });
      _this.callList[target] = callItem;
    },
    [MINE_P2P_ONCALL]: ({ call }) => {
      const callItem = _this.getTargetCallItem(
        call,
        _this.userInfo,
        _this.callList
      );
      if (callItem.isMineCaller) {
        Object.assign(callItem, { call });
      } else {
        Object.assign(callItem, { call, connectState: CONNECT_STATE.CONNECT });
      }
    },
    [TARGET_P2P_ANSWER]: async ({ user, call }) => {
      const callItem = _this.getTargetCallItem(
        call,
        _this.userInfo,
        _this.callList
      );
      if (callItem) {
        // 对方应答  拉流
        /* 0825 sy添加点对点手机呼叫播放两路流start */
        let calleePhone = callItem.call.callee;
        let first_phone = calleePhone.slice(0, 1);

        if(first_phone=="0") {
            calleePhone = calleePhone.slice(1);
        }
        /* sy添加点对点手机呼叫播放两路流end */

        _this.playerProcess({ call }, callItem).then(() => {
          Object.assign(callItem, {
            call,
            connectState: CONNECT_STATE.CONNECT,
          });
          callItem.fire(P2P_EVENT.ANSWER, callItem);
        });

        /* 0825 sy点对点手机呼叫判断手机号并再次拉流start */
        if((calleePhone.length===11 && callItem.call.call_type == "voice_video")) {
          _this.playerProcess({ call }, callItem).then(() => {
            Object.assign(callItem, {
              call,
              connectState: CONNECT_STATE.CONNECT
            });
            callItem.fire(P2P_EVENT.ANSWER, callItem);
          });
        };
        /* sy点对点手机呼叫判断手机号并再次拉流end */
      } else {
        playerPcInfo.close();
        throw new Error(`${TARGET_P2P_ANSWER}:not find answer user`);
      }
    },
    [MINE_P2P_AVAILABLE]: ({ call }) => {
      const callItem = _this.getTargetCallItem(
        call,
        _this.userInfo,
        _this.callList
      );
      callItem && callItem.fire(P2P_EVENT.HANGUP, callItem);
      deleteCallItem(call);
    },
    [TARGET_P2P_AVAILABLE]: ({ call }) => {
      const callItem = _this.getTargetCallItem(
        call,
        _this.userInfo,
        _this.callList
      );
      callItem && callItem.fire(P2P_EVENT.HANGUP, callItem);
      deleteCallItem(call);
    },
    [CALLEE_OFFLINE]: ({ callee }) => {
      const callItem = _this.callList[callee];
      callItem.fire(P2P_EVENT.CALLEE_OFFLINE);
      callItem && callItem.stopPublish && callItem.stopPublish();
      delete _this.callList[callee];
    },
    [CALLEE_BUSY]: ({ callee }) => {
      const callItem = _this.callList[callee];
      callItem.fire(P2P_EVENT.CALLEE_BUSY);
      callItem && callItem.stopPublish && callItem.stopPublish();
      delete _this.callList[callee];
    },
    [CALLEE_NOT_SYS_USER]: ({ callee }) => {
      const callItem = _this.callList[callee];
      callItem.fire(P2P_EVENT.CALLEE_NOT_SYS_USER);
      callItem && callItem.stopPublish && callItem.stopPublish();
      delete _this.callList[callee];
    },
    [TARGER_NOT_CALL]: ({ callee }) => {
      const callItem = _this.callList[callee];
      callItem.fire(P2P_EVENT.TARGER_NOT_CALL);
      callItem && callItem.stopPublish && callItem.stopPublish();
      delete _this.callList[callee];
    },
  };
}
