import { CONNECT_STATE } from '../../dict';

export default class P2PPopSession {
  constructor() {}

  init({ session, render } = {}) {
    this.session = session || this.session;
    this.render = render || this.render;
    // 连接成功时的处理
    this.watchConnectState();
  }

  watchConnectState() {
    const _this = this;
    let connectState = this.session['connectState'];
    Object.defineProperty(this.session, 'connectState', {
      get: function () {
        return connectState;
      },
      set(newVal) {
        console.log('进入', newVal);

        if (newVal && connectState !== newVal) {
          connectState = newVal;
        }
        if (newVal === CONNECT_STATE.CONNECT) {
          _this.render.openP2P(_this.session);
          _this.render.addStream(_this.session.playerPcInfo.stream);
        }
      }
    });
  }
}
