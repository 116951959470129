export const MEET_STATE = {
  AWAIT_MEETIN: 'await_meetin',
  AVAILABLE: 'available',
  MEETIN: 'meetIn'
};

export const MEET_USER_STATE = {
  IN: 'in',
  OUT: 'out'
};

export const MEET_TYPE = {
  MEET_VIDEO: 'meet_video',
  MEET_VOICE: 'meet_voice',
  RADIO: 'radio'
};

export const IS_MEET_COMPERE = {
  YES: 0,
  NO: 1
};

export const MEET_LAYOUUT = {
  ONE_AND_ONE: '1x1',
  TWO_AND_TWO: '2x2',
  ONE_AND_FIVE: '1+5',
  THREE_AND_THREE: '3x3',
  FOUR_AND_FOUR: '4x4'
};

export const MEET_TYPE_TEXT_MAP = {
  meet_video: '视频会议',
  meet_voice: '语音会议'
};
