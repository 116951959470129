
import { SENDMSG_IM, MEMBERADD_IM, DISMISSGROUP_IM, MEMBEROUT_im } from "../../websocket/message/im";

export function useImNotice() {
    const _this = this;

    return {
        [SENDMSG_IM]:(data)=>{
            _this.render.sendMsgProcess(data);
        },

        [MEMBERADD_IM]:(data)=>{
            _this.render.memberAddProcess(data);
        },

        [DISMISSGROUP_IM]:(data)=>{
            _this.render.imGourpOut(data);
        },

        [MEMBEROUT_im]:(data)=>{
            _this.render.memberAddProcess(data);
        }
    }
}