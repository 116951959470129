// export const CALL_STATE = {
//   RING: 'ring',
//   AVAILABLE: 'available',
//   CALLIN: 'callin'
// };

export const CONNECT_STATE = {
  READY: 'ready',
  CONNECT: 'connect',
  END: 'end'
};

export const CALL_TYPE = {
  VOICE: 'voice',
  VIDEO: 'video',
  VOICE_VIDEO: 'voice_video'
};
