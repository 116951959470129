
import { imTpl, imRecordListItemTpl, chatContentTpl, maskTpl, userListItemTpl, checkedListTpl, chatBoxFooterTpl,
    msgSysItemTpl, msgItemTpl, textMsgTpl, picMsgTpl, rightUserItemTpl, groupNameAndGongGaoTpl, emjoyTpl, fileMsgTpl, videoMsgTpl, videoTpl, micOpeartTpl, audioMsgTpl,
    videoPreviewTpl, picPreviewTpl
} from "./tpl";
import { createTpl, tplReplace } from "../../util";
import { IS_GROUP, CONTENT_TYPE } from "../../dict/enum/im";
import groupDefaultIcon from "../../images/bar-message.png";
import defaultIcon from "../../images/default.png";
import emjoyIcon from "../../images/emjoy.png";
import micIcon from "../../images/mic.png";
import wenjianIcon from "../../images/wenjian.png";
import addIcon from "../../images/add.png";
import reduceIcon from "../../images/reduce.png";
import groupIcon from "../../images/IDIcon.png";
import ggaoIcon from "../../images/ggao.png";
import { formatTime } from "../../util/index";
import textIcon from "../../images/text.png";
import imEvent from "./event";
import { EMOJI_LIST, ChatMsg } from "./chat";
import { startRecord,stopRecord,stopStream } from "./record";

export default class Render {
    constructor({ mountedId, userInfo, inviteesList, chatMsgList, imApi }) {
        this.mountedId = mountedId;
        this.userInfo = userInfo || null;
        this.imApi = imApi;
        this.inviteesList = inviteesList;
        this.chatMsgList = chatMsgList;
        this.mountedEl = null;
        this.imEl = null;
        this.imMainEl = null;
        this.imLeftEl = null;
        this.imContainerEl = null;
        this.imRecordListEl = null;
        this.chatBoxEl = null;
        this.chatContentEl = null;
        this.chatTopEl = null;
        this.chatBottomEl = null;
        this.addUserBtnEl = null;
        this.maskEl = null;
        this.maskLeftEl = null;
        this.maskRightEl = null;
        this.leftUserListEl = null;
        this.rightUserListEl = null;
        this.userListMap = {};
        this.userCheckedListMap = {};
        this.imRecordItemMap = {};
        this.draftObj = {};
        this.isSelectChatEl = null;
        this.isSelectChatItem = null;
        this.checkUsers = [];
        this.ImEventApi = null;
        this.inputEl = null;
        this.uploadEl = null;
        this.messageStatus = {
            finish: false
        };
        this.chatMsgListObj = {};
        this.msgListEl = null;
        this.createGroupInfo = null;
        this.rightEl = null;
        this.rightListEl = null;
        this.rightInfoEl = null;
        this.rightUserItemMap = {};
        this.infoFormEl = null;
        this.userdetialListEl = null;
        this.isAddMember = false;
        this.isGroupUser = null;
        this.file = null;
        this.url = null;
        this.emjoyEl = null;
        this.emjoyListEl = null;
        this.sendBtnEl = null;
        this.isrecord = null;
        this.group = null;
        this.headerBtnEl = null;
        this.videoPreviewEl = null;
        this.audioInputEl = null;
        this.micBtnEl = null;
        this.micBtnIcon = null;
        this.chatInputEl = null;
        this.micState = null;
        this.timer = null;
        this.blob = null;
        this.stream = null;
        this.time = 0;
        this.micAudioEl = null;
        this.currentAudio = null;
        this.p_bg = null;
        this.emjoyBtnEl = null;
        this.backEl = null;
        this.mainView = null;
        this.close = null;
        this.rightHeaderCountEl = null;
        this.selectNode = null;
        this.currentType = null;
    }

    openIM() {
        this.renderIM();
        this.renderMask();
        this.renderPreview();
        if (this.mountedId) {
            this.mountedEl = document.getElementById(this.mountedId);
            this.mountedEl.className += " rtc-im";
            this.mountedEl.append(this.imEl);
            this.mountedEl.append(this.maskEl);
            this.mountedEl.append(this.videoPreviewEl);
        }
        this.ImEventApi = new imEvent(this.imApi);
    }

    renderMask() {
        this.maskEl = Array.from(createTpl(maskTpl.trim()))[0];
        this.maskLeftEl = this.maskEl.querySelector(".model-left");
        this.maskRightEl = this.maskEl.querySelector(".model-right");
        this.leftUserListEl = this.maskLeftEl.querySelector(".modal-left-content");
        this.rightUserListEl = this.maskRightEl.querySelector(".modal-right-content");
        this.rightHeaderCountEl = this.maskRightEl.querySelector(".number");

        const okBtnEl = this.maskEl.querySelector(".submit-btn");
        const cancleBtnEl = this.maskEl.querySelector(".cancle-btn");
        this.renderUserList();

        okBtnEl.addEventListener("click", async (e) => {
            if(this.checkUsers.length > 0) {
                let idsArr = this.checkUsers.map((item)=>{
                    return item.id;
                })

                if(!this.isAddMember) {
                    if(idsArr.length===1) {
                        let data = {
                            sender: this.userInfo.user.id,
                            receiver: idsArr.toString(),
                            is_group: "0",
                            newMsgCount: 0,
                            content: "",
                            content_type: "1",
                            sender_id: this.userInfo.user.id,
                            receiver_id: idsArr.toString(),
                            receiver_name: this.checkUsers[0].name,
                            sender_name: this.userInfo.user.name,
                            chatMsgList: [],
                            createtime: new Date().getTime().toString()
                        }
                        this.sendMsgProcess(data);
                        /* this.sendMsgProcess(data)
                        this.getChatList(data) */
                    } else {
                        this.createGroupInfo = await this.ImEventApi.createGroupIM({
                            creater_id: this.userInfo.user.id,
                            userIds: idsArr.toString()
                        })
                    }
                } else {
                    let userIds = this.isGroupUser.groupUserList.map((item)=>{
                        return item.id;
                    })

                    idsArr = idsArr.filter((idsArr) => !userIds.some((userIds) => idsArr === userIds));
                    console.log(idsArr,'===idsArr')
                    this.createGroupInfo = await this.ImEventApi.addGroupIM({
                        user_id: this.userInfo.user.id,
                        group_id: this.isGroupUser.group_id,
                        userIds: idsArr.toString()
                    })
                }
                this.removeChecked();
                this.maskEl.style.display = "none";
                this.checkUsers = [];
            }
        })
        cancleBtnEl.addEventListener("click", (e) => {
            this.removeChecked();
            this.maskEl.style.display = "none";
            this.checkUsers = [];
        })
        return this.maskEl;
    }

    renderPreview() {
        this.videoPreviewEl = Array.from(createTpl(videoTpl.trim()))[0];
        this.mainView = this.videoPreviewEl.querySelector(".main");
        this.close = this.videoPreviewEl.querySelector(".close");
        this.close.addEventListener("click",(e)=>{
            this.mainView.innerHTML = "";
            this.videoPreviewEl.style.display = "none";
        })
        this.videoPreviewEl.style.display = "none";
        return this.videoPreviewEl;
    }

    removeChecked() {
        if(this.checkUsers && this.checkUsers.length > 0) {
            this.checkUsers.forEach((user)=>{
                this.leftUserListEl.append(this.addUser(user));
                this.removeRightItem(user);
            })
        }
    }

    /* 渲染IM */
    renderIM() {
        this.imEl = Array.from(createTpl(imTpl.trim()))[0];
        this.imMainEl = this.imEl;
        this.renderMain(this.imMainEl);
        this.imEl.querySelector(".headerName").innerText = "";
        return this.imEl;
    }

    renderMain(el) {
        this.addUserBtnEl = el.querySelector(".addIM-icon");
        this.addListenClick(this.addUserBtnEl);

        this.chatBoxEl = this.imEl.querySelector(".chatBox");

        this.imLeftEl = el.querySelector(".im-record");
        this.imRecordListEl = this.imLeftEl.querySelector(".record-list");
        this.headerBtnEl = this.chatBoxEl.querySelector(".header-more");
        let closreEl = this.chatBoxEl.querySelector(".close");
        
        closreEl.addEventListener("click",(e)=>{
            document.getElementById("imId").style.display = "none";
        })

        this.renderLeftContainer();

        /* 中间 */
        this.chatContentEl = this.chatBoxEl.querySelector(".im-main-content");
        this.chatTopEl = createTpl(chatContentTpl.trim())[0];
        this.chatBottomEl = createTpl(
            tplReplace(chatBoxFooterTpl.trim(), {
                emjoy: emjoyIcon,
                wenjian: wenjianIcon,
                mic: micIcon
            })
        )[0];
        this.chatContentEl.append(this.chatTopEl);
        this.chatContentEl.append(this.chatBottomEl);
        this.emjoyEl = this.chatBottomEl.querySelector(".emjoy-box");
        this.emjoyListEl = this.emjoyEl.querySelector(".list-inner");
        this.renderEmjoy();
        
        this.chatInputEl = this.chatBottomEl.querySelector(".content-input");
        this.inputEl = this.chatBottomEl.querySelector(".im-input");
        this.uploadEl = this.chatBottomEl.querySelector(".wenjian");
        this.emjoyBtnEl = this.chatBottomEl.querySelector(".emjoy");
        this.backEl = this.chatBottomEl.querySelector(".back");
        this.backEl.className = "btn back hide";
        this.sendBtnEl = this.chatBottomEl.querySelector(".send-btn");

        this.audioInputEl = createTpl(micOpeartTpl.trim())[0];
        this.micBtnIcon = this.audioInputEl.querySelector(".btns-item");
        this.micAudioEl = this.audioInputEl.querySelector(".audioBot");
        let playbtn = this.audioInputEl.querySelector(".play");
        let pausebtn = this.audioInputEl.querySelector(".pause");
        let clearbtn = this.audioInputEl.querySelector(".clear");
        this.chatInputEl.append(this.audioInputEl);

        this.micBtnEl = this.chatBottomEl.querySelector(".mic");
        this.audioInputEl.className = "input-container hide";
        
        this.micBtnIcon.addEventListener("mousedown",(e)=>{
            this.micState = "ready";
            this.micBtnIcon.setAttribute("state", "ready");
            this.timer = window.setInterval(()=>{
                this.time++;
            },1000)

            window.navigator.mediaDevices.getUserMedia({video:false,audio:true}).then((stream)=>{
                if(this.micState !== 'ready'){
                    stopStream(stream);
                    return;
                }
                this.micState = 'get';
                this.micBtnIcon.setAttribute("state", "get");
                startRecord({
                    mimeType:'audio/webm;',
                    blobType:"audio/mp3",
                    stream
                })
                this.stream = stream;
            })
        })

        this.micBtnIcon.addEventListener("mouseup", (e) => {
            window.clearInterval(this.timer);
            this.timer = null;
            this.micState = "release";
            this.time = 0;
            this.micBtnIcon.setAttribute("state", "release");
            if(this.stream) {
                this.blob = stopRecord({isDownload:false});
                stopStream(this.stream);
                playbtn.className = "item orange-bg play";
                clearbtn.className = "item clearShow clear";
                this.micAudioEl.setAttribute("src",window.URL.createObjectURL(this.blob));
                console.log(window.URL.createObjectURL(this.blob),"====window.URL.createObjectURL(this.blob)")
            } else {
                console.log("时间太短了!")
            }
        });

        playbtn.addEventListener("click",(e)=>{
            if(this.blob) {
                pausebtn.className = "item orange-bg pause";
                playbtn.className = "item hide play";
                this.micAudioEl.play();
            }
        })

        pausebtn.addEventListener("click",(e)=>{
            pausebtn.className = "item hide pause";
            playbtn.className = "item orange-bg play";
            this.micAudioEl.pause();
        })

        clearbtn.addEventListener("click",(e)=>{
            if(this.blob) {
                pausebtn.className = "item hide pause";
                playbtn.className = "item grey play";
                clearbtn.className = "item grey clear";
            }
            this.micAudioEl.pause();
            this.micAudioEl.setAttribute("src",null);
            this.blob = null;
            this.stream = null;
        })

        this.micBtnEl.addEventListener("click",(e)=>{
            this.backEl.className = "btn back";
            this.uploadEl.className = "btn wenjian hide";
            this.emjoyBtnEl.className = "btn emjoy hide";
            this.micBtnEl.className = "btn mic hide";
            this.inputEl.className = "im-input hide";
            this.audioInputEl.className = "input-container show";
        })

        this.backEl.addEventListener("click",(e)=>{
            pausebtn.className = "item hide pause";
            playbtn.className = "item grey play";
            clearbtn.className = "item grey clear";
            this.clearVoice()
        })
        
        this.renderInputEvent(this.inputEl);
        this.renderUploadEvent(this.uploadEl);
        
        this.emjoyBtnEl.addEventListener("click",(e)=>{
            this.emjoyEl.className = "emjoy-box show"
        })

        this.sendBtnEl.addEventListener("click",(e)=>{
            if(this.blob) {
                let file = new File(
                    [this.blob],
                    `${new Date().getTime()}_${this.userInfo.user.name}.mp3`,
                    { type: "audio/mp3" }
                  );
                this.uploadFile(file, "4");
                pausebtn.className = "item hide pause";
                playbtn.className = "item grey play";
                clearbtn.className = "item grey clear";
                this.clearVoice();
                console.log(file,'===file')
            } else if(this.inputEl.innerHTML) {
                var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
                    let content = this.inputEl.innerHTML
                        .replace(/<img .+?text=\"(.+?)\".+?>/g, "$1")
                        .replace(/<.+?>/g, "")
                        .replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
                            return arrEntities[t];
                        });
                    if (!content.trim()) return;
                    console.log(content,'===content')
                this.sendMsg({ content_type: "1", content });
            }
            this.inputEl.innerHTML = "";
        })

        /* right */
        this.rightEl = this.imEl.querySelector(".moreInfo");
        console.log(this.rightEl,'===this.rightEl');
        
        this.headerBtnEl.addEventListener("click", async(e) => {
            const detialEl = document.getElementById("im-group-detial");
            if(detialEl.className=="moreInfo showBlock") {
                if(this.userdetialListEl) {
                    this.clearRightDetial();
                }
                detialEl.className="moreInfo hide";
                return
            }
            
            const groupDetialInfo = await this.ImEventApi.getGroupDetialInfo({
                group_id: e.target.id
            })
            
            detialEl.className = "moreInfo showBlock";
            if(groupDetialInfo) {
                this.renderDetial(groupDetialInfo);
                this.renderRightSearch();
            }
        })
    }

    clearVoice() {
        this.backEl.className = "btn back hide";
        this.uploadEl.className = "btn wenjian";
        this.emjoyBtnEl.className = "btn emjoy";
        this.micBtnEl.className = "btn mic";
        this.inputEl.className = "im-input";
        this.time = 0;
        this.timer = null;
        this.stream = null;
        this.blob = null;
        this.micState = null;
        this.micBtnIcon.setAttribute("state", "release");
        this.micAudioEl = null;
        this.audioInputEl.className = "input-container hide";
    }

    createVoice() {
        window.navigator.mediaDevices
          .getUserMedia({ video: false, audio: true })
          .then((stream) => {
            if (state.speakState !== SPEAK_STATE.LOADING) {
              recorder?.stop();
              return;
            }
            state.speakState = SPEAK_STATE.GET;
            recorder.start(stream);
          });
    }

    /* 监听输入框事件 */
    renderInputEvent(el) {
        el.addEventListener("keydown", (event) => {
            if (event.keyCode === 13) {
                if(el.innerHTML) {
                    var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
                    let content = el.innerHTML
                        .replace(/<img .+?text=\"(.+?)\".+?>/g, "$1")
                        .replace(/<.+?>/g, "")
                        .replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
                            return arrEntities[t];
                        });
                    if (!content.trim()) return;
                    this.sendMsg({ content_type: "1", content });
                }
                this.inputEl.innerHTML = "";
            }
        })
    }

    /* 上传文件 */
    renderUploadEvent(el) {
        el.addEventListener("click", (event) => {
            document.getElementById('upload-file').click();
            document.getElementById('upload-file').addEventListener('change',(event)=>{
                if(event.target.files[0].type.indexOf("image") != -1) {
                    this.uploadFile(event.target.files[0],"2");
                } else if(event.target.files[0].type.indexOf("video") != -1) {
                    this.uploadFile(event.target.files[0],"3");
                } else {
                    this.uploadFile(event.target.files[0],"5");
                }
            })
        })
    }

    uploadFile(file, type) {
        if(this.file===file) {
            return;
        }
        this.file = file;
        let form = new FormData();
        form.append('type','1');
        form.append('file',file);
        console.log(form.get('type'),'===form');
        console.log(form.get('file'),'===form');
        window._rtc.api.uploadByFileType(form).then((res)=>{
            if(res.url) {
                this.sendMsg({ content_type: type, content: res.url, content_type_value: res.name})
            }
        })
    }

    /* 表情包 */
    renderEmjoy() {
        if(EMOJI_LIST && EMOJI_LIST.length > 0) {
            EMOJI_LIST.forEach((item,index) => this.emjoyListEl.append(this.addEmjoy(item)));
        }
    }

    addEmjoy(data) {
        const emjoyItemEl = createTpl(
            tplReplace(emjoyTpl.trim(), {
                emjoy: data.icon,
                face: data.face
            })
        )[0];
        
        addEvent.call(this, emjoyItemEl, data);
        return emjoyItemEl;

        function addEvent(el, emjoy) {
            el.addEventListener("click",(e)=>{
                console.log(emjoy);
                const imgStr = this.getImgByFaceName(emjoy.face);
                console.log(imgStr,'===imgStr')
                this.setInputStr(imgStr);
                this.emjoyEl.className = "emjoy-box hide";
            })
        }
    }

    getImgByFaceName(faceName) {
        var imgStr = '<img class="qqemoji small text="@faceName" src="@faceSrc"/>';
        var faceIndex = 0;
        for(let i=0; i<EMOJI_LIST.length; i++){
             if (EMOJI_LIST[i].face === faceName) {
                faceIndex = i;
                break;
            }
        }
        imgStr = imgStr.replace(/@faceName/g,`face[${faceName}]`)
            .replace(/@faceSrc/g,EMOJI_LIST[faceIndex].icon)
        return imgStr;
    }

    setInputStr(data) {
       let content =  this.inputEl.innerHTML;
       let newContent = `${content}${data}`;
       this.inputEl.innerHTML = newContent;
    }

    async sendMsg({ content_type = "1", content, content_type_value = "", file={} }) {
        let chatMsg = new ChatMsg({
            user:this.userInfo.user,
            receiverInfo: this.isSelectChatItem,
            content, content_type,file,content_type_value
        })
        /* console.log(chatMsg,'===data') */
        const result = await this.ImEventApi.sendIM(chatMsg);
        if(chatMsg.group_id && chatMsg.is_group=="1") {
            result.group_id = chatMsg.group_id;
        } else {
            result.group_id = null;
        }

        if(result) {
            this.sendMsgProcess(result);
        }
        /* console.log(result,'===resulr') */
    }

    getImTargetPhone(data) {
        let target = 
          data.is_group === IS_GROUP.YES
            ? data.group_id
            : data.sender_id === this.userInfo.user.id
            ? data.receiver_phone
            : data.sender_phone;
        return target;
      };

    getImTargetName(data) {
        let target =
        data.is_group === IS_GROUP.YES
            ? data.group_id
            : data.sender_id === this.userInfo.user.id
            ? data.receiver_name
            : data.sender_name;
        return target;
    };

  getImTargetId(data) {
    let targetId = "";
    if(data.is_group === IS_GROUP.YES){
        targetId = data.group_id;
    } else {
        targetId = data.sender_id === this.userInfo.user.id ? data.receiver_id || data.receiver : data.sender_id || data.sender;
    }
    return targetId;
};

    /* 打开用户列表 */
    addListenClick(el) {
        this.isAddMember = false;
        this.isGroupUser = null;
        el.addEventListener("click", (e) => {
            this.maskEl.style.display = "flex";
        })
    }

    /* 渲染左侧列表框 */
    renderLeftContainer() {
        if(this.chatMsgList && this.chatMsgList.length > 0) {
            this.initRecordList();
        }
        this.renderRecordListSearch();
    }

    /* 初始化群列表 */
    initRecordList() {
        const value = this.imLeftEl.querySelector(".search-input").value;
        if(value) {
            this.chatMsgList = this.chatMsgList.filter((item)=>{
                tem.group_name?.includes(value) ||
                item.sender_name?.includes(value) ||
                item.receiver_name?.includes(value)
            })
        }

        /* 时间排序未做 */

        this.chatMsgList.forEach((record) => this.imRecordListEl.append(this.addRecord(record)));
        return this.chatMsgList;
    }

    /*消息列表搜索 */
    renderRecordListSearch() {
        const searchEl = this.imLeftEl.querySelector(".search-input");
        searchEl.addEventListener("input", (e) => {
            const value = e.target.value;
            const recordListEl = Array.from(this.imRecordListEl.children);
            recordListEl.forEach((el) => {
              if (el.querySelector(".name").innerText.includes(value)) {
                el.className = "chat-item show";
              } else {
                el.className = "chat-item hide";
              }
            });
        });
    }

    /* 渲染消息记录节点 */
    addRecord(record) {
        let target = this.getImTargetId(record);
        let isSelected = false;

        /* 如果已经存在 */
        if (this.imRecordItemMap[target]) {
            if(this.isSelectChatEl===this.imRecordItemMap[target]) {
                isSelected = true;
            }
            this.imRecordListEl.removeChild(this.imRecordItemMap[target]);
        }

        let name = "";
        let avatar = defaultIcon;
        let messageId = "";
        let content = "";
        if(record.is_group === IS_GROUP.YES) {
            name = record.group_name;
            avatar = groupDefaultIcon;
            messageId = record.group_id;
        } else {
            name = record.sender_id === this.userInfo.user.id ? record.receiver_name : record.sender_name;
           /*  avatar = record.sender_id === this.userInfo.user.id ? record.receiver_headimg : record.sender_headimg; */
            messageId = record.sender_id === this.userInfo.user.id ? record.receiver_id || record.receiver : record.sender_id || record.sender;
        }

        const recordItemEl = createTpl(
            tplReplace(imRecordListItemTpl.trim(), {
              name: name,
              avatar: avatar,
              time: formatTime(record.createtime, "HH:mm:SS"),
              newMsgCount: record.newMsgCount,
              content: this.draftObj[messageId] ? `[草稿]${this.draftObj[targetId]}` : this.getContent(record)
            })
        )[0];

        /* record节点添加点击事件 */
        this.isSelectChatEl = recordItemEl;
        addEvent.call(this, recordItemEl, record);
        this.imRecordItemMap[target] = recordItemEl;

        if(isSelected) {
            this.setSupEl(recordItemEl, 0);
            recordItemEl.className = "chat-item show active";
        } else {
            this.setSupEl(recordItemEl, record.newMsgCount);
        }
        return recordItemEl;

        function addEvent(el, record) {
            el.addEventListener("click", (e) => {
                this.headerBtnEl.id = record.group_id;
                this.selectNode = recordItemEl;
                
                if(this.userdetialListEl) {
                    this.clearRightDetial();
                }

                const detialEl = document.getElementById("im-group-detial");
                detialEl.className = "moreInfo hide";

                if(this.isSelectChatEl) {
                    this.isSelectChatEl.className = "chat-item show noactive";
                }
                
                this.setSupEl(el, 0);
                this.isSelectChatEl = el;
                this.isSelectChatItem = record;
                el.className = "chat-item show active";
                this.imEl.querySelector(".headerName").innerText = name;

                const emptyEl = this.chatBoxEl.querySelector(".box-im-main-content");
                
                emptyEl.style.display = "none";
                this.headerBtnEl.style.display = "inline-block";
                this.chatContentEl.style.display = "block";

                this.getChatList(record);
            });
        }
    }

    renderDetial(data) {
        this.isAddMember = false;
        this.isGroupUser = null;
        const bottomBtn = document.getElementById("disSolve");

        this.infoFormEl = this.rightEl.querySelector(".info-form");
        this.userdetialListEl = this.rightEl.querySelector(".right-list");
        const disGroupEl = this.rightEl.querySelector(".disSolve");

        bottomBtn.innerText = data.creater_id===this.userInfo.user.id ? "解散群聊" : "删除并退出";

        const infoFormItemEl = createTpl(
            tplReplace(groupNameAndGongGaoTpl.trim(), {
                value: data.groupName,
                icon: groupIcon,
                name: "群聊名称"
            })
        )[0];
        addEvent.call(this, infoFormItemEl, data, "1");
        this.rightUserItemMap["groupName"] = infoFormItemEl;
        this.infoFormEl.append(infoFormItemEl);

        const infoFormItemEl2 = createTpl(
            tplReplace(groupNameAndGongGaoTpl.trim(), {
                value: data.groupAnnouncement || "",
                icon: ggaoIcon,
                name: "群公告"
            })
        )[0];
        addEvent.call(this, infoFormItemEl2, data, "2");
        this.rightUserItemMap["ggao"] = infoFormItemEl2;
        this.infoFormEl.append(infoFormItemEl2);

        if(data.groupUserList && data.groupUserList.length > 0) {
            const add_userItemEl = createTpl(
                tplReplace(rightUserItemTpl.trim(), {
                  text: "增加",
                  icon: addIcon
                })
            )[0];
            addUserEvent.call(this, add_userItemEl, data);
            this.rightUserItemMap["add"] = add_userItemEl;
            this.userdetialListEl.append(add_userItemEl);
            
            if(data.creater_id===this.userInfo.user.id) {
                const reduce_userItemEl = createTpl(
                    tplReplace(rightUserItemTpl.trim(), {
                      text: "减少",
                      icon: reduceIcon
                    })
                )[0];
                reduceUserEvent.call(this, reduce_userItemEl, data);
                this.rightUserItemMap["reduce"] = reduce_userItemEl;
                this.userdetialListEl.append(reduce_userItemEl);
            }

            data.groupUserList.forEach((user) => this.userdetialListEl.append(this.addUserDetial(user)));

            disGroupEl.addEventListener("click", async(e) => {
                const result = await this.ImEventApi.disMissGroup({
                    group_id: data.group_id,
                    user_id: this.userInfo.user.id
                })
            })
        }

        function addEvent(el, group, type) {
            const textInput = el.querySelector(".info-input");
            textInput.addEventListener("blur", async(e) => {
                if(e.target.value) {
                    if(type=="1") {
                        let result = await this.ImEventApi.updateGroupName({
                            group_id: group.group_id,
                            user_id: this.userInfo.user.id,
                            group_name: e.target.value,
                        });
                    } else {
                        let result2 = await this.ImEventApi.updateGroupName({
                            group_id: group.group_id,
                            user_id: this.userInfo.user.id,
                            announcement: e.target.value
                        });
                    }
                    await this.ImEventApi.updateCheckFlagIm({
                        msgType: 2,
                        user_id: this.userInfo.user.id,
                        group_id: group.group_id
                    })
                }
            })
        }

        function addUserEvent(el, group) {
            el.addEventListener("click", (e) => {
                this.isAddMember = true;
                this.isGroupUser = group;
                this.maskEl.style.display = "flex";
            })
        }

        function reduceUserEvent(el, group) {
            el.addEventListener("click", (e) => {
                const userListEl = Array.from(this.userdetialListEl.children);
                for(var i = 0; i < userListEl.length; i++) {
                    if(i>=2) {
                        const input = userListEl[i].querySelector(".remove");
                        input.className = "remove show";
                        input.addEventListener("click", async(event) => {
                            console.log(event.target.id,'===event')
                            const result = await this.ImEventApi.delMemberIm({
                                group_id: group.group_id,
                                user_id: this.userInfo.user.id,
                                ops_ids: event.target.id.substring(6)
                            })
                            if (this.rightUserItemMap[event.target.id.substring(6)]) {
                                this.userdetialListEl.removeChild(this.rightUserItemMap[event.target.id.substring(6)]);
                            }
                        })
                    }
                }
            })
        }
    }

    renderRightSearch() {
        const right_searchUserEl = this.rightEl.querySelector(".right-search-input");
        right_searchUserEl.addEventListener("input", (e) => {
            const value = e.target.value;
            const userListEl = Array.from(this.userdetialListEl.children);
            userListEl.forEach((el) => {
              if (el.querySelector(".text").innerText.includes(value)) {
                el.className = "item show";
              } else {
                el.className = "item hide";
              }
            });
        });
    }

    clearRightDetial() {
        /* 未完成清除上一个群详情 */
        const bottomBtn1 = document.getElementById("disSolve");
        bottomBtn1.innerText = "";

        let child = document.getElementsByClassName("item");
        if(child && child.length > 0) {
            child[0].parentNode.innerHTML = "";
        }

        let child2 = document.getElementsByClassName("info-item");
        if(child2 && child2.length > 0) {
            child2[0].parentNode.innerHTML = "";
        }

        this.rightUserItemMap = {};
        
    }

    addUserDetial(user) {
        if (this.rightUserItemMap[user.id]) {
            this.userdetialListEl.removeChild(this.rightUserItemMap[user.id]);
        }
        const right_userItemEl = createTpl(
            tplReplace(rightUserItemTpl.trim(), {
              text: user.name,
              icon: defaultIcon,
              user: user,
              userId: `group_`+user.id
            })
        )[0];

        this.rightUserItemMap[user.id] = right_userItemEl;
        return right_userItemEl;
    }

    setSupEl(el, count) {
        const supEl = el.querySelector(".badge-content");
        if(parseInt(count) > 0) {
            supEl.className = "badge-content showSup";
        } else {
            supEl.className = "badge-content hide";
        }
    }

    getContent(record) {
        if(!record.content_type) {
            return;
        }

        const contentTypeMap = {
            [CONTENT_TYPE.IMG]:'图片',
            [CONTENT_TYPE.VIDEO]:'视频',
            [CONTENT_TYPE.AUDIO]:'音频',
            [CONTENT_TYPE.FILE]:'文件',
            [CONTENT_TYPE.POSITION]:'定位',
            [CONTENT_TYPE.OHTER]:'其他',
            [CONTENT_TYPE.SYSTEM]:'系统消息'
        }
        let content;
        switch (record.content_type) {
            case CONTENT_TYPE.TEXT:
                content = record.content;
                break;
            case CONTENT_TYPE.SYSTEM:
                content =  `[${contentTypeMap[record.content_type]}]${record.content}`;
                break;
            default:
                content = `[${contentTypeMap[record.content_type]}]`;
                break;
        }
        return content;
    }

    /* 渲染弹框user */
    renderUserList() {
        if(this.inviteesList && this.inviteesList.length > 0) {
            this.initUserList()
        }
        this.renderUserdListSearch();
    }

    renderUserdListSearch() {
        const searchUserEl = this.maskLeftEl.querySelector(".search-input");
        searchUserEl.addEventListener("input", (e) => {
            const value = e.target.value;
            const userListEl = Array.from(this.leftUserListEl.children);
            userListEl.forEach((el) => {
                console.log(el.querySelector(".userName").innerText,'===el.querySelector(".userName").innerText')
              if (el.querySelector(".userName").innerText.includes(value)) {
                el.className = "user-check-item show";
              } else {
                el.className = "user-check-item hide";
              }
            });
        });
    }

    initUserList() {
        const value = this.maskLeftEl.querySelector(".search-input").value;
        if(value) {
            this.inviteesList = this.inviteesList.filter((item)=>{
                tem.name?.includes(value) ||
                item.phone?.includes(value)
            })
        }

        this.inviteesList.forEach((user) => this.leftUserListEl.append(this.addUser(user)));
        return this.inviteesList;
    }

    addUser(user) {
        if(this.userListMap[user.id]) {
            this.leftUserListEl.removeChild(this.userListMap[user.id]);
        }

        const userItemEl = createTpl(
            tplReplace(userListItemTpl.trim(), {
                userName: user.name || user.phone,
                user: user,
                id: user.id
            })
        )[0];

        addEvent.call(this, userItemEl, user);
        this.userListMap[user.id] = userItemEl;
        return userItemEl;

        function addEvent(el, user) {
            const checkBtnEl = el.querySelector(".checkBtn");
            checkBtnEl.addEventListener("change", (e) => {
                if(this.checkUsers.length > 0) {
                    const result = this.checkUsers.find((item)=>{
                        return item.id == user.id;
                    });
                    if(result) {
                        let index = this.checkUsers.findIndex((item) => item.id == result.id);
                        if (index !== -1) {
                            this.checkUsers.splice(index, 1);
                        }
                        this.removeRightItem(result);
                    } else {
                        this.checkUsers.push(user);
                    }
                } else {
                    this.checkUsers.push(user);
                }
                this.renderRightUser()
            });
        }
    }

    renderRightUser() {
        if(this.checkUsers && this.checkUsers.length > 0) {
            this.checkUsers.forEach((user) => this.rightUserListEl.append(this.addRightList(user)));
            this.rightHeaderCountEl.innerText = this.checkUsers.length;
        }
    }

    addRightList(user) {
        if (this.userCheckedListMap[user.id]) {
            this.rightUserListEl.removeChild(this.userCheckedListMap[user.id]);
        }

        const checkedItemEl = createTpl(
            tplReplace(checkedListTpl.trim(), {
                userName: user.name || user.phone,
                user: user
            })
        )[0];
        addEvent.call(this, checkedItemEl, user);

        this.userCheckedListMap[user.id] = checkedItemEl;
        return checkedItemEl;

        function addEvent(el, user) {
            const checkBtnEl = el.querySelector(".checkBtn");
            checkBtnEl.addEventListener("change", (e) => {
                let index = this.checkUsers.findIndex((item) => item.id == user.id);
                if (index !== -1) {
                    this.checkUsers.splice(index, 1);
                }
                this.removeRightItem(user);

                const leftCheckedEl = document.getElementById(user.id);
                leftCheckedEl.checked = false;
            });
        }
    }

    removeRightItem(user) {
        if (this.userCheckedListMap[user.id]) {
            this.rightUserListEl.removeChild(this.userCheckedListMap[user.id]);
            this.rightHeaderCountEl.innerText = this.checkUsers.length;
        }
    }

    /* 添加成员 */
    memberAddProcess(data) {
        if(this.userdetialListEl) {
            this.clearRightDetial();
        }
        const detialEl = document.getElementById("im-group-detial");
        detialEl.className = "moreInfo hide";
        /* 更新群信息详情就好 */
    }

    /* 接收消息 */
    sendMsgProcess(data) {
        let targetId = "";
        if(data.is_group === IS_GROUP.YES){
            targetId = data.group_id;
        } else {
            targetId = data.sender_id === this.userInfo.user.id ? data.receiver_id || data.receiver : data.sender_id || data.sender;
        }

        if(this.chatMsgListObj[targetId]) {
            if(!this.chatMsgListObj[targetId].list) {
                this.chatMsgListObj[targetId].list = [];
            }
            this.chatMsgListObj[targetId].list.push(data)
        }
        this.messageProcess({ targetId, chat: data })
    }

    /* 聊天列表信息更新 */
    messageProcess({ targetId, chat }) {
        console.log(chat,targetId)
        let chatMsgList = [...this.chatMsgList];
        let isSelectChatItem = Object.assign({}, this.isSelectChatItem);
        const node = Array.from(this.imRecordListEl.children)[0];
        let foundChat = chatMsgList.some((item, index) => {
            //聊天列表是否存在
            let chatTarget = this.getImTargetId(item);
            if (chatTarget === targetId) {
                let selectTarget = this.getImTargetId(isSelectChatItem); //正在打开该会话的时候消息就不会加1
                if (selectTarget !== targetId) {
                    item.newMsgCount++;
                } else {
                    console.log(this.msgListEl,'===111');
                    this.msgListEl.append(this.addMsgList(chat));
                    setTimeout(()=>{
                        const dic = document.getElementById("msgContainer");
                        dic.scrollTop = dic.scrollHeight;
                    },20);
                }
                let obj = Object.assign({}, item, chat);
                chatMsgList.splice(index, 1);
                chatMsgList.unshift(obj);
                if(node == this.selectNode) {
                    /* this.imRecordListEl.append(this.addRecord(obj)); */
                } else {
                    this.imRecordListEl.insertBefore(this.addRecord(obj),node);
                }
                /* this.imRecordListEl.append(this.addRecord(obj)); */
                return true;
            }
        })

        if (!foundChat) {
            chat.newMsgCount = 1;
            chatMsgList.unshift(chat);
            this.imRecordListEl.insertBefore(this.addRecord(chat),node);
            /* this.imRecordListEl.append(this.addRecord(chat)); */
        }
        this.chatMsgList = chatMsgList;
    }

    /* 解散群 */
    imGourpOut(data) {
        if(this.imRecordItemMap[data.group_id]) {
            this.imRecordListEl.removeChild(this.imRecordItemMap[data.group_id]);
        }
    }

    /* 获取聊天记录 */
    async getChatList(record) {
        let targetId = "";
        if(record.is_group === IS_GROUP.YES){
            targetId = record.group_id;
        }else{
            targetId = record.sender_id === this.userInfo.user.id ? record.receiver_id || record.receiver : record.sender_id || record.sender;
        }

        if (!this.chatMsgListObj[record.id]) {
            this.chatMsgListObj[targetId] = {
                list: null,
                page: {
                    page: 1,
                    pageSize: 115
                }
            }
            if(record.is_group === IS_GROUP.YES) {
                const groupInfo = await this.ImEventApi.getGroupChatMsgList({
                    user_id: this.userInfo.user.id,
                    group_id: record.group_id,
                    page: 1,
                    pageSize: 115
                })

                if(groupInfo.chatMsgList && groupInfo.chatMsgList.length > 0) {
                    this.chatMsgListObj[targetId].list = groupInfo.chatMsgList.reverse();
                    this.chatMsgListObj[targetId].page.page += 1;
                    /* if (groupInfo.chatMsgList.length < chatMsgListObj[targetId].page.pageSize ||
                        groupInfo.totalCount === chatMsgListObj[targetId].list.length
                    ) {
                        chatMsgListObj[targetId].finish = true;
                    } */
                    this.renderChatList(this.chatMsgListObj[targetId].list);
                    await this.ImEventApi.updateCheckFlagIm({
                        msgType: 2,
                        user_id: this.userInfo.user.id,
                        group_id: targetId
                    })
                }
            } else {
                const pointList = await this.ImEventApi.getPointChatMsgList({
                    page: 1,
                    pageSize: 115,
                    sender: targetId,
                    receiver: this.userInfo.user.id
                })

                if(pointList.chatMsgList && pointList.chatMsgList.length > 0) {
                    this.chatMsgListObj[targetId].list = pointList.chatMsgList.reverse();
                    this.chatMsgListObj[targetId].page.page += 1;
                    /* if (groupInfo.chatMsgList.length < chatMsgListObj[targetId].page.pageSize ||
                        groupInfo.totalCount === chatMsgListObj[targetId].list.length
                    ) {
                        chatMsgListObj[targetId].finish = true;
                    } */
                    this.renderChatList(this.chatMsgListObj[targetId].list);
                    await this.ImEventApi.updateCheckFlagIm({
                        msgType: 1,
                        user_id: this.userInfo.user.id,
                        sender: targetId
                    })
                } else {
                    this.renderChatList([]);
                    await this.ImEventApi.updateCheckFlagIm({
                        msgType: 1,
                        user_id: this.userInfo.user.id,
                        sender: targetId
                    })
                }
                console.log(pointList,'===pointList')
            }
            
        } else {
            this.renderChatList(this.chatMsgListObj[targetId].list);
            if(record.is_group === IS_GROUP.YES) {
                await this.ImEventApi.updateCheckFlagIm({
                    msgType: 2,
                    user_id: this.userInfo.user.id,
                    group_id: targetId
                })
            } else {
                await this.ImEventApi.updateCheckFlagIm({
                    msgType: 1,
                    user_id: this.userInfo.user.id,
                    sender: targetId
                })
            }
            
        }
    }

    renderChatList(list) {
        const moreEl = this.chatTopEl.querySelector(".message-more");
        moreEl.style.display = "none";
        this.msgListEl = document.getElementById("msgContainer");

        const allMsgItemEl = document.getElementsByClassName("msg-item");
        if(allMsgItemEl.length > 0) {
            for(let i = allMsgItemEl.length-1; i >= 0; i-- ) {
                allMsgItemEl[i].parentNode.removeChild(allMsgItemEl[i])
            }
        }

        if(list && list.length > 0) {
            list.forEach((item) => this.msgListEl.append(this.addMsgList(item)));
            setTimeout(()=>{
                const dic = document.getElementById("msgContainer");
                dic.scrollTop = dic.scrollHeight;
            },20);
        }
    }

    getEmoji(value) {
        if (value == undefined) {
          return;
        }
        let res = value.replace(/face\[(.+?)\]/g, (item, value) => {
          return this.getImgByFaceName(value);
        });
        return res;
    }

    addMsgList(data) {
        switch(data.content_type) {
            case CONTENT_TYPE.TEXT:
                const textMsgEl = createTpl(
                    tplReplace(msgItemTpl.trim(), {
                        time: data.createtime_str,
                        issender: data.sender==this.userInfo.user.id ? true : false,
                        userName: data.sender_name,
                        headimg: defaultIcon
                    })
                )[0];
                const childItem = textMsgEl.querySelector(".item-content");
                const contentText = this.getEmoji(data.content);
                const textItemEl = createTpl(
                    tplReplace(textMsgTpl.trim(), {
                        content:  contentText
                    })
                )[0];
                childItem.append(textItemEl)
                return textMsgEl;
            case CONTENT_TYPE.SYSTEM:
                const sysMsgEl = createTpl(
                    tplReplace(msgSysItemTpl.trim(), {
                        sysInfo: data.content
                    })
                )[0];
                return sysMsgEl;
            case CONTENT_TYPE.IMG:
                const picMsgEl = createTpl(
                    tplReplace(msgItemTpl.trim(), {
                        time: data.createtime_str,
                        issender: data.sender==this.userInfo.user.id ? true : false,
                        userName: data.sender_name,
                        headimg: defaultIcon
                    })
                )[0];
                const childItem1 = picMsgEl.querySelector(".item-content");
                const imgMsgEl = createTpl(
                    tplReplace(picMsgTpl.trim(), {
                        url: data.content
                    })
                )[0];
                childItem1.append(imgMsgEl);
                this.addPicEvent.call(this, picMsgEl, data);
                return picMsgEl;
            case CONTENT_TYPE.FILE:
                const fileMsgEl = createTpl(
                    tplReplace(msgItemTpl.trim(), {
                        time: data.createtime_str,
                        issender: data.sender==this.userInfo.user.id ? true : false,
                        userName: data.sender_name,
                        headimg: defaultIcon
                    })
                )[0];
                const childItem2 = fileMsgEl.querySelector(".item-content");
                const fileChildMsgEl = createTpl(
                    tplReplace(fileMsgTpl.trim(), {
                        href: data.content,
                        file: data.content_type_value,
                        url: textIcon,
                        name: data.content_type_value || "未知文件"
                    })
                )[0];
                childItem2.append(fileChildMsgEl);
                return fileMsgEl;
            case CONTENT_TYPE.VIDEO:
                const videoMsgEl = createTpl(
                    tplReplace(msgItemTpl.trim(), {
                        time: data.createtime_str,
                        issender: data.sender==this.userInfo.user.id ? true : false,
                        userName: data.sender_name,
                        headimg: defaultIcon
                    })
                )[0];
                const childItem3 = videoMsgEl.querySelector(".item-content");
                const videoChildMsgEl = createTpl(
                    tplReplace(videoMsgTpl.trim(), {
                        url: data.content
                    })
                )[0];
                childItem3.append(videoChildMsgEl);
                this.addVideoEvent.call(this, videoMsgEl, data);
                return videoMsgEl;
            case CONTENT_TYPE.AUDIO:
                const audioMsgEl = createTpl(
                    tplReplace(msgItemTpl.trim(), {
                        time: data.createtime_str,
                        issender: data.sender==this.userInfo.user.id ? true : false,
                        userName: data.sender_name,
                        headimg: defaultIcon
                    })
                )[0];
                const childItem4 = audioMsgEl.querySelector(".item-content");
                const audioChildMsgEl = createTpl(
                    tplReplace(audioMsgTpl.trim(), {
                        url: data.content,
                        direction: data.sender!==this.userInfo.user.id ? "right" : "left",
                    })
                )[0];
                childItem4.append(audioChildMsgEl);
                const audi = audioChildMsgEl.querySelector(".audioItem");
                const timeEl = audioChildMsgEl.querySelector(".time");
                audi.addEventListener('canplaythrough', () => {
                    console.log('audio.duration----', audi.duration);
                    const second = Math.floor(audi.duration);
                    if(second=="Infinity") {
                        timeEl.innerHTML = "0";
                    } else {
                        timeEl.innerHTML = `${second}`;
                    }
                })
                console.log(audi.duration,'===audi.duration')
                this.addAudioEvent.call(this, audioMsgEl, data);
                return audioMsgEl;

        }
    }

    addVideoEvent(dom, data) {
        const playBtn = dom.querySelector(".play");
        playBtn.addEventListener("click",(e)=>{
            this.videoPreviewEl.style.display = "block";
            const dowm = this.videoPreviewEl.querySelector(".downIco");
            dowm.setAttribute("href",data.content);
            const videoView = createTpl(
                tplReplace(videoPreviewTpl.trim(), {
                    url: data.content
                })
            )[0];
            this.mainView.append(videoView);
        })
    }

    addAudioEvent(dom, data) {
        const p_bg = dom.querySelector(".play");
        p_bg.addEventListener("click",(e)=>{
            const currentDom = dom.querySelector(".audioItem");
            if(this.currentAudio) {
                this.p_bg.setAttribute("state","pause");
                this.currentAudio.pause();
            }
            if(currentDom === this.currentAudio) {
                this.currentAudio = null;
                this.p_bg = null;
                return
            }
            this.currentAudio = currentDom;
            this.p_bg = p_bg;
            p_bg.setAttribute("state","play");
            this.currentAudio.play();
        })
    }

    addPicEvent(dom, data) {
        const omgBtn = dom.querySelector(".msgPic");
        omgBtn.addEventListener("click",(e)=>{
            this.videoPreviewEl.style.display = "block";
            const dowm = this.videoPreviewEl.querySelector(".downIco");
            dowm.setAttribute("href",data.content);
            const imgView = createTpl(
                tplReplace(picPreviewTpl.trim(), {
                    url: data.content
                })
            )[0];
            this.mainView.append(imgView);
        })
    }
}

