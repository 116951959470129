import { getStorage } from '../../util/index';
import { MEET_NUMBER_KEY } from '../../dict/index';

export const I_MEET_IN = 'i_meet_in'; //我进入会有
export const OTHER_MEET_IN = 'other_meet_in'; //别人进入会议
export const MEET_IN = 'meet_in';
export const MEET_ADD = 'meet_add';
export const MEET_KICK = 'meet_kick';
export const MEET_OUT = 'meet_out';

export const MEET_OFF = 'meet_off';
export const MEET_MUTE = 'meet_mute';

export const MEET_UNMUTE = 'meet_unmute';
export const MEET_DEAF = 'meet_deaf';

export const MEET_UNDEAF = 'meet_undeaf';
export const MEET_STARTTALKING = 'meetStartTalking';
export const MEET_STOPTALKING = 'meetStopTalking';

export const MEET_INVITE = 'meet_invite'; //会议邀请
export const MEET_ADD_USER = 'meet_add_user';

export function useMeetNotice() {
  return {
    meet_in(data, userInfo) {
      const { meetAllUsers, meetInUser, meet } = data;
      let oType =
        meetInUser.phone === userInfo.user.phone ? I_MEET_IN : OTHER_MEET_IN;
      return oType;
    },
    meet_add(data, userInfo) {
      const { meet, meetAddUser } = data;
      let oType = '';
      const meetnum = getStorage(MEET_NUMBER_KEY);
      if (
        meetnum !== meet.meetnum &&
        meetAddUser.phone === userInfo.user.phone
      ) {
        oType = MEET_INVITE;
      } else {
        oType = MEET_ADD_USER;
      }
      return oType;
    }
  };
}
