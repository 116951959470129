import Session from "../base/Session";
import api from "../../api/index";
import { getStorage, removeStorage } from "../../util/index";
import { MEET_NUMBER_KEY } from "../../dict/index";
import { MEET_STATE, MEET_LAYOUUT } from "../../dict/index";

export default class MeetSession extends Session {
  constructor(
    { publishPcInfo, playerPcInfo, isCompere, meet, meetAllUsers, state },
    event,
    userInfo
  ) {
    super({ event });
    this.publishPcInfo = publishPcInfo;
    this.playerPcInfo = playerPcInfo;
    this.meet = meet;
    this.meetAllUsers = meetAllUsers || [];
    this.isCompere = isCompere || false;
    this.userInfo = userInfo;
    this.state = state || MEET_STATE.AVAILABLE;
    this.speaker = {};
    this.layout = MEET_LAYOUUT.ONE_AND_ONE;
    this.init();
  }

  init() {}

  // 全体禁言
  muteAll() {
    return api.meetMute({
      meetnum: this.meet.meetnum,
      phone: this.meetAllUsers.map((item) => item.phone).toString(),
    });
  }

  // 全体取消禁言
  cancelMuteAll() {
    return api.meetUnmute({
      meetnum: this.meet.meetnum,
      phone: this.meetAllUsers.map((item) => item.phone).toString(),
    });
  }

  muted(meetUser) {
    return api.meetMute({
      meetnum: this.meet.meetnum,
      phone: meetUser.phone,
    });
  }

  cancelMuted(meetUser) {
    return api.meetUnmute({
      meetnum: this.meet.meetnum,
      phone: meetUser.phone,
    });
  }

  //置聋 实现
  deaf(meetUser) {
    return api.meetDeaf({
      meetnum: this.meet.meetnum,
      phone: meetUser.phone,
    });
  }

  cancelDeaf(meetUser) {
    return api.meetUndeaf({
      meetnum: this.meet.meetnum,
      phone: meetUser.phone,
    });
  }

  meetKick(meetUser) {
    if (!this.isCompere) return;
    return api.meetKick({ meetnum: this.meet.meetnum, phone: meetUser.phone });
  }

  meetOut(meetUser) {
    return api.meetOut({ meetnum: this.meet.meetnum, phone: meetUser.phone });
  }

  meetfloor(meetUser) {
    return api.meetFloor({ meetnum: this.meet.meetnum, phone: meetUser.phone });
  }

  callAgain(meetUser) {
    return api.meetAdd({ meetnum: this.meet.meetnum, phone: meetUser.phone });
  }

  shareScreen(cancalCb) {
    this.publishPcInfo && this.publishPcInfo.shareScreen(cancalCb);
  }

  cancelShare() {
    this.publishPcInfo && this.publishPcInfo.cancelShareScreen();
  }

  meetLayout(layout) {
    return new Promise(async (resolve, reject) => {
      try {
        await api.meetLayout({ meetnum: this.meet.meetnum, layout });
        this.layout = layout;
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  addGetWay(phone) {
    return api.meetAddGateway({ meetnum: this.meet.meetnum, phone });
  }

  addUserToWX(mobile) {
    if (!mobile || !mobile.length) return;
    return api.appletMeet({
      mobile,
      meetnum: (this.meet && this.meet.meetnum) || getStorage(MEET_NUMBER_KEY),
      user_id: this.userInfo.user.id,
    });
  }

  addUser(phone) {
    if (!phone || !phone.length) return;
    // if (this.state !== MEET_STATE.MEETIN) {
    //   throw new Error(`addUser: The meeting has not started `);
    // }
    if (Object.prototype.toString.call(phone) === "[object Array]") {
      phone = phone.toString();
    }
    return api.meetAdd({
      phone,
      meetnum: (this.meet && this.meet.meetnum) || getStorage(MEET_NUMBER_KEY),
    });
  }

  terminate() {
    // 终止通话   数据删除是在ws通知后删除
    return new Promise(async (resolve, reject) => {
      if (this.state === MEET_STATE.AVAILABLE) {
        resolve();
        return;
      }
      this.state = MEET_STATE.AVAILABLE;
      this.publishPcInfo && this.publishPcInfo.close();
      this.playerPcInfo && this.playerPcInfo.close();
      removeStorage(MEET_NUMBER_KEY);
      if (this.isCompere) {
        await api.meetOff({ meetnum: this.meet.meetnum });
      } else {
        await api.meetOut({
          meetnum: this.meet.meetnum,
          phone: this.userInfo.user.phone,
        });
      }
      resolve();
    });
  }
}
