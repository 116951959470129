export * from './other';
export * from './p2p';
export * from './channel';
export * from './meet';
import { useP2PNotice } from './p2p';
import { useMeetNotice } from './meet';
import { useChannelNotice } from './channel';
import { useImNotice } from "./im";

const collectMessage = () => {
  const p2pNotice = useP2PNotice();
  const meetNotice = useMeetNotice();
  const channelNotice = useChannelNotice();
  const imNotice = useImNotice();
  return {
    ...p2pNotice,
    ...meetNotice,
    ...channelNotice,
    ...imNotice
  };
};

export function messageProcess(type, data, userInfo) {
  let oType = type;
  const messge = collectMessage();
  oType = (messge[type] && messge[type](data, userInfo)) || oType;
  return oType;
}

export default {
  messageProcess
};
