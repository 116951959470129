import CHANNEL_EVENT from "../../operate/channel/event";

export function useChannelProcess() {
    const _this = this;

    return{
        [CHANNEL_EVENT.CHANNEL_IN]:(...info)=>{
            console.log(info,'===CHANNEL_IN')
        },
        [CHANNEL_EVENT.CHANNEL_ADD]:(...info)=>{
            console.log(info,'===CHANNEL_ADD')
        },
        [CHANNEL_EVENT.CHANNEL_OUT]:(...info)=>{
            _this.setUserStatus(info[1].channel);
            console.log(info,'===CHANNEL_OUT')
        },
        [CHANNEL_EVENT.CHANNEL_DEL_USER]:(...info)=>{
            console.log(info,'===CHANNEL_DEL_USER')
        },
        [CHANNEL_EVENT.CHANNEL_GET]:(...info)=>{
            _this.setSpeaker(info[1].channel)
        },
        [CHANNEL_EVENT.CHANNEL_RELEASE]:(...info) =>{
            console.log("1111", info)
            _this.clearSpeaker(info[1].channel)
        }
    }
}