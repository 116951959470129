import P2P_EVENT from '../../operate/p2p/event';

export function useP2PProcess() {
  const _this = this;

  return {
    [P2P_EVENT.HANGUP]: () => {
      _this.render.closePop();
    },
    [P2P_EVENT.CALLEE_OFFLINE]: () => {
      _this.render.closePop();
    },
    [P2P_EVENT.CALLEE_BUSY]: () => {
      _this.render.closePop();
    },
    [P2P_EVENT.CALLEE_NOT_SYS_USER]: () => {
      _this.render.closePop();
    },
    [P2P_EVENT.TARGER_NOT_CALL]: () => {
      _this.render.closePop();
    },
    [P2P_EVENT.PUBLISH_FIRE]: (e) => {
      console.error('推流异常', e);
    },
    [P2P_EVENT.ADD_PUBLISH_STREAM]: (e) => {
      if (!_this.render.exist) {
        _this.session.terminate && _this.session.terminate();
      }
    }
  };
}
