import {
  LOGIN_TOKEN_KEY,
  MEET_NUMBER_KEY,
  CHANNEL_NUMBER_KEY,
  WS_FAIL_CODE,
} from "./dict/index";
import { LOGIN_ACCOUNT, LOGIN_PASSWORD, WS_URL } from "./dict/config";
import {
  supportDetection,
  getStorage,
  setStorage,
  removeStorage,
} from "./util/index";
import Core_ws from "./websocket/index";
import api from "./api/index";
import imApi from "./api/im";
/* import Channel from "./operate/channel/index"; */
import { getMediaSource } from "./operate/config/MediaSource";
import { regiestConfig } from "./operate/config/index";
import MeetService from "./service/meet";
import P2PService from "./service/p2p";
import ChannelService from "./service/channel";
import ImService from "./service/im";
import "./css/index.css";
import "./directive";
import "./lib/index";

/**
 * @class
 * @classdesc rtc核心类
 */
class RtcCore {
  constructor() {
    this.ws = null;
    this.userInfo = null;
    this.api = api;
    this.imApi = imApi;
    this.p2p = null; //p2p相关
    this.channel = null; //对讲相关
    this.monitor = null; //监控相关
    this.meet = null; //会议相关
    this.im = null; //im相关
    this.radio = null; //广播相关
    this.wsAndHttpConfig = null;
    this.mediaSource = getMediaSource(); //媒体设备信息
    this.config = null;
    this.mediaSupport = supportDetection(); //能力检测
    this.init();
  }

  /**
   * @description 初始化
   */
  init() {
    this.initStorage();
  }

  /**
   * @description 初始化Storage存储
   */
  initStorage() {
    const REMOVE_KEY_LIST = [MEET_NUMBER_KEY, CHANNEL_NUMBER_KEY];
    window.addEventListener("beforeunload", () => {
      REMOVE_KEY_LIST.forEach((key) => {
        removeStorage([key]);
      });
      this.cleanRTCOperate();
      this.destroy();
    });
  }

  /**
   * @description 核心登录的函数
   * @member {string} account 登录账号
   * @member {string} password 登录密码
   * @member {string} last_token 强制登录时 正在登录方的token
   * @return {Promise<Core>} 返回Promise
   */
  regiest({ account, password, last_token }) {
    return new Promise(async (resolve, reject) => {
      try {
        let token =
          getStorage(LOGIN_TOKEN_KEY) || `${new Date().getTime()}_${account}`;
        this.ws = new Core_ws({
          url: WS_URL,
          account,
          password,
          token,
          last_token,
        });
        this.userInfo = await this.ws.regiest(); //ws登录
        this.initRTCOperate(); //相关功能初始化
        setStorage(LOGIN_TOKEN_KEY, token); //token塞入
        setStorage(LOGIN_ACCOUNT, account); //account塞入
        setStorage(LOGIN_PASSWORD, password); //password塞入
        resolve(this);
      } catch (error) {
        console.error("websoket登陆异常", error);
        reject(
          error.cmd
            ? error
            : {
                res_code: WS_FAIL_CODE.OTHER_ERROR,
                res_msg: error,
              }
        );
      }
    });
  }

  /**
   * @description 核心销毁的函数
   * @returns
   */
  destroy() {
    removeStorage(LOGIN_TOKEN_KEY);
    this.ws && this.ws.logout();
  }

  /**
   * @description 初始化相关功能
   */
  initRTCOperate() {
    this.config = regiestConfig({ userInfo: this.userInfo });
    this.p2p = new P2PService({ userInfo: this.userInfo, ws: this.ws });
    this.channel = new ChannelService({ userInfo: this.userInfo, ws: this.ws });
    this.meet = new MeetService({ userInfo: this.userInfo, ws: this.ws });
    this.im = new ImService({ userInfo: this.userInfo, ws: this.ws, imApi: this.imApi })
  }

  /**
   * @description 清空正在通话
   */
  cleanRTCOperate() {
    this.meet &&
      this.meet.meetSession &&
      this.meet.meetSession.terminate &&
      this.meet.meetSession.terminate();
  }
  
}

(() => {
  const rtc = (window._rtc = window._rtc || new RtcCore());
})();

export default window._rtc;
