import MeetSession from "./MeetSession";
import Meet_Event from "./event";
import { MEET_STATE, MEET_USER_STATE } from "../../dict/index";
import {
  MEET_IN,
  MEET_ADD,
  I_MEET_IN,
  OTHER_MEET_IN,
  MEET_KICK,
  MEET_OUT,
  MEET_OFF,
  MEET_UNMUTE,
  MEET_ADD_USER,
  MEET_MUTE,
  MEET_DEAF,
  MEET_UNDEAF,
  MEET_STARTTALKING,
  MEET_STOPTALKING,
  MEET_INVITE,
} from "../../websocket/message/meet";
import { getStorage, removeStorage } from "../../util/index";
import { MEET_NUMBER_KEY } from "../../dict/index";
import BASE_EVENT from "../base/event";

export function useMeetNotice() {
  const _this = this;

  function isMineMeet(meet) {
    return (
      ((_this.meetSession &&
        _this.meetSession.meet &&
        _this.meetSession.meet.meetnum) ||
        getStorage(MEET_NUMBER_KEY)) === meet.meetnum
    );
  }

  function isMine(phone) {
    return _this.userInfo.user.phone === phone;
  }

  async function cleanMeet() {
    _this.meetSession && (await _this.meetSession.terminate());
    _this.meetSession.publishPcInfo && _this.meetSession.publishPcInfo.close();
    _this.meetSession.playerPcInfo && _this.meetSession.playerPcInfo.close();
    Object.assign(_this.meetSession, {
      publishPcInfo: null,
      playerPcInfo: null,
      meet: null,
      meetAllUsers: [],
    });
    removeStorage(MEET_NUMBER_KEY);
  }

  function findUser(phone) {
    const user = _this.meetSession.meetAllUsers.find(
      (item) => item.phone === phone
    );
    return user;
  }

  function updateUserProps(phone, prop, value) {
    const user = findUser(phone);
    user[prop] = value;
  }

  return {
    [MEET_INVITE]: ({ meet, meetAddUser }) => {
      // _this.meetSession = new MeetSession(
      //   {
      //     isCompere: false,
      //     meet
      //   },
      //   {},
      //   _this.userInfo
      // );
    },
    [MEET_IN]: async ({ meetAllUsers, meetInUser, meet }) => {
      if (!isMineMeet(meet)) return;
      if (isMine(meetInUser.phone)) {
        Object.assign(_this.meetSession, {
          meetAllUsers,
          meet,
        });
        _this.play({ meet, meetInUser }).then((playerPcInfo) => {
          Object.assign(_this.meetSession, {
            playerPcInfo,
            state: MEET_STATE.MEETIN,
          });
          _this.meetSession.fire(
            BASE_EVENT.ADD_PLAYER_STREAM,
            playerPcInfo.stream
          );
        });
        _this.meetSession.fire(Meet_Event.MINE_MEET_IN, _this.meetSession, {
          meetAllUsers,
          meetInUser,
          meet,
        });
      } else {
        const user = findUser(meetInUser.phone);
        if (!user) {
          _this.meetSession.meetAllUsers.push(meetInUser);
        } else {
          Object.assign(user, meetInUser);
        }
        _this.meetSession.fire(Meet_Event.OTHER_MEET_IN, _this.meetSession, {
          meetAllUsers,
          meetInUser,
          meet,
        });
      }
      _this.meetSession.fire(Meet_Event.MEET_IN, _this.meetSession, {
        meetAllUsers,
        meetInUser,
        meet,
      });
    },
    [MEET_ADD_USER]: ({ meet, meetAddUser }) => {
      if (
        _this.meetSession.state !== MEET_STATE.MEETIN &&
        _this.meetSession.state !== MEET_STATE.AWAIT_MEETIN
      )
        return;
      /* console.log(
        'MEET_ADD_USER --->',
        _this.meetSession.meetAllUsers.map((item) => item.phone)
      ); */
      const existUser = _this.meetSession.meetAllUsers.find(
        (item) => item.phone === meetAddUser.phone
      );
      !existUser &&
        _this.meetSession.meetAllUsers &&
        _this.meetSession.meetAllUsers.push(meetAddUser);
      _this.meetSession.fire(Meet_Event.MEET_ADD_USER, _this.meetSession, {
        meet,
        meetAddUser,
      });
    },
    [MEET_KICK]: ({ meeKickUser }) => {
      if (isMine(meeKickUser.phone)) {
        cleanMeet();
        _this.meetSession.fire(Meet_Event.MEET_OFF, _this.meetSession);
      } else {
        _this.meetSession.meetAllUsers = _this.meetSession.meetAllUsers.filter(
          (item) => item.phone !== meeKickUser.phone
        );
        _this.meetSession.fire(Meet_Event.MEET_KICK, _this.meetSession, {
          meeKickUser,
        });
      }
    },
    [MEET_OUT]: ({ meet, meetOutUser }) => {
      const outUser = _this.meetSession.meetAllUsers.find(
        (item) => item.phone === meetOutUser.phone
      );
      Object.assign(outUser, meetOutUser, {
        state: MEET_USER_STATE.OUT,
      });
      if (isMine(meetOutUser.phone)) {
        cleanMeet();
        _this.meetSession.fire(Meet_Event.MEET_OFF, _this.meetSession);
      }
      _this.meetSession.fire(Meet_Event.MEET_OUT, _this.meetSession, {
        meet,
        meetOutUser,
      });
    },
    //当关闭会议时，会议内的所有的成员会收到此通知
    [MEET_OFF]: () => {
      cleanMeet();
      console.log("off", _this.meetSession);
      _this.meetSession.fire(Meet_Event.MEET_OFF, _this.meetSession);
    },
    [MEET_MUTE]: ({ meetMute }) => {
      updateUserProps(meetMute.phone, "mute", true);
      _this.meetSession.fire(Meet_Event.MEET_MUTE, _this.meetSession, {
        meetMute,
      });
    },
    [MEET_UNMUTE]: ({ meetUnmute }) => {
      updateUserProps(meetUnmute.phone, "mute", false);
      _this.meetSession.fire(Meet_Event.MEET_UNMUTE, _this.meetSession, {
        meetUnmute,
      });
    },
    [MEET_DEAF]: ({ meetDeaf }) => {
      updateUserProps(meetDeaf.phone, "deaf", true);
      _this.meetSession.fire(Meet_Event.MEET_DEAF, _this.meetSession, {
        meetDeaf,
      });
    },
    [MEET_UNDEAF]: ({ meetUndeaf }) => {
      updateUserProps(meetUndeaf.phone, "deaf", false);
      _this.meetSession.fire(Meet_Event.MEET_UNDEAF, _this.meetSession, {
        meetUndeaf,
      });
    },
    [MEET_STARTTALKING]: ({ meet, meetStartTalking }) => {
      if (!isMineMeet(meet)) return;
      _this.meetSession.speaker = meetStartTalking;
      _this.meetSession.fire(Meet_Event.MEET_STARTTALKING, _this.meetSession, {
        cmd: Meet_Event.MEET_STARTTALKING,
        meet,
        meetStartTalking,
      });
    },
    [MEET_STOPTALKING]: ({ meet, meetStopTalking }) => {
      if (!isMineMeet(meet)) return;
      _this.meetSession.speaker = {};
      _this.meetSession.fire(Meet_Event.MEET_STOPTALKING, _this.meetSession, {
        cmd: Meet_Event.MEET_STOPTALKING,
        meet,
        meetStopTalking,
      });
    },
  };
}
