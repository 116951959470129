import { getStorage } from '../../util/index';

export const CHANNEL_IN = 'channel_in';
export const CHANNEL_ADD = 'channel_add';
export const CHANNEL_OUT = 'channel_out';
export const CHANNEL_DEL_USER = 'channel_del_user';
export const CHANNEL_GET = 'channel_get';
export const CHANNEL_RELEASE = 'channel_release';
export const CHANNEL_HANGUP = 'channel_hangup';

export function useChannelNotice() {
  return {
    channel_get(data, userInfo){
      
    }
  };
}
