import Session from "../base/Session";
import P2P_EVENT from "./event";
import api from "../../api/index";
import { CONNECT_STATE } from "../../dict/index";

export default class CallSession extends Session {
  constructor(
    { publishPcInfo, playerPcInfo, call, userInfo, isMineCaller, target },
    event
  ) {
    super({ event });
    this.userInfo = userInfo;
    this.publishPcInfo = publishPcInfo;
    this.playerPcInfo = playerPcInfo;
    this.call = call;
    this.controls = {
      mute: false,
      deaf: false,
      share: false,
    };
    this.active = true; //这轮通话还是否存活
    this.connectState = CONNECT_STATE.READY;
    this.isMineCaller = isMineCaller || false; //呼叫方法内部会传入true   不传就是对方呼叫我
    this.target = target;
  }

  getTarget() {
    return this.call.caller === this.userInfo.user.phone
      ? this.call.callee
      : this.call.caller;
  }

  muted() {
    this.controls.mute = true;
    this.fire(P2P_EVENT.CONTROLS_UPDATE, this.controls); //以后可以改成观察者
  }

  cancelMuted() {
    this.controls.mute = false;
    this.fire(P2P_EVENT.CONTROLS_UPDATE, this.controls);
  }

  deaf() {
    this.checkMediaSouce();
    this.publishPcInfo.closeVoice();
    this.controls.deaf = true;
    this.fire(P2P_EVENT.CONTROLS_UPDATE, this.controls);
  }

  cancelDeaf() {
    this.checkMediaSouce();
    this.publishPcInfo.openVoice();
    this.controls.deaf = false;
    this.fire(P2P_EVENT.CONTROLS_UPDATE, this.controls);
  }

  async shareScreen(cancelCb) {
    this.controls.share = true;
    if (this.publishPcInfo) {
      await this.publishPcInfo.shareScreen(cancelCb);
    }
    this.fire(P2P_EVENT.CONTROLS_UPDATE, this.controls);
  }

  cancelShare() {
    this.controls.share = false;
    this.publishPcInfo && this.publishPcInfo.cancelShareScreen();
    this.fire(P2P_EVENT.CONTROLS_UPDATE, this.controls);
  }

  help(session, el) {
    this.publishPcInfo.help(session, el);
  }

  cancelHelp(session) {
    this.publishPcInfo.cancelHelp(session);
  }

  replaceCamera(deivceId) {
    this.checkMediaSouce();
    this.publishPcInfo.replaceCamera(deivceId);
  }

  terminate() {
    // 终止通话   数据删除是在ws通知后删除
    if (this.connectState === CONNECT_STATE.END) return;
    this.connectState = CONNECT_STATE.END;
    if (this.config.isMediaSource) {
      api.sysCallHangup({
        user_id: this.userInfo.user.id,
        uuid: this.call.uuid,
      });
    } else if (this.publishPcInfo || this.playerPcInfo) {
      console.log("close => publishInfo", this.publishPcInfo);
      /* 为啥我这里publish停止不管用 */
      this.publishPcInfo && this.publishPcInfo.close();
      this.playerPcInfo && this.playerPcInfo.close();
    } else {
      api.sysCallHangup({
        user_id: this.userInfo.user.id,
        uuid: this.call.uuid,
      });
    }
    // api.sysCallHangup({
    //   user_id: this.userInfo.user.id,
    //   uuid: this.call.uuid
    // });
    // this.publishPcInfo && this.publishPcInfo.terminate();
    // this.playerPcInfo && this.playerPcInfo.terminate();
    this.publishPcInfo = null;
    this.playerPcInfo = null;
  }

  //视频源检测
  checkMediaSouce() {
    if (this.config.isMediaSource) {
      console.warn(`视频源模式下无法操作`);
      return;
    }
  }

  // 停止推流
  stopPublish() {
    if (this.connectState === CONNECT_STATE.END) return;
    this.publishPcInfo && this.publishPcInfo.close();
    this.playerPcInfo && this.playerPcInfo.close();
    this.publishPcInfo = null;
    this.playerPcInfo = null;
  }
}
