const MEET_EVENT = {
  MEET_IN: 'meetIn', /* 入会 */
  MEET_ADD_USER: 'meetAddUser', /* 会议添加成员 */
  MEET_KICK: 'meetKick',/* 会议剔除成员 */
  KICK_MINE: 'kickMine',/* 我被踢出会议 */
  KICK_OTHER: 'kickOther',/* 他人被踢出会议 */
  MEET_OFF: 'meetOff',/* 会议关闭 */
  MEET_OUT: 'meetOut',/* 会议成员退出 */
  MEET_MUTE: 'meetMute',/* 静音 */
  MEET_UNMUTE: 'meetUnmute',/* 解除静音 */
  MEET_DEAF: 'meetDeaf',/* 置聋 */
  MEET_UNDEAF: 'meetUndeaf',/* 解除置聋 */
  MEET_INVITE: 'meetInvite',//会议邀请
  MINE_MEET_IN: 'mineMeetIn',/* 我进入会议 */
  OTHER_MEET_IN: 'otherMeetIn',/* 他人进入会议 */
  MEET_STARTTALKING: 'meetStartTalking',
  MEET_STOPTALKING: 'meetStopTalking'
};

export default MEET_EVENT;
