import api from '../../api/index';
import { USERGROUP_STATE } from '../../dict/index';

export default class UserGroup {
  constructor({ userInfo }) {
    this.userInfo = userInfo;
    this.data = {};
    this.isOpen = false;
    this.init();
  }

  async init() {
    /* this.data = await api.getUserGroupInfo({ user_id: this.userInfo.user.id });
    this.changeUserGroupState(this.data.state); */
  }

  //改变userGroup 状态
  changeUserGroupState(state) {
    this.data.group_state = state;
    this.isOpen = state === USERGROUP_STATE.OPEN;
  }

  async open() {
    try {
      await api.ajax.updateUserGroup({
        user_id: this.userInfo.user.id,
        group_state: USERGROUP_STATE.OPEN
      });
      this.changeUserGroupState(USERGROUP_STATE.OPEN);
    } catch (error) {
      console.error(error);
    }
  }

  async close() {
    try {
      await api.ajax.updateUserGroup({
        user_id: this.userInfo.user.id,
        group_state: USERGROUP_STATE.CLOSE
      });
      this.changeUserGroupState(USERGROUP_STATE.CLOSE);
    } catch (error) {
      console.error(error);
    }
  }
}
