
export const channelTpl = `
    <div class="channel-main">
        <div class="channel-container">

        </div>
    </div>
`;

export const channelGroupItemTpl = `
    <div class="channel_list">
        <div class="group">
            <div class="name">
                <i class="iconfont {{headIcon}}"></i>
                {{name}}
            </div>
            <div class="actions {{groupId}}" data-group={{groupId}}>
                <i class="iconfont iconyuyin"></i>
            </div>
        </div>

        <ul class="user_list">

        </ul>
    </div>
`;

export const userItemTpl = `
    <li class="userList">
        <div class="{{status}}" data-account="{{account}}"><i class="iconfont2 iconyuyin"></i></div>
        <div class="userName">{{name}}</div>
    </li>
`;

export const speakBtnTpl = `
    <div class="channel-btns">
        <div class="microphone-box">
            <p class="cur-channel">当前组：</p>
            <button title="麦克风" class="microphone-item" btn-type="microphone" state="release">
            <button>
            <p class="speaker"></p>
        </div>
    </div>
`