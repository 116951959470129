const BASE_EVENT = {
  UPDATE: 'update',
  ADDSTREAM: 'addStream',
  ADD_PUBLISH_STREAM: 'add_publish_stream',
  PUBLISH_FIRE: 'publish_fire',
  ADD_PLAYER_STREAM: 'add_player_stream',
  PLAYER_FIRE: 'player_fire'
};

export default BASE_EVENT;
