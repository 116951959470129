const SOCKET_RECONNECT_FAIL = 'socket_reconnect_fail'; //socket重新连接失败
const SOCKET_RECONNECT_SUCCESS = 'socket_reconnect_success'; //socket重新连接成功
const SOCKET_RECONNECTING = 'socket_reconnecting'; // 正在重连
const SOCKET_CLOSE = 'socket_close';

export {
  SOCKET_RECONNECT_FAIL,
  SOCKET_RECONNECT_SUCCESS,
  SOCKET_RECONNECTING,
  SOCKET_CLOSE
};
