import face_0 from "../../images/emoji/face_0.png";
import face_1 from "../../images/emoji/face_1.png";
import face_2 from "../../images/emoji/face_2.png";
import face_3 from "../../images/emoji/face_3.png";
import face_4 from "../../images/emoji/face_4.png";
import face_5 from "../../images/emoji/face_5.png";
import face_6 from "../../images/emoji/face_6.png";
import face_7 from "../../images/emoji/face_7.png";
import face_8 from "../../images/emoji/face_8.png";
import face_9 from "../../images/emoji/face_9.png";

import face_10 from "../../images/emoji/face_10.png";
import face_11 from "../../images/emoji/face_11.png";
import face_12 from "../../images/emoji/face_12.png";
import face_13 from "../../images/emoji/face_13.png";
import face_14 from "../../images/emoji/face_14.png";
import face_15 from "../../images/emoji/face_15.png";
import face_16 from "../../images/emoji/face_16.png";
import face_17 from "../../images/emoji/face_17.png";
import face_18 from "../../images/emoji/face_18.png";
import face_19 from "../../images/emoji/face_19.png";

import face_20 from "../../images/emoji/face_20.png";
import face_21 from "../../images/emoji/face_21.png";
import face_22 from "../../images/emoji/face_22.png";
import face_23 from "../../images/emoji/face_23.png";
import face_24 from "../../images/emoji/face_24.png";
import face_25 from "../../images/emoji/face_25.png";
import face_26 from "../../images/emoji/face_26.png";
import face_27 from "../../images/emoji/face_27.png";
import face_28 from "../../images/emoji/face_28.png";
import face_29 from "../../images/emoji/face_29.png";

import face_30 from "../../images/emoji/face_30.png";
import face_31 from "../../images/emoji/face_31.png";
import face_32 from "../../images/emoji/face_32.png";
import face_33 from "../../images/emoji/face_33.png";
import face_34 from "../../images/emoji/face_34.png";
import face_35 from "../../images/emoji/face_35.png";
import face_36 from "../../images/emoji/face_36.png";
import face_37 from "../../images/emoji/face_37.png";
import face_38 from "../../images/emoji/face_38.png";
import face_39 from "../../images/emoji/face_39.png";

import face_40 from "../../images/emoji/face_40.png";
import face_41 from "../../images/emoji/face_41.png";
import face_42 from "../../images/emoji/face_42.png";
import face_43 from "../../images/emoji/face_43.png";
import face_44 from "../../images/emoji/face_44.png";
import face_45 from "../../images/emoji/face_45.png";
import face_46 from "../../images/emoji/face_46.png";
import face_47 from "../../images/emoji/face_47.png";
import face_48 from "../../images/emoji/face_48.png";
import face_49 from "../../images/emoji/face_49.png";

import { uuid, formatTime } from "../../util/index";
class Sender {
    constructor(userInfo) {
      this.sender = userInfo.id;
      this.sender_account = userInfo.account;
      this.sender_headimg = userInfo.headimg;
      this.sender_id = userInfo.id;
      this.sender_mobile = userInfo.mobile;
      this.sender_name = userInfo.name;
      this.sender_now_extension_status = userInfo.now_extension_status;
      this.sender_orgname = userInfo.orgname;
      this.sender_phone = userInfo.phone;
    }
  }
  
  class Receiver {
    constructor(receiver, receiver_name, receiver_phone) {
      this.receiver = receiver;
      this.receiver_id = receiver;
      this.receiver_name = receiver_name;
      this.receiver_phone = receiver_phone;
    }
  }
  
  class Content {
    constructor(
      content,
      content_type,
      content_type_value,
      createtime,
      createtime_str,
      id,
      is_group,
      state,
      msg_id
    ) {
      this.content = content;
      this.content_type = content_type;
      this.content_type_value = content_type_value;
      this.createtime = createtime;
      this.createtime_str = createtime_str;
      this.id = id;
      this.is_group = is_group;
      this.state = state;
      this.msg_id = msg_id;
    }
    changeState(state) {
      this.state = state;
    }
  }
  
  class Message {
    constructor(sender, receiver, content) {
      this.message = {};
      this.sender = sender;
      this.receiver = receiver;
      this.content = content;
    }
  
    getMessage() {
      this.message = Object.assign({}, this.sender, this.receiver, this.content);
      return this.message;
    }
  
    changeMessageState(state) {
      this.content.changeState(state);
    }
  }

class ChatMsg {
    constructor({user,receiverInfo,content,content_type,content_type_value,file,isCheck,newMsgCount}){
        let scope;
        if(receiverInfo.is_group === "1") {
            scope = 'receiver';
        }else{
            scope = user.id === receiverInfo.receiver_id ? 'sender':'receiver';
        }
        this.content = content,
        this.content_type = content_type,
        this.content_type_value = content_type_value,
        this.file = file,

        this.state = "wait",

        this.createtime = new Date().getTime().toString() ,
        this.createtime_str = formatTime(this.createtime),
        this.id = uuid(),
        this.group_id = receiverInfo.group_id,
        this.group_name = receiverInfo.group_name,
        this.is_group = receiverInfo.is_group,
        this.receiver = receiverInfo[receiverInfo.is_group === "1" ? 'group_id':scope],
        this.receiver_account = receiverInfo[`${scope}_account`],
        this.receiver_headimg = receiverInfo[`${scope}_headimg`]
        this.receiver_id = receiverInfo[receiverInfo.is_group === "1" ? 'group_id':`${scope}_id`],
        this.receiver_mobile = receiverInfo[`${scope}_mobile`] ,
        this.receiver_name = receiverInfo[`${scope}_name`],
        this.receiver_now_extension_status = receiverInfo[`${scope}_now_extension_status`],
        this.receiver_orgname = receiverInfo[`${scope}_orgname`],
        this.receiver_phone = receiverInfo[`${scope}_phone`] ,

        this.sender = user.id;
        this.sender_account = user.account;
        this.sender_headimg = user.headimg;
        this.sender_id = user.id;
        this.sender_mobile = user.mobile;
        this.sender_name = user.name;
        this.sender_now_extension_status = user.now_extension_status;
        this.sender_orgname = user.orgname;
        this.sender_phone = user.phone;

        this.isCheck = isCheck;
        this.newMsgCount = newMsgCount;
    }
}


  export const EMOJI_LIST = [
    {
      icon: face_0,
      face: "微笑"
    },
    {
      icon: face_1,
      face: "生病"
    },
    {
      icon: face_2,
      face: "色"
    },
    {
      icon: face_3,
      face: "恐惧"
    },
    {
      icon: face_4,
      face: "玫瑰"
    },
    {
      icon: face_5,
      face: "炸弹"
    },
    {
      icon: face_6,
      face: "放大镜"
    },
    {
      icon: face_7,
      face: "强"
    },
    {
      icon: face_8,
      face: "弱"
    },
    {
      icon: face_9,
      face: "合十"
    },
    {
      icon: face_10,
      face: "破涕为笑"
    },
    {
      icon: face_11,
      face: "亲亲"
    },
    {
      icon: face_12,
      face: "失望"
    },
    {
      icon: face_13,
      face: "无语"
    },
    {
      icon: face_14,
      face: "擦汗"
    },
    {
      icon: face_15,
      face: "调皮"
    },
    {
      icon: face_16,
      face: "发怒"
    },
    {
      icon: face_17,
      face: "小狗"
    },
    {
      icon: face_18,
      face: "房子"
    },
    {
      icon: face_19,
      face: "肌肉"
    },
    {
      icon: face_20,
      face: "手机"
    },
    {
      icon: face_21,
      face: "啤酒"
    },
    {
      icon: face_22,
      face: "大哭"
    },
    {
      icon: face_23,
      face: "月亮"
    },
    {
      icon: face_24,
      face: "心碎"
    },
    {
      icon: face_25,
      face: "礼物"
    },
    {
      icon: face_26,
      face: "蛋糕"
    },
    {
      icon: face_27,
      face: "太阳"
    },
    {
      icon: face_28,
      face: "胜利"
    },
    {
      icon: face_29,
      face: "下雨"
    },
    {
      icon: face_30,
      face: "咖啡"
    },
    {
      icon: face_31,
      face: "汽车"
    },
    {
      icon: face_32,
      face: "足球"
    },
    {
      icon: face_33,
      face: "OK"
    },
    {
      icon: face_34,
      face: "鼓掌"
    },
    {
      icon: face_35,
      face: "衣服"
    },
    {
      icon: face_36,
      face: "云"
    },
    {
      icon: face_37,
      face: "上"
    },
    {
      icon: face_38,
      face: "下"
    },
    {
      icon: face_39,
      face: "左"
    },
    {
      icon: face_40,
      face: "右"
    },
    {
      icon: face_41,
      face: "恶魔"
    },
    {
      icon: face_42,
      face: "鬼魂"
    },
    {
      icon: face_43,
      face: "笑脸"
    },
    {
      icon: face_44,
      face: "囧"
    },
    {
      icon: face_45,
      face: "汗"
    },
    {
      icon: face_46,
      face: "心"
    },
    {
      icon: face_47,
      face: "钻石"
    },
    {
      icon: face_48,
      face: "拳头"
    },
    {
      icon: face_49,
      face: "龇牙"
    }
  ];
  
  export { Sender, Receiver, Content, Message, ChatMsg };
  