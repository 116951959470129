let buffer = [];
let mediaRecorder;
let config = {};
export const startRecord = ({
  mimeType = "video/webm;codecs=vp8",
  blobType = "video/mp4",
  stream,
}) => {
  //开始录屏
  buffer = [];
  config = {
    blobType,
    mimeType,
  };
  let options = { mimeType };

  if (!MediaRecorder.isTypeSupported(options.mimeType)) {
    console.error(`${options.mimeType} is not supported!`);
    return;
  }
  try {
    mediaRecorder = new MediaRecorder(stream, options);
  } catch (e) {
    console.error("Failed to create MediaRecorder:", e);
    return;
  }
  mediaRecorder.ondataavailable = handleDataAvailable;
  mediaRecorder.start(10);
};
export const stopRecord = ({ isDownload = true }) => {
  //结束录屏
  mediaRecorder.stop();
  let blob = new Blob(buffer, { type: config.blobType });
  if (isDownload) {
    let href = window.URL.createObjectURL(blob);
    let fileType = config.blobType.subString(config.blobType.indexOf("/") + 1);
    let filename = `${new Date().getTime() + "_" + this.target}.${fileType}`;
    download({ href, filename });
  }
  return blob;
};
const handleDataAvailable = (e) => {
  //录屏数据存储
  if (e && e.data && e.data.size > 0) {
    buffer.push(e.data);
  }
};

export const download = ({ href, filename }) => {
  let a = document.createElement("a");
  a.href = href;
  a.style.display = "none";
  a.download = filename;
  a.click();
  a.remove();
};

export const stopStream = (stream) => {
  stream &&
    stream.getTracks &&
    stream.getTracks().forEach((item) => {
      item.stop && item.stop();
    });
};