export const MICROPHONE_STATE = {
  OTHER_SPEAKING: 'other_speaking', //他人说话
  MINE_SPEAKING: 'mine_speaking', //我在说话
  GET_READY: 'get_ready', //抢话准备中
  GET_TALKING: 'get_talking', //抢话中
  GET_TALK_SUCCESS: 'get_talk_success', //抢话成功
  GET_TALK_FAIL: 'get_talk_fail', //抢话失败
  PUBLISH_STREAM: 'publish_stream', //推流中
  RELEASE: 'release' //空闲
};

export const CHANNEL_STATE = {
  AVAILABLE: 'available', //空闲 还未进入
  CAHNELIN: 'channelIn' //在对讲组中
};
