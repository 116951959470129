import {
  BTN_TYPE,
  MEET_USER_STATE,
  IS_MEET_COMPERE,
  ICON_TYPE,
  MEET_LAYOUUT,
  MEET_TYPE,
} from "../../dict/index";

// 画面下方按钮
export const toolsBottomBtn = {
  [MEET_TYPE.MEET_VIDEO]: [
    {
      btnType: BTN_TYPE.MUTED,
      icon: ICON_TYPE.MUTED,
      text: "静音",
    },
    {
      btnType: BTN_TYPE.DEAF,
      icon: ICON_TYPE.DEAF,
      text: "置聋",
    },
    {
      btnType: BTN_TYPE.SHARE_SCREEN,
      icon: ICON_TYPE.SHARE_SCREEN,
      text: "共享桌面",
    },
    {
      btnType: BTN_TYPE.CANCEL_SHARE_SCREEN,
      icon: ICON_TYPE.CANCEL_SHARE,
      text: "取消共享桌面",
    },
    {
      btnType: BTN_TYPE.ADD_WX_MEET,
      icon: ICON_TYPE.ADD_WX_MEET,
      text: "小程序入会",
    },
    {
      btnType: BTN_TYPE.MEETOFF,
      icon: ICON_TYPE.MEETOFF,
      text: "关闭会议",
    },
  ],
  [MEET_TYPE.MEET_VOICE]: [
    {
      btnType: BTN_TYPE.MUTED,
      icon: ICON_TYPE.MUTED,
      text: "静音",
    },
    {
      btnType: BTN_TYPE.DEAF,
      icon: ICON_TYPE.DEAF,
      text: "置聋",
    },
    {
      btnType: BTN_TYPE.MEETOFF,
      icon: ICON_TYPE.MEETOFF,
      text: "关闭会议",
    },
  ],
};

// 进入人员按钮
export const meetInUserBtns = [
  {
    btnType: BTN_TYPE.MUTED,
    icon: ICON_TYPE.MUTED,
    text: "静音",
  },
  {
    btnType: BTN_TYPE.UNMUTED,
    icon: ICON_TYPE.CANCELMUTED,
    text: "取消静音",
  },
  {
    btnType: BTN_TYPE.DEAF,
    icon: ICON_TYPE.DEAF,
    text: "置聋",
  },
  {
    btnType: BTN_TYPE.UNDEAF,
    icon: ICON_TYPE.CANCELDEAF,
    text: "取消置聋",
  },
  {
    btnType: BTN_TYPE.FLOOR,
    icon: ICON_TYPE.LAYOUT,
    text: "设为主屏",
  },
  {
    btnType: BTN_TYPE.MEETHANGUP,
    icon: ICON_TYPE.HNAGUP,
    text: "挂断",
  },
  {
    btnType: BTN_TYPE.MEETKICK,
    icon: ICON_TYPE.MEETKICK,
    text: "踢出",
  },
];

export const meetOutUserBtns = [
  {
    btnType: BTN_TYPE.CALLAGAIN,
    icon: ICON_TYPE.CALLAGAIN,
    text: "踢出",
  },
  {
    btnType: BTN_TYPE.MEETKICK,
    icon: ICON_TYPE.MEETKICK,
    text: "踢出",
  },
];

export const btnNextInfo = {
  [BTN_TYPE.MUTED]: {
    btnType: BTN_TYPE.UNMUTED,
    icon: ICON_TYPE.CANCELMUTED,
    text: "取消静音",
  },
  [BTN_TYPE.UNMUTED]: {
    btnType: BTN_TYPE.MUTED,
    icon: ICON_TYPE.MUTED,
    text: "静音",
  },
  [BTN_TYPE.DEAF]: {
    btnType: BTN_TYPE.UNDEAF,
    icon: ICON_TYPE.CANCELDEAF,
    text: "取消置聋",
  },
  [BTN_TYPE.UNDEAF]: {
    btnType: BTN_TYPE.DEAF,
    icon: ICON_TYPE.DEAF,
    text: "置聋",
  },
};
