import { channelTpl, speakBtnTpl, channelGroupItemTpl, userItemTpl } from "./tpl.js";
import { createTpl, tplReplace } from "../../util";
import { useChannelProcess } from "./process";

export default class Render {
    constructor({ mountedId, userInfo, _channel, eid }) {
        this.userInfo = userInfo || null;
        this.mountedId = mountedId;
        this._channel = _channel;
        this.mountedEl = null;
        this.channelEl = null;
        this.channelMainEl = null;
        this.channelContainerEl = null;
        this.channelListEl = null;
        this.speakEl = null;
        this.channelSession = null;
        this.timer = null;
        this.speakBtn = null;
        this.channelList = null;
        this.userItemElMap = {};
        this.eid = eid;
        this.process = useChannelProcess.call(this);
    }

    mounted(el) {
        this.mountedId = el;
    }

    setUserInfo(userInfo) {
        this.userInfo = userInfo;
    }

    openChannel(channelList) {
        console.log(channelList,'===channelList');
        this.channelList = channelList;
        this.renderChannel(channelList);
        if (this.mountedId) {
            this.mountedEl = document.getElementById(this.mountedId);
            this.mountedEl.className += " rtc-chanel rtc-base channel-inner";
            this.mountedEl.append(this.channelEl);
        }
    }

    renderChannel(channelList) {
        this.channelEl = Array.from(createTpl(channelTpl.trim()))[0];
        this.channelMainEl = this.channelEl;
        this.renderMain(this.channelMainEl, channelList);
        return this.channelEl;
    }

    renderMain(el,channelList) {
        this.channelContainerEl = el.querySelector(".channel-container");
        /* this.channelListEl = this.channelContainerEl.querySelector(".channel-list"); */
        this.initchannelList(channelList);
        this.addMicEl()
    }

    addMicEl() {
        this.speakEl = createTpl(speakBtnTpl.trim())[0];
        this.speakEl.addEventListener("mousedown", (e) => {
            this.speakBtn = this.speakEl.querySelector(".microphone-item");
            this.speakBtn.setAttribute("state", "ready");
            this.timer = setTimeout(()=>{
                if(this.speakBtn.getAttribute("state") != "ready") {
                    window.clearTimeout(timer);
                    this.timer = null;
                    return;
                }
                console.log(this.speakBtn.getAttribute("state"),'==state');
                this.getChannel()
            },60)
            console.log("===长按")
        });
        this.speakEl.addEventListener("mouseup", (e) => {
            this._channel.release();
            this.speakBtn.setAttribute("state", "release");
        });
        this.channelEl.append(this.speakEl);
    }

    async getChannel() {
        this.speakBtn.setAttribute("state", "loading");
        /* this.speakBtn.setAttribute("state", "get"); */
        this.channelSession = await this._channel.getTalk(this.speakBtn, this.eid);
    }

    initchannelList(channelList) {
        channelList.forEach((item)=>{
            this.channelContainerEl.append(this.addList(item))
        })
    }

    /* 对讲列表渲染 */
    addList(channelItem) {
        const groupItemEl = createTpl(
            tplReplace(channelGroupItemTpl.trim(), {
                name: channelItem.channel_name,
                groupId: channelItem.channel_id,
                channel_head_img: channelItem.channel_head_img,
                channel_in: channelItem.channel_in,
                channel_type: channelItem.channel_type,
                isVideo: channelItem.isVideo,
                headIcon: "iconjiantou-top"
            })
        )[0];

        addEvent.call(this, groupItemEl, channelItem);

        return groupItemEl;

        function addEvent(el, channelItem) {
            const actionsEl = el.querySelector(".actions");
            actionsEl.addEventListener("click", (e) => {
                this.handleBtn(actionsEl, e, channelItem);
            });

            const menuEl = el.querySelector(".name .iconfont");
            menuEl.addEventListener("click", (e) => {
                if(e.target.className == "iconfont iconjiantou") {
                    el.querySelector(".name .iconfont").className = `iconfont iconjiantou-top`;
                } else {
                    el.querySelector(".name .iconfont").className = `iconfont iconjiantou`;
                    const userListEl = el.querySelector(".user_list");
                    if(userListEl) {
                        if(channelItem.children && channelItem.children.length > 0) {
                            channelItem.children.forEach((user)=>{
                                userListEl.append(this.addUser(user))
                            })
                        }
                    }
                }
            });
        }
    }

    setUserStatus(user) {
        this.userListEl[user.account] = user;
        console.log(user,'===user')
    }

    setSpeaker(user) {
        if(user.user.channel_id == this.channelSession.channel.channel_id) {
            this.speakEl.querySelector(".speaker").innerText = user.name || "";
        }
    }

    clearSpeaker(user) {
        if(user.user.channel_id == this.channelSession.channel.channel_id) {
            this.speakEl.querySelector(".speaker").innerText = "";
        }
    }

    delUser() {}

    addUser(user) {
        /* 离线 */
        let status = "";
        switch(parseInt(user.now_extension_status)) {
            case 1:
                status = "offline";
                break;
            case 0:
                status = user.now_extension_state;
                break;
        }
        
        const userItemEl = createTpl(
            tplReplace(userItemTpl.trim(), {
                name: user.name,
                account: user.account,
                status: status
                /* status: status */
            })
        )[0];

        this.userItemElMap[user.account] = userItemEl;
        return userItemEl;
    }

    async handleBtn(el, e, channelItem) {
        if(this.channelSession && !channelItem.channel_in) {
           if(this.channelSession.channel.channel_id !== channelItem.channel_id) {
                const groupEl = document.getElementsByClassName(''+this.channelSession.channel.channel_id);
                groupEl[0].style.color = "#cbf5fc";
           }
        }
        if(!channelItem.channel_in) {
           /*  debugger
            console.log(this._channel.process,'===process') */
            el.style.color = "#068ea7";
            this.channelSession = await this._channel.join({channel: channelItem}, this.process);
            this.speakEl.querySelector(".cur-channel").innerText = channelItem.channel_name || "";
        }
    }
}