import { BTN_TYPE } from "../../dict";
import Meet_Event from "../../operate/meet/event";

export function useMeetProcess() {
  const _this = this;

  return {
    [Meet_Event.MINE_MEET_IN]: (...info) => {
      const [meetSession, wsInfo] = info;
      const { meetAllUsers, meetInUser, meet } = wsInfo;
      if (!_this.meetSession.isCompere) {
        _this.render.openMeet(meet, meetAllUsers);
      }
      _this.render.setSession(meetSession);
      _this.render.setUserInfo(_this.userInfo);
      _this.render.initUserList({ meetSession, meetAllUsers, meetInUser }); //初始化人员列表
    },
    [Meet_Event.OTHER_MEET_IN]: (...info) => {
      const [meetSession, wsInfo] = info;
      const { meetAllUsers, meetInUser } = wsInfo;
      _this.render.appendUser(meetInUser);
    },
    [Meet_Event.MEET_ADD_USER]: (...info) => {
      const [meetSession, wsInfo] = info;
      const { meet, meetAddUser } = wsInfo;
      _this.render.appendUser(meetAddUser);
    },
    [Meet_Event.MEET_OUT]: (...info) => {
      const [meetSession, wsInfo] = info;
      const { meet, meetOutUser } = wsInfo;
      _this.render.hangUpUser(meetOutUser);
    },
    [Meet_Event.MEET_KICK]: (...info) => {
      const [meetSession, wsInfo] = info;
      const { meeKickUser } = wsInfo;
      _this.render.kickUser(meeKickUser);
    },
    [Meet_Event.MEET_OFF]: () => {
      _this.render.closePop();
    },
    [Meet_Event.MEET_MUTE]: (...info) => {
      const [meetSession, wsInfo] = info;
      const { meetMute } = wsInfo;
      if (_this.isMine(meetMute.phone)) {
        _this.render.changeContainerTools(BTN_TYPE.MUTED);
      }
      _this.render.changeBtnType({
        btnType: BTN_TYPE.MUTED,
        userItem: meetMute,
      });
    },
    [Meet_Event.MEET_UNMUTE]: (...info) => {
      const [meetSession, wsInfo] = info;
      const { meetUnmute } = wsInfo;
      if (_this.isMine(meetUnmute.phone)) {
        _this.render.changeContainerTools(BTN_TYPE.UNMUTED);
      }
      _this.render.changeBtnType({
        btnType: BTN_TYPE.UNMUTED,
        userItem: meetUnmute,
      });
    },
    [Meet_Event.MEET_DEAF]: (...info) => {
      const [meetSession, wsInfo] = info;
      const { meetDeaf } = wsInfo;
      if (_this.isMine(meetDeaf.phone)) {
        _this.render.changeContainerTools(BTN_TYPE.DEAF);
      }
      _this.render.changeBtnType({
        btnType: BTN_TYPE.DEAF,
        userItem: meetDeaf,
      });
    },
    [Meet_Event.MEET_UNDEAF]: (...info) => {
      const [meetSession, wsInfo] = info;
      const { meetUndeaf } = wsInfo;
      if (_this.isMine(meetUndeaf.phone)) {
        _this.render.changeContainerTools(BTN_TYPE.UNDEAF);
      }
      _this.render.changeBtnType({
        btnType: BTN_TYPE.UNDEAF,
        userItem: meetUndeaf,
      });
    },
    [Meet_Event.MEET_STARTTALKING]: (...info) => {
      const [meetSession, wsInfo] = info;
      const { meetStartTalking } = wsInfo;
      _this.render.replaceTalking(meetStartTalking.name);
    },
    [Meet_Event.MEET_STOPTALKING]: () => {
      _this.render.replaceTalking();
    },
  };
}
