import {
  createTpl,
  tplReplace,
  isNodeEl,
  getBtnType,
  getReportInfo,
} from "../../util";
import { ACTIONSBTNS, btnNextInfo, callTypeTpl } from "./data";
import { P2PPOP_EVENT, handleActionsEvent } from "./event";
import { actionsBtnTpl, loadingTpl, p2pMainTpl, p2pTpl } from "./tpl";

export default class Render {
  constructor(mountedEl) {
    this.exist = true;
    this.mountedEl = mountedEl;
    this.rootEl = null;
    this.mediaEl = null;
    this.reportInfoEl = null;
    this.actionsEl = null;
    this.loading = false;
    this.session = null;
    this.infoTimer = null;
    this.p2pInstance = null;
  }

  setSession(session) {
    this.session = session;
  }

  openLoading(p2pInstance) {
    this.p2pInstance = p2pInstance;
    this.loading = true;
    this.rootEl = createTpl(p2pTpl.trim())[0];
    this.rootEl.innerHTML = loadingTpl;
    this.actionsEl = this.rootEl.querySelector(".actions");
    this.actionsEl.addEventListener("click", (e) => {
      handleActionsEvent.call(this, e);
    });
    if (this.mountedEl) {
      this.rootEl.setAttribute("data-mouted", false);
      this.mountedEl.appendChild(this.rootEl);
    } else {
      this.rootEl.setAttribute("data-mouted", true);
      document.body.appendChild(this.rootEl);
      this.rootEl.dragClick();
    }
  }

  openP2P(session) {
    this.loading = false;
    this.session = session;
    this.renderP2P();
  }

  renderP2P() {
    this.rootEl.innerHTML = tplReplace(p2pMainTpl.trim(), {
      target: this.session.getTarget(),
      mediaTpl: callTypeTpl[this.session.call.call_type],
    });
    this.reportInfoEl = this.rootEl.querySelector(".receiver-report");
    this.renderActions();
  }

  renderActions() {
    this.actionsEl = this.rootEl.querySelector(".actions");
    this.actionsEl.innerHTML = "";
    this.mediaEl = this.rootEl.querySelector(".media");
    const btns = ACTIONSBTNS[this.session.call.call_type];
    btns.forEach((item) => this.actionsEl.append(this.renderActionsBtn(item)));
    this.actionsEl.addEventListener("click", (e) => {
      handleActionsEvent.call(this, e, this.mediaEl);
    });
  }

  renderActionsBtn(item) {
    const btnEl = createTpl(tplReplace(actionsBtnTpl.trim(), item))[0];
    return btnEl;
  }

  addStream(stream) {
    if (this.mediaEl.srcObject) return;
    this.mediaEl.srcObject = stream;
  }

  closePop() {
    this.session && this.session.fire(P2PPOP_EVENT.CLOSE_POP); //告诉外面弹窗关闭
    this.rootEl.remove();
    this.exist = false;
  }

  loadReportInfo() {
    this.infoTimer = setInterval(async () => {
      const reportInfo =
        (await getReportInfo(this.session.playerPcInfo.pc)) || {};
      this.reportInfoEl.innerHTML = tplReplace(
        this.reportInfoEl.innerHTML,
        reportInfo.info
      );
    }, 1000);
  }

  stopReportInfo() {
    window.clearInterval(this.infoTimer);
    this.infoTimer = null;
  }
}
