import { BTN_CMD, BTN_TYPE } from "../../dict";

export const p2pTpl = `
<div class="rtc-media rtc-base">

</div>
`;

export const p2pMainTpl = `
  <div class="video-header">
    <span class="name">{{ target }}</span>
  <div class="receiver-report" data-show=${BTN_CMD.INFO_HIDE}>
    帧率:{{framesPerSecond}} 码率: {{biterate}} 分辨率: {{frameWidth}}*{{frameHeight}}
  </div>
  </div>
  <div class="container">
  {{mediaTpl}}
  </div>
  <div class="actions">
  </div>
`;

export const loadingTpl = `
  <div class="loading">加载中...</div>
  <div class="actions">
    <button class="action-btn ${BTN_TYPE.HANGUP}" btn-cmd=${BTN_CMD.HANGUP}>挂断</button>
  </div>
`;

export const actionsBtnTpl = `
<button class="action-btn {{btnType}}" btn-type={{btnType}} btn-cmd={{btnCmd}} title={{text}}>
<i class="iconfont {{icon}}"></i>
</button>
`;

export const videoTpl = `<video autoplay class="media"></video>`;

export const audioTpl = `<audio autoplay class="media"></audio>
<i class="iconfont iconyuyin1 center yuyin"></i>`;

export const p2pInviteTipTpl = `<div class="invite">用户 <span class="info">{{target}}</span> 正在 <span class="info">{{type}}</span></div>`;
