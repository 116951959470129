import {
  meetTpl,
  userItemTpl,
  userBtnTpl,
  containerToolBtnTpl,
  videoTpl,
  audioTpl,
  layoutTpl,
} from "./tpl";
import {
  createTpl,
  tplReplace,
  isNodeEl,
  getBtnType,
  triggerScale,
} from "../../util";
import {
  toolsBottomBtn,
  btnNextInfo,
  meetOutUserBtns,
  meetInUserBtns,
} from "./data";
import {
  BTN_TYPE,
  MEET_USER_STATE,
  IS_MEET_COMPERE,
  ICON_TYPE,
  MEET_LAYOUUT,
  MEET_TYPE,
} from "../../dict/index";
import { MEETPOP_EVENT } from "./event";
import { PHONE_REG } from "../../dict/enum/regexp";

export default class Render {
  constructor({ mountedId, userInfo }) {
    this.meet = null;
    this.mineMeetItem = null;
    this.mineMeetItemEl = null;
    this.meetSession = null;
    this.userInfo = userInfo || null;
    this.mountedId = mountedId;
    this.mountedEl = null;
    this.meetEl = null;
    this.meetTabEl = null;
    this.meetMainEl = null;
    this.meetSideEl = null;
    this.userListEl = null;
    this.meetContainerEl = null;
    this.containerTalkingEl = null;
    this.containerTopToolsEl = null;
    this.containerBottomToolsEl = null;
    this.mediaEl = null;
    this.layoutEl = null;
    this.layerIdx = null;
    this.meetAllUsers = null;
    this.userItemElMap = {};
    this.globAction = {
      showUser: true,
    };
  }

  mounted(el) {
    this.mountedId = el;
  }

  setSession(meetSession) {
    this.meetSession = meetSession;
  }

  setUserInfo(userInfo) {
    this.userInfo = userInfo;
  }

  //打开会议窗口/内嵌
  openMeet(meet) {
    this.meet = meet;
    this.renderMeet();
    if (this.mountedId) {
      // 有传入就是内嵌
      this.mountedEl = document.getElementById(this.mountedId);
      this.mountedEl.className += " rtc-meet rtc-base meet-inner";
      // 缩放
      // triggerScale(
      //   this.mountedEl.clientWidth,
      //   this.mountedEl.clientHeight,
      //   this.meetEl
      // );
      this.mountedEl.append(this.meetEl);
    } else {
      // 没有就是弹窗
      this.mountedEl = document.createElement("div");
      this.mountedEl.id = "rtc-meet-mounted";
      this.mountedEl.className = "rtc-meet rtc-base meet-pop";
      this.mountedEl.append(this.meetEl);
      document.body.append(this.mountedEl);
      layui.use(() => {
        const { layer, jquery: $ } = window.layui;
        layer.open({
          type: 1,
          title: meet.meetname,
          shade: 0,
          maxmin: true,
          area: ["80vw", "80vh"],
          content: $("#rtc-meet-mounted"),
          success: (layero, index) => {
            this.layerIdx = index;
          },
          cancel: (layero, index) => {
            this.closePop();
            this.handleBtn({ btnType: BTN_TYPE.MEETOFF });
          },
        });
      });
    }
  }

  /* renderName(el) {
    const { meetname } = this.meet;
    el.innerHTML = tplReplace(el.innerHTML, {
      meetname,
    });
  } */

  // 渲染会议
  renderMeet() {
    this.meetEl = Array.from(createTpl(meetTpl.trim()))[0];
    this.meetMainEl = this.meetEl;
    this.renderMain(this.meetMainEl);
    const headerEl = this.meetEl.querySelector(".left-info");
    const { meetname } = this.meet;
    headerEl.innerHTML = tplReplace(headerEl.innerHTML, {
      meetname,
    });
    return this.meetEl;
  }

  // tab渲染
  renderTab(el) {
    const { meetname } = this.meet;
    el.innerHTML = tplReplace(el.innerHTML, {
      meetname,
    });
    this.tabEvent(el);
  }

  tabEvent(el) {
    const actionEl = el.querySelector(".actions");
    actionEl.addEventListener("click", (e) => {
      const btnType = getBtnType(e);
      switch (btnType) {
        case BTN_TYPE.CLOSEPOP:
          this.closePop();
          this.meetSession.terminate();
          break;
      }
    });
  }
  closePop() {
    if (this.mountedId) {
      this.mountedEl.contains(this.meetEl) &&
        this.mountedEl.removeChild(this.meetEl);
    } else {
      layer.close(this.layerIdx, () => {
        if (this.mountedEl) {
          document.body.removeChild(
            document.querySelector("#rtc-meet-mounted")
          );
        }
        this.layerIdx = null;
      });
    }
    this.meetSession.fire(MEETPOP_EVENT.CLOSE_POP);
    console.log("关闭弹出框");
  }

  // 渲染主体
  renderMain(el) {
    this.meetContainerEl = el.querySelector(".meet-container");
    this.meetSideEl = el.querySelector(".meet-side");
    this.userListEl = this.meetSideEl.querySelector(".user-list");
    this.renderContainer();
    this.renderSide();
  }

  // 渲染会议内容
  renderContainer() {
    this.renderTalking();
    this.renderMedia();

    if (this.meetSession.isCompere) {
      this.renderContainerTopTools();
    }
    this.renderContainerBottomTools();
  }

  // side渲染
  renderSide() {
    this.renderSearch();
  }

  // 会议全局操作
  renderMeetControls() {
    /* if (this.meetSession.isCompere) {
      const meetControlsActionsEl = this.meetSideEl.querySelector(
        ".meet-controls .actions"
      );
      meetControlsActionsEl.append(...createTpl(meetControlsTpl.trim()));
      meetControlsActionsEl.addEventListener("click", (e) => {
        this.handleBtn({
          meetControlsActionsEl,
          e,
          userItem: this.mineMeetItem,
        });
      });
    } */
  }

  //搜索框
  renderSearch() {
    const searchEl = this.meetSideEl.querySelector(".search-input");
    searchEl.addEventListener("input", (e) => {
      const value = e.target.value;
      const userListEl = Array.from(this.userListEl.children);
      userListEl.forEach((el) => {
        if (el.querySelector(".name").innerText.includes(value)) {
          el.className = "user-item";
        } else {
          el.className = "user-item hide";
        }
      });
    });
  }

  //渲染话权人
  renderTalking() {
    this.containerTalkingEl = this.meetContainerEl.querySelector(".talking");
  }

  // 话权人更改
  replaceTalking(text) {
    this.containerTalkingEl.querySelector(".name").innerText = text || "暂无";
  }

  renderMedia() {
    const containerMediaEl =
      this.meetContainerEl.querySelector(".video-container");
    let tpl;
    switch (this.meet.type) {
      case MEET_TYPE.MEET_VIDEO:
        tpl = videoTpl;
        break;
      case MEET_TYPE.MEET_VOICE:
        tpl = audioTpl;
        break;
    }
    containerMediaEl.append(...createTpl(tpl.trim()));
  }

  renderContainerTopTools() {
    this.containerTopToolsEl =
      this.meetContainerEl.querySelector(".meet-top-tools");
    const actionsEl = this.containerTopToolsEl.querySelector(".actions");
    if (this.meet.type === MEET_TYPE.MEET_VIDEO) {
      // layout布局
      this.appendLayout(actionsEl);
    }
    actionsEl.addEventListener("click", (e) => {
      const btnType = getBtnType(e);
      switch (btnType) {
        case BTN_TYPE.SHOWUSERLIST:
          this.globAction.showUser = !this.globAction.showUser;
          this.meetSideEl.setAttribute("showUser", this.globAction.showUser);
          break;
      }
    });
  }

  // 增加布局按钮
  appendLayout(el) {
    this.layoutEl = createTpl(layoutTpl.trim())[0];
    this.layoutEl.addEventListener("click", async (e) => {
      const layout = getBtnType(e);
      if (!layout) return;
      await this.meetSession.meetLayout(layout);
      this.changeUserFloor(); //更改当前布局按钮样式  和  人员是否有设为主屏按钮
    });
    el.append(this.layoutEl);
  }

  // 会议内容的tools渲染
  renderContainerBottomTools() {
    const toolsBtnList = toolsBottomBtn[this.meet.type];
    this.containerBottomToolsEl =
      this.meetContainerEl.querySelector(".meet-bottom-tools");
    const list = this.meetAllUsers || this.meet.userList;
    const mineInfo = list
      ? list.find((item) => item.phone === this.userInfo.user.phone)
      : null;
    toolsBtnList.forEach((item) => {
      if (mineInfo && item.btnType === BTN_TYPE.MUTED) {
        item = mineInfo.mute ? item : btnNextInfo[BTN_TYPE.MUTED];
      }
      if (mineInfo && item.btn === BTN_TYPE.DEAF) {
        item = mineInfo.deaf ? item : btnNextInfo[BTN_TYPE.DEAF];
      }
      this.containerBottomToolsEl.append(
        ...createTpl(tplReplace(containerToolBtnTpl.trim(), item))
      );
    });
    this.containerBottomToolsEl.addEventListener("click", (e) => {
      this.handleBtn({
        e,
        userItem: this.mineMeetItem || this.userInfo.user,
      });
    });
  }

  // 人员列表初始化
  initUserList({ meetAllUsers }) {
    meetAllUsers.forEach((user) => this.userListEl.append(this.addUser(user)));
    if (this.meet.type === MEET_TYPE.MEET_VIDEO && this.meetSession.isCompere) {
      this.changeUserFloor();
    }
  }

  //会议人员进入
  appendUser(user) {
    this.userListEl.append(this.addUser(user));
  }

  // 挂断 只是按钮多少的改变
  hangUpUser(user) {
    if (user.phone === this.userInfo.user.phone) {
      this.closePop();
      return;
    }
    this.appendUser(user);
  }

  // 踢出人员
  kickUser(user) {
    if (user.phone === this.userInfo.user.phone) {
      this.closePop();
      return;
    }
    this.userListEl.removeChild(this.userItemElMap[user.phone]);
    this.userItemElMap[user.phone] = null;
  }

  addWXMeet() {
    return new Promise((resolve, reject) => {
      layui.use(() => {
        const layer = layui.layer;
        layer.prompt(
          { title: "对方手机号码", formType: 0 },
          (mobile, index) => {
            if (!PHONE_REG.test(mobile)) {
              reject("手机号码格式错误");
              layer.close(index);
            } else {
              layer.close(index);
              return this.meetSession.addUserToWX(mobile).then(() => {
                layer.msg("小程序邀请短信已发送!");
              });
            }
          }
        );
      });
    });
    /* return new Promise((resolve, reject) => {
      ElMessageBox.prompt('对方手机号码', '小程序入会', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: value=>{
          if (!PHONE_REG.test(value)) {
            return "手机号码格式错误"
          }
        },
        inputErrorMessage: '手机号码格式错误',
      }).then((mobile)=>{
        this.meetSession.addUserToWX(mobile.value).then(() => {
          ElMessage({
            type: 'success',
            message: '小程序邀请短信已发送',
          })
        });
      })
    }); */
  }

  //人员进入渲染处理
  addUser(user) {
    let desc = [];
    // 渲染人员的描述情况
    // 我是主持人
    if (user.compere === IS_MEET_COMPERE.YES) {
      desc.push("主持人");
    }
    // 我的进入
    /* if (user.phone === this.userInfo.user.phone) {
      this.renderMeetControls();
      this.mineMeetItem = user;
      desc.push("我");
    } else {
      desc.push(user.phone);
    } */
    // 这个人已存在 但没进入
    if (this.userItemElMap[user.phone]) {
      this.userListEl.removeChild(this.userItemElMap[user.phone]);
    }
    // userItem框架
    const userItemEl = createTpl(
      tplReplace(userItemTpl.trim(), {
        name: user.name,
        /* desc: `(${desc.toString()})`, */
        desc: desc.toString(),
        phone: user.phone,
        headimg: user.headimg,
      })
    )[0];
    // 添加人员是我  className 加上isMine 方便查找
    if (user.phone === this.userInfo.user.phone) {
      userItemEl.className += " isMine";
      this.mineMeetItemEl = userItemEl;
    }
    if (this.meetSession.isCompere) {
      // 我是会议主持人
      const userBtnEl = this.createUserBtn(user);
      addEvent.call(this, userItemEl, user);
      userItemEl.querySelector(".actions").append(...userBtnEl);
    } else {
      // 我不是会议主持人
    }
    this.userItemElMap[user.phone] = userItemEl;
    return userItemEl;

    function addEvent(el, user) {
      const actionsEl = el.querySelector(".actions");
      const phone = actionsEl.getAttribute("data-phone");
      // const userItem = this.meetSession.meetAllUsers.find(
      //   (item) => item.phone === phone
      // );
      actionsEl.addEventListener("click", (e) => {
        this.handleBtn({ actionsEl, e, userItem: user });
      });
    }
  }

  // 处理按钮api接口方面
  handleBtn({ e, userItem, btnType }) {
    btnType = btnType || getBtnType(e);
    switch (btnType) {
      case BTN_TYPE.MUTED:
      case BTN_TYPE.UNMUTED:
        userItem.mute
          ? this.meetSession.cancelMuted(userItem)
          : this.meetSession.muted(userItem);
        userItem.mute = !userItem.mute;
        break;
      case BTN_TYPE.DEAF:
      case BTN_TYPE.UNDEAF:
        userItem.deaf
          ? this.meetSession.cancelDeaf(userItem)
          : this.meetSession.deaf(userItem);
        userItem.deaf = !userItem.deaf;
        break;
      case BTN_TYPE.FLOOR:
        this.meetSession.meetfloor(userItem);
        break;
      case BTN_TYPE.MEETHANGUP:
        this.meetSession.meetOut(userItem);
        break;
      case BTN_TYPE.CALLAGAIN:
        this.meetSession.callAgain(userItem);
        break;
      case BTN_TYPE.MEETKICK:
        this.meetSession.meetKick(userItem);
        break;
      case BTN_TYPE.SHARE_SCREEN:
        this.meetSession.shareScreen();
        break;
      case BTN_TYPE.CANCEL_SHARE_SCREEN:
        this.meetSession.cancelShare();
        break;
      case BTN_TYPE.MEETOFF:
        this.meetSession.terminate();
        this.closePop();
        break;
      case BTN_TYPE.MUTEALL:
        this.meetSession.muteAll();
        break;
      case BTN_TYPE.UNMUTEALL:
        this.meetSession.cancelMuteAll();
        break;
      case BTN_TYPE.ADD_WX_MEET:
        this.addWXMeet();
        break;
    }
    // this.changeBtnType({ el: actionsEl, btnType, userItem });
  }

  // 更改el下按钮的状态类型
  changeBtnType({ el, btnType, userItem }) {
    if (!el) {
      const actionsEl = Array.from(
        this.userListEl.querySelectorAll(".actions")
      );
      el = actionsEl.find(
        (item) => item.getAttribute("data-phone") === userItem.phone
      );
      if (!el) return;
    }
    let flag = true;
    switch (btnType) {
      case BTN_TYPE.MUTED:
      case BTN_TYPE.UNMUTED:
      case BTN_TYPE.DEAF:
      case BTN_TYPE.UNDEAF:
        break;
      default:
        flag = false;
        break;
    }
    if (flag) {
      const hideEl = el.querySelector(`.${btnType}`);
      if (hideEl) {
        hideEl.className += " hide";
      }
      const nextBtnInfo = btnNextInfo[btnType];
      if (nextBtnInfo) {
        const nextBtn = el.querySelector(`.${nextBtnInfo.btnType}`);
        if (nextBtn) {
          nextBtn.className = `user-btn ${nextBtnInfo.btnType}`;
        }
      }
    }
  }

  changeContainerTools(btnType) {
    const btn = this.containerBottomToolsEl.querySelector(`.${btnType}`);
    if (!btn) return;
    const nextInfo = btnNextInfo[btnType];
    btn.className = `tool-btn ${nextInfo.btnType}`;
    btn.setAttribute("btn-type", nextInfo.btnType);
    btn.querySelector(".iconfont").className = `iconfont ${nextInfo.icon}`;
    btn.querySelector(".text").innerHTML = nextInfo.text;
  }

  // 创建userBtn
  createUserBtn(user) {
    let tpl = "";
    if (user.state === MEET_USER_STATE.IN) {
      meetInUserBtns.forEach((item) => {
        tpl += tplReplace(userBtnTpl.trim(), item);
      });
    } else {
      meetOutUserBtns.forEach((item) => {
        tpl += tplReplace(userBtnTpl.trim(), item);
      });
    }
    // 进入的人员   隐藏不需要展示的
    const nodes = Array.from(createTpl(tpl));
    if (user.state === MEET_USER_STATE.IN) {
      nodes.find((item) =>
        item.className.includes(user.mute ? BTN_TYPE.MUTED : BTN_TYPE.UNMUTED)
      ).className += " hide";
      nodes.find((item) =>
        item.className.includes(user.deaf ? BTN_TYPE.DEAF : BTN_TYPE.UNDEAF)
      ).className += " hide";
      if (this.meetSession.layout !== MEET_LAYOUUT.ONE_AND_FIVE) {
        nodes.find((item) =>
          item.className.includes(BTN_TYPE.FLOOR)
        ).className += " hide";
      }
    }
    return nodes;
  }

  changeUserFloor() {
    // 布局按钮增加active
    const childEl = Array.from(this.layoutEl.children);
    childEl.forEach((el) => {
      if (el.getAttribute("btn-type") === this.meetSession.layout) {
        el.className += " active";
      } else {
        el.className = "";
      }
    });

    const layoutElList = Array.from(
      this.userListEl.querySelectorAll(`.${BTN_TYPE.FLOOR}`)
    );
    layoutElList.forEach((el) => {
      el.className =
        this.meetSession.layout === MEET_LAYOUUT.ONE_AND_FIVE
          ? `user-btn ${BTN_TYPE.FLOOR}`
          : `user-btn ${BTN_TYPE.FLOOR} hide`;
    });
  }

  playMedia(stream) {
    this.mediaEl =
      this.meet.type === MEET_TYPE.MEET_VIDEO
        ? this.meetContainerEl.querySelector("video")
        : this.meetContainerEl.querySelector("audio");
    if (stream) {
      this.mediaEl.srcObject = stream;
    }
  }
}
