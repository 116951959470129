const P2P_EVENT = {
  HANGUP: 'hangup',
  ANSWER: 'answer',
  CONTROLS_UPDATE: 'controlsUpdate',
  BEFORE_MINE_ANSWER: 'mine_answer',
  CALLEE_OFFLINE: 'callee_offline',
  CALLEE_BUSY: 'callee_busy', //被叫正忙
  CALLEE_NOT_SYS_USER: 'callee_not_sys_user', //被叫非系统用户
  TARGER_NOT_CALL: 'targer_not_call', //被叫非系统用户
  SYS_NOW_CALL: 'sys_now_call',
  ADD_PUBLISH_STREAM: 'add_publish_stream',
  ADD_PLAYER_STREAM: 'add_player_stream',
  PUBLISH_FIRE: 'p2p_event.publish_fire'
};
export default P2P_EVENT;
